<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="settings">

      <v-row class="settings-wrapper">
        <v-btn
          v-if="importConfig.hasCustomerImport"
          elevation="0"
          color="primary"
          class="icon-left sp-mar-r-3"
          :loading="isImportingCustomers"
          @click="importCustomers()"
        >
          <v-icon>mdi-application-import</v-icon>
          {{t('import-customers')}}
        </v-btn>

        <v-btn
          v-if="importConfig.hasUserImport"
          elevation="0"
          color="primary"
          class="icon-left sp-mar-r-3"
          :loading="isImportingUsers"
          @click="importUsers()"
        >
          <v-icon>mdi-application-import</v-icon>
          {{t('import-users')}}
        </v-btn>

        <v-btn
          v-if="importConfig.hasCustomerImport"
          text
          class="sp-mar-r-3"
          @click="lastImportCustomers()"
        >
          {{t('last-import-customers')}}
        </v-btn>

        <v-btn
          v-if="importConfig.hasUserImport"
          text
          @click="lastImportUsers()"
        >
          {{t('last-import-users')}}
        </v-btn>

        <v-btn
            v-for="importer in importConfig.customImporters"
            elevation="0"
            color="primary"
            class="icon-left sp-mar-r-3"
            @click="runCustomImporter(importer)">
          {{ importer.label }}
        </v-btn>
      </v-row>

      <v-row class="settings-wrapper">
        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('import-log') }}</v-card-title>
          <v-card-text>
            <div class="import-log" v-html="importResults" v-show="!isLoading"></div>
            <loading v-show="isLoading"></loading>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>JobLog</v-card-title>
          <v-card-text>
            <loading v-show="isLoading"></loading>
            <div v-if="!selectedJobLogDetails" class="import-log" v-show="!isLoading">
              <v-simple-table class="mb-0">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th></th>
                    <th>{{ t('type') }}</th>
                    <th>{{ t('start') }}</th>
                    <th>{{ t('end') }}</th>
                    <th>{{ t('duration') }}</th>
                    <th>{{ t('status') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="jobLog in jobLogs" :class="jobLog.status">
                    <td>
                      <v-btn @click="loadJobLogDetails(jobLog.id)">Details</v-btn>
                    </td>
                    <td>{{ jobLog.name }}</td>
                    <td>{{ formatDateTime(jobLog.start_date) }}</td>
                    <td>{{ formatDateTime(jobLog.end_date) }}</td>
                    <td>{{ duration(jobLog) }}</td>
                    <td>{{ $t('import.status.' + jobLog.status) }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <div v-if="selectedJobLogDetails" v-show="!isLoading">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>
                      {{ t('date') }}
                    </th>
                    <th>
                      {{ t('message') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="entry in selectedJobLogDetails">
                    <td> {{ entry.event_date }}</td>
                    <td>{{ entry.message }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn @click="loadJobLogs" v-if="!selectedJobLogDetails">{{ t('reload') }}</v-btn>
            <v-btn @click="selectedJobLogDetails = null" v-if="selectedJobLogDetails">{{ t('close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'
import store from 'store'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'

export default {
  name: 'SettingsImport',
  components: {
    toolbar,
    loading
  },
  data() {
    return {
      isLoading: false,
      importConfig: {
        hasCustomerImport: false,
        hasUserImport: false,
        customImporters: [],
      },
      isImportingCustomers: false,
      isImportingUsers: false,
      importResults: '',
      modules: [],
      snackbar: false,
      jobLogs: [],
      selectedJobLogDetails: null
    }
  },
  async mounted() {
    await this.loadConfig()
    await this.loadJobLogs()
  },
  created() {
    this.modules = store.get('modules')
  },
  methods: {
    t: function (key) {
      return this.$t('apiimport-settings.' + key);
    },
    async loadConfig() {
      try {
        let response = await HTTP.get('settings/import/config')
        this.importConfig.hasCustomerImport = response.data.has_customer_import
        this.importConfig.hasUserImport = response.data.has_user_import
        this.importConfig.customImporters = response.data.importers
      } catch (error) {
        this.$root.infoNotification.showResponseError(error)
      }
    },
    duration(joblog) {
      if (joblog.start_date && joblog.end_date) {
        return moment.duration(moment(joblog.end_date).diff(moment(joblog.start_date))).humanize()
      } else {
        return ''
      }
    },
    async loadJobLogs() {
      let response = await HTTP.get('joblogs')
      this.jobLogs = response.data
    },
    async loadJobLogDetails(id) {
      let response = await HTTP.get('joblogs/' + id)
      this.selectedJobLogDetails = response.data
    },
    async runCustomImporter(importer) {
      this.isLoading = true
      try {
        await HTTP.post('settings/import/' + importer.url)
        await this.loadJobLogs()
      } catch (error) {
        this.$root.infoNotification.showResponseError(error)
        await this.loadJobLogs()
      } finally {
        this.isLoading = false
        this.isImportingCustomers = false
      }
    },
    async importCustomers() {
      this.isLoading = true
      this.isImportingCustomers = true
      try {
        let response = await HTTP.post('settings/import/import-customers')
        this.importResults = '<h4>' + response.data.activeCustomers + ' Aktive Kunden</h4>' + response.data.results.replace(/(?:\r\n|\r|\n)/g, '<br>')
        await this.loadJobLogs()
      } catch (error) {
        this.$root.infoNotification.showResponseError(error)
      } finally {
        this.isLoading = false
        this.isImportingCustomers = false
      }
    },
    async importUsers() {
      this.isLoading = true
      this.isImportingUsers = true
      try {
        let response = await HTTP.post('settings/import/import-users')
        this.importResults = '<h4>' + response.data.activeUsers + ' Aktive Mitarbeiter</h4>' + response.data.results.replace(/(?:\r\n|\r|\n)/g, '<br>')
        await this.loadJobLogs()
      } catch (error) {
        this.$root.infoNotification.showResponseError(error)
      } finally {
        this.isLoading = false
        this.isImportingUsers = false
      }
    },
    async lastImportCustomers() {
      this.isLoading = true
      try {
        let response = await HTTP.get('settings/import/last-import-logs')
        let results = response.data.customers.import_result
        results = results === '' ? 'Keine Änderungen im letzten Import.' : results
        this.importResults = '<h4>Kundenimport ' + moment(response.data.customers.created_at).format('YYYY-MM-DD HH:mm:ss') + '</h4>' + results.replace(/(?:\r\n|\r|\n)/g, '<br>')
      } catch (error) {
        this.$root.infoNotification.showResponseError(error)
      } finally {
        this.isLoading = false
      }
    },
    async lastImportUsers() {
      this.isLoading = true
      try {
        let response = await HTTP.get('settings/import/last-import-logs')
        let results = response.data.users.import_result
        results = results === '' ? 'Keine Änderungen im letzten Import.' : results
        this.importResults = '<h4>Benutzerimport ' + moment(response.data.users.created_at).format('YYYY-MM-DD HH:mm:ss') + '</h4>' + results.replace(/(?:\r\n|\r|\n)/g, '<br>')
      } catch (error) {
        this.$root.infoNotification.showResponseError(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .completed-partially {
    background-color: #FF880044;
  }
  .aborted {
    background-color: #FF000044;
  }
</style>
