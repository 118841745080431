<template>
  <v-select
      class="learning-portal-topics-filter"
      v-model="selectedTopics"
      :label="$t('components.learning-portal-topics-filter.label')"
      :items="topics"
      :loading="loading"
      item-text="name"
      item-value="id"
      multiple
      chips
  ></v-select>

</template>

<script>
import { HTTP } from '@/auth'

export default {
  name: "LearningPortalTopicsFilter",
  props: ['value'],
  data() {
    return {
      selectedTopics: this.value,
      topics: [],
      loading: false
    }
  },
  watch: {
    selectedTopics: function (val) {
      this.$emit('input', val)
    },
    value: function (val) {
      this.selectedTopics = val
    }
  },
  async created() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      await HTTP.get('learning_portal_folder').then(function (response) {
        this.topics = response.data
        this.loading = false
      }.bind(this)).catch(function (error) {
        console.log(error)
        this.loading = false
      }.bind(this))
    }
  }
}
</script>

<style scoped lang="scss">
.learning-portal-topics-filter {

  ::v-deep .v-select__selections {

    input {
      width: 0;
    }
  }
}
</style>
