<template>
    <div>
  
        <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
  
        <v-container fluid class="settings">
  
            <loading :error="error" :dataLoaded="dataLoaded"></loading>
          
            <v-row v-if="dataLoaded" class="settings-wrapper"> 
                <v-card elevation="0">
                    <v-card-title>{{ t('categories.group-title') }}</v-card-title>
                    <v-card-text>
    
                        <div class="button-wrapper">
                            <v-btn
                              elevation="0"
                              color="primary"
                              class="icon-left"
                              @click="openCategoryDialog(null)"
                            >
                              <v-icon>mdi-plus</v-icon>
                              {{ t('categories.create') }}
                            </v-btn>
                        </div>

                        <v-data-table
                          v-if="dataLoaded"
                          :headers="headers"
                          :items="categories"
                          :server-items-length="categories.length"
                          class="elevation-0"
                          hide-default-footer
                        >                  
                          <template v-slot:[`item.name`]="{ item }">
                            <span class="ellipsis"><b>{{ item.name }}</b></span>
                          </template>
              
                          <template v-slot:[`item.user_count`]="{ item }">
                            <span>{{ item.user_count }}</span>
                          </template>
              
                          <template v-slot:[`item.edit`]="{ item, index }">
                            <div @click="openCategoryDialog(item, index)" class="edit-row-table">
                              <v-icon
                                small
                                class="edit-row-button"
                              >
                                edit
                              </v-icon>
                            </div>
                          </template>

                          <template v-slot:[`item.remove`]="{ item, index }">
                            <div @click="removeCategory(index)" class="edit-row-table">
                              <v-icon
                                small
                                class="edit-row-button"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                            </div>
                          </template>
                        </v-data-table>
    
                    </v-card-text>
                </v-card>
            </v-row>
  
        </v-container>
  
        <transition name="fade">
            <div class="bottom-navigation modal-nav">
                <v-container fluid>
                    <v-row>
                        <v-btn
                          color="primary"
                          text
                          class="icon-left"
                          :disabled="dataSaving"
                          @click="clear()"
                        >
                          <v-icon>mdi-reload</v-icon>
                          {{ t('reset') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          class="icon-left"
                          :disabled="dataSaving"
                          :loading="dataSaving"
                          @click="saveData()"
                        >
                          <v-icon>mdi-check</v-icon>
                          {{ t('save') }}
                        </v-btn>
                    </v-row>
                </v-container>
            </div>
        </transition>

        <v-dialog
          v-model="categoryDialog"
          content-class="category-dialog"
          width="600"
          persistent
        >
            <v-card>
                <v-card-title>
                  {{ t('dialog.title') }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                          :label="t('dialog.placeholder')"
                          v-model="categoryTitle"
                          counter="120"
                          filled
                          :rules="[
                            () => !!categoryTitle || t('validation.error-title-required'),
                            () => (categoryTitle && categoryTitle.length <= 120) || t('validation.error-max-title-120')
                          ]"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                      elevation="0"
                      color="primary"
                      text
                      class="icon-left px-4"
                      @click="closeCategoryDialog()"
                    >
                      <v-icon>mdi-close</v-icon>
                      {{ t('dialog.cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      elevation="0"
                      color="primary"
                      class="icon-left px-4"
                      @click="editItem ? editCategory() : addCategory()"
                    >
                      <v-icon>mdi-check</v-icon>
                      {{ editItem ? t('dialog.apply') : t('dialog.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  
    </div>
  </template>
  
  <script>
  import { HTTP } from '@/auth'
  import toolbar from '@/components/layouts/Navigation'
  import loading from '@/components/layouts/Loading'
  
  export default {
    name: 'SettingsObjectControl',
    components: {
      toolbar,
      loading
    },
    data() {
      return {
        categories: [],
        dataLoaded: false,
        dataSaving: false,
        error: null,
        categoryDialog: false,
        categoryTitle: '',
        editItem: null,
        editItemIndex: null
      }
    },
    computed: {
      headers() {
        return [
          {
            text: this.t('table.headers.title'),
            align: 'start',
            sortable: true,
            value: 'title',
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.headers.edit'),
            value: 'edit',
            sortable: false,
            align: 'center',
            width: 120,
          },
          {
            text: '',
            value: 'remove',
            sortable: false,
            align: 'center',
            width: 100,
          }
        ]
      },
      calculatedCategoryTitle() {
        return this.editItem ? this.editItem.title : this.categoryTitle
      }
    },
    created() {
      this.getData()
    },
    methods: {
      t: function (key) {
        return this.$t('object-control.settings.' + key)
      },
      reload() {
        this.$nextTick(function () {
          this.getData()
        }.bind(this))
      },
      saveData() {
        if (this.dataSaving) {
          return
        }
        this.dataSaving = true

        const data = {
            categories: this.categories,
            pgs: this.assigned_permission_groups
        }
        HTTP.post('object_control_settings', data).then(function (response) {
          this.categories = response.data.categories
          this.dataSaving = false
          this.$root.infoNotification.showMessage(this.t('saved'))
        }.bind(this)).catch(function (error) {
          this.dataSaving = false
          this.$root.infoNotification.showMessage(this.t('error') + error.response)
        }.bind(this))
      },
      clear() {
        this.dataLoaded = false
        this.data = []
        this.getData()
      },
      getData() {
        HTTP.get('object_control_settings').then(function (response) {
          this.categories = response.data.categories
          this.dataLoaded = true
          this.error = false
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this))
      },
      addCategory() {
        if (this.$refs.form.validate()) {
          this.categories.push({
              id: null,
              title: this.categoryTitle
          })
          this.categoryTitle = ''
          this.sortCategories()
          this.$refs.form.reset()
          this.closeCategoryDialog()
        }
      },
      editCategory() {
        if (this.$refs.form.validate()) {
          this.categories[this.editItemIndex].title = this.categoryTitle
          this.categoryTitle = ''
          this.editItem = null
          this.editItemIndex = null
          this.sortCategories()
          this.$refs.form.reset()
          this.closeCategoryDialog()
        }
      },
      removeCategory(index) {
        this.categories.splice(index, 1)
      },
      sortCategories() {
        this.categories.sort((a, b) => {
          const nameA = a.title.toUpperCase()
          const nameB = b.title.toUpperCase()
          if (nameA < nameB) {
              return -1
          }
          if (nameA > nameB) {
              return 1
          }
          return 0
        })
      },
      openCategoryDialog(item, index) {
        if (item) {
          this.categoryTitle = item.title
          this.editItem = item
          this.editItemIndex = index
        }
        this.categoryDialog = true
      },
      closeCategoryDialog() {
        this.categoryDialog = false
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .edit-row-table {
    cursor: pointer;
  }

  .apply-setting {
    color: #FFF !important;
    background: #FF5252 !important;
  }
  
  .settings-wrapper {
    justify-content: space-between;
  
    .v-card:first-child {
        width: calc(66.66% - 16px);
        min-width: 600px;
    }

    .v-data-table {
        margin-bottom: 0;
    }
  }

  .button-wrapper {
    text-align: right;
    padding-bottom: 16px;
  }
  </style>