import { acceptHMRUpdate, defineStore } from 'pinia'
import moment from 'moment'

const initialState = { 
  selectedUser: null,
  selectedCustomer: null,
  dateStart: moment().subtract(6, 'months').format('YYYY-MM-DD'),
  dateEnd: moment().add(6, 'months').format('YYYY-MM-DD'),
  categories: [],
  status: []
}

const store = defineStore('TaskFilterStore', {
  state: () => {
    return {
      filter: {
        ...initialState
      },
      appliedFilter: {
          ...initialState
      },
      table: {
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: ['created_at'],
        sortDesc: [true]
      },
    }
  },
  getters: {
    hasChanges: (state) => {
      return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
    },
    isInitialState: (state) => {
      return JSON.stringify(state.filter) === JSON.stringify(initialState)
  }
  },
  actions: {
    filterApplied() {
      this.table.currentPage = 1
      this.appliedFilter = { ...this.filter }
    }
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useTaskFilterStore = store
