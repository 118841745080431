export function mapDataTableConfig(filter, options) {
  const updatedFilter = {
    ...filter,
    page: options.page || 1,
    itemsPerPage: options.itemsPerPage ?? 10,
  };

  if (options.sortBy && options.sortBy.length) {
    updatedFilter.sortBy = options.sortBy[0];
    updatedFilter.sortDesc = options.sortDesc[0] ? "desc" : "asc";
  }

  return updatedFilter;
}