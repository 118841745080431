const themesSmg = {
  themes: {
    light: {
      primary: '#225499',
      secondary: '#B3D4F4',
      success: '#71AE5C',
      warning: '#E30613',
      error: '#FB8C00',
      buttons:'#225499',
      accent: '#BB74EC'
    }
  },
}

export default themesSmg
