<template>
  <div class="has-hero-container">

    <toolbar :dataLoaded="true">{{ t('evaluations-title') }}</toolbar>

    <div class="sp-hero sp-bg-light-blue">
      <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

        <app-filter-apply-button
          :has-changes="filterStore.hasChanges"
          :is-initial-state="filterStore.isInitialState"
          :is-loading="loading"
          @click="applyFilter();"
          @reset="filterStore.$reset(); applyFilter();"
        ></app-filter-apply-button>

        <v-slide-group show-arrows>
          <v-slide-item>
            <div class="filter-search-list">
              <div class="filter-item">
                <div class="filter-item-wrapper">
                  <app-filter-search-field
                    v-model="filterStore.filter.searchTerm"
                    :filterLabel="$t('components.learning-portal-title-search.label')"
                    clearable
                    @click:clear="clearSearchTerm"
                    append-icon="mdi-magnify"
                  ></app-filter-search-field>
                </div>
              </div>
            </div>
          </v-slide-item>

          <v-slide-item>
            <div class="filter-search-list">
              <div class="filter-item">
                <div class="filter-item-wrapper">
                  <learning-portal-topics-filter
                    v-model="filterStore.filter.topics"
                    ></learning-portal-topics-filter>
                </div>
              </div>
            </div>  
          </v-slide-item>
        </v-slide-group>
      </v-container>
    </div>

    <v-container fluid>

      <v-row class="data-table-toolbar"></v-row>

      <v-row v-if="dataLoaded && trainings.length === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-else>

        <v-data-table
          :headers="headers"
          :items="trainings"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-0"
          :page.sync="filterStore.table.currentPage"
          :items-per-page.sync="filterStore.table.itemsPerPage"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': t('table.items-per-page')
          }" 
        >
          <template v-slot:[`item.status`]="{ item }">
            <span class="status-chip active" v-if="item.is_active">            
              <v-icon left>
                mdi-eye-outline
              </v-icon>
              {{ t('table.active') }}
            </span>
            <span class="status-chip inactive" v-else>
              <v-icon left>
                mdi-eye-off-outline
              </v-icon>
              {{ t('table.inactive') }}
            </span>
          </template>

          <template v-slot:[`item.folders`]="{ item }">
            {{ item.folders.map(f => f.name).join(', ') }}
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <router-link
              v-if="item.participants > 0"
              :to="{ name: 'LearningPortalEvaluationsShow', params: { id: item.id} }"
              class="edit-row-table"
            >
              <v-icon
                small
                class="edit-row-button"
              >
                mdi-eye
              </v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import LearningPortalTopicsFilter from '@/components/filter-components/LearningPortalTopicsFilter'
import { useLearningPortalEvaluationsFilterStore } from '@/stores/LearningPortalEvaluationsFilterStore'

export default {
  name: 'LearningPortalEvaluationsIndex',
  components: {
    toolbar,
    AppFilterApplyButton,
    AppFilterSearchField,
    LearningPortalTopicsFilter
  },
  setup() {
    const filterStore = useLearningPortalEvaluationsFilterStore()
    return { filterStore }
  },
  data() {
    return {
      trainings: [],
      totalItems: 0,
      options: {},
      dataLoaded: false,
      loading: false,
      error: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getTrainings()
      },
      deep: true,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.title'),
          align: 'start',
          sortable: false,
          value: 'title',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.folders'),
          align: 'start',
          sortable: false,
          value: 'folders',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.participants'),
          align: 'start',
          sortable: false,
          value: 'participants',
          width: 150,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          width: 150,
          cellClass: 'text-middle'
        },
        {
          text: '',
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 75,
          cellClass: 'text-middle'
        }
      ]
    }
  },
  methods: {
    t: function (key) {
      return this.$t('learning-portal.trainings.list.' + key)
    },
    reload () {
      this.$nextTick(() => {
        this.trainings = []
        this.getTrainings()
      })
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    clearSearchTerm() {
      this.filterStore.filter.searchTerm = ''
    },
    getTrainings() {
      if (!this.loading) {
        this.loading = true
        this.dataLoaded = false

        this.apiCall().then(data => {
          this.trainings = data.items
          this.totalItems = data.total
          this.loading = false
          this.dataLoaded = true
        })
      }
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const { page, itemsPerPage } = this.options
        let url = this.prepareUrl(page, itemsPerPage)

        HTTP.get('learning_portal_training' + url).then(function (response) {
          let items = response.data.data
          let total = response.data.total
      
          resolve({
            items,
            total,
          })
        }.bind(this))
      })
    },
    prepareUrl(page, itemsPerPage) {
        let queryStringParts = []
        if (this.filterStore.filter.searchTerm) {
            queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
        }
        if (this.filterStore.filter.topics.length > 0) {
            queryStringParts.push('topics=' + this.filterStore.filter.topics)
        }
        page = page ? page : 1
        queryStringParts.push('page=' + page)
        if (itemsPerPage != null) {
            queryStringParts.push('itemsPerPage=' + itemsPerPage)
        }
        return '?' + queryStringParts.join('&')
    }
  }
}
</script>

<style scoped lang="scss">
.status-chip {
  display: inline-block; 
  font-size: 14px;
  line-height: 20px;
  padding: 1px 8px;
  border-radius: 8px;

  &.active {
    color: var(--v-success-base);
    background: rgba(112, 210, 115, 0.19);

    .v-icon {
      color: var(--v-success-base);
    }
  }

  &.inactive {
    color: var(--v-warning-base);
    background: rgba(254, 197, 125, 0.24);

    .v-icon {
      color: var(--v-warning-base);
    }
  }
}
</style>
