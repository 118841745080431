

const themesCar = {
  themes: {
    light: {
      primary: '#007AC6',
      secondary: '#B3D7EE',
      success: '#71AESC',
      warning: '#FB8C00',
      error: '#E30713',
      buttons: '#1B4A82'
    }
  },
}

export default themesCar
