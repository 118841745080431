<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>
    
      <v-row v-if="dataLoaded" class="settings-wrapper">

        <v-card elevation="0">
          <v-card-title>{{ t('group-title') }}</v-card-title>
          <v-card-text>
            <p v-if="!validations['categories']" class="dr-validation-message">
              {{ t('validation.no-catgeories') }}
            </p>
            <v-spacer></v-spacer>
            <div class="button-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog('categories', null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('create') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="settings.categories"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="settings.categories"
                  tag="tbody"
                >
                  <data-table-damage-report-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                    setting="categories"
                  ></data-table-damage-report-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

      </v-row>

    </v-container>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
              color="primary"
              text
              class="icon-left"
              @click="reset()"
              :disabled="saving"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                class="icon-left"
                @click="saveSettings()"
                :disabled="!hasChanges || loading || !dataLoaded"
                :loading="saving"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

    <v-dialog
      v-model="settingsDialog"
      content-class="settings-dialog"
      width="600"
      persistent
    >
      <v-card v-if="settingsDialog">
        <v-card-title>
          {{ editItem.id ? t('dialog.edit-title') : t('dialog.add-title') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent="editItem.id ? editSettings() : addSettings()">
            <div class="d-flex justify-space-between align-center mb-4">
              <v-checkbox
                class="mt-0 pt-0"
                :label="t('dialog.status-label')"
                v-model="editItem.is_active"
                hide-details
              ></v-checkbox>

              <status-icon-widget
                :status="activeStatus"
                :hide-text="false"
              ></status-icon-widget>
            </div>
            
            <v-text-field
              :label="t('dialog.placeholder')"
              v-model="editItem.name"
              counter="120"
              filled
              :rules="[
                () => !!editItem.name || t('validation.error-title-required'),
                () => (editItem.name && editItem.name.length <= 120) || t('validation.error-max-title-120')
              ]"
            ></v-text-field>

            <v-divider></v-divider>

            <v-checkbox
              class="mt-4 pt-0"
              :label="t('dialog.customer-label')"
              v-model="editItem.enable_customer_selection"
              @change="handleCustomerRequired($event)"
              hide-details
            ></v-checkbox>

            <v-checkbox
              class="my-4 pt-0"
              :label="t('dialog.customer-required-label')"
              :disabled="!editItem.enable_customer_selection"
              v-model="editItem.customer_required"
              hide-details
            ></v-checkbox>

            <v-divider></v-divider>

            <v-checkbox
              class="my-4 pt-0"
              :label="t('dialog.addition-field-label')"
              v-model="editItem.enable_additional_field"
              @change="handleAdditionalFieldRequired($event)"
              hide-details
            ></v-checkbox>

            <v-text-field
              :label="t('dialog.addition-field-placeholder')"
              v-model="editItem.additional_field_name"
              :disabled="!editItem.enable_additional_field"
              counter="120"
              filled
              :rules="editItem.enable_additional_field ? [
                () => !!editItem.additional_field_name || t('validation.error-title-required'),
                () => (editItem.additional_field_name && editItem.additional_field_name.length <= 120) || t('validation.error-max-title-120')
              ] : []"
            ></v-text-field>

            <v-checkbox
              class="mt-0 pt-0"
              :label="t('dialog.addition-field-required-label')"
              :disabled="!editItem.enable_additional_field"
              v-model="editItem.additional_field_required"
              hide-details
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="closeSettingsDialog()"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('dialog.cancel') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            @click="editItem.id ? editSettings() : addSettings()"
          >
            <v-icon>mdi-check</v-icon>
            {{ editItem ? t('dialog.apply') : t('dialog.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import DataTableDamageReportRowHandler from '@/components/DataTableDamageReportRowHandler'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import draggable from 'vuedraggable'

export default {
  name: 'SettingsDamageReport',
  components: {
    toolbar,
    loading,
    DataTableDamageReportRowHandler,
    StatusIconWidget,
    draggable
  },
  data() {
    return {
      settings: {
        categories: []
      },
      dataOriginal: {},
      dataLoaded: false,
      loading: false,
      saving: false,
      error: false,
      settingsDialog: false,
      settingsType: null,
      editItem: null,
      editItemIndex: null,
      typeMapping: {
        'categories': 'categories'
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          width: 120
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 120
        },
        {
          text: '',
          value: 'remove',
          sortable: false,
          align: 'center',
          width: 100,
        }
      ]
    },
    hasChanges() {
      return JSON.stringify(this.settings) !== JSON.stringify(this.dataOriginal)
    },
    validations() {
      return {
        'categories': this.settings.categories.length > 0
      }
    },
    activeStatus() {
      return this.editItem && this.editItem.is_active ? 'active' : 'inactive'
    }
  },
  created() {
    this.getSettings()
    EventBus.$on('dr-open-settings-dialog', (settingsType, index, item) => {
      this.openSettingDialog(settingsType, item, index)
    })
    EventBus.$on('dr-remove-item', (settingsType, index) => {
      this.removeSetting(settingsType, index)
    })
  },
  methods: {
    t: function (key) {
      return this.$t('damage-report.settings.' + key)
    },
    getSettings() {
      this.loading = true

      Promise.allSettled([
        this.getCategories()      
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'fulfilled') {
            this.settings[response.value.type] = response.value.data
          }
          if (response.status == 'rejected') {
            errors.push(this.t('group-title') + ' : ' + response.reason.error)
          }
        })

        if (errors.length > 0) {
          errors.unshift(this.t('get-request-error'))
          this.$root.infoNotification.showMessage(errors)
        }

        this.dataOriginal = JSON.parse(JSON.stringify(this.settings))

        this.dataLoaded = true
        this.loading = false
        this.error = false

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    getCategories() {
      return new Promise(function(resolve, reject) {
        HTTP.get('damage-report-categories').then((response) => {
          resolve({
            'type': 'categories',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    saveCategories() {
      if (this.settings.categories.length == 0) {
        return Promise.reject({
          'type': 'categories',
          'error': 'Empty Array'
        })
      } else {
        return new Promise(function(resolve, reject) {
          HTTP.post('damage-report-categories', this.settings.categories).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      }
    },
    saveSettings() {
      this.saving = true

      Object.keys(this.validations).forEach((type) => {
        if (!this.validations[type]) {
          this.saving = false
          return
        }
      })

      Promise.allSettled([
        this.saveCategories()
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'rejected') {
            errors.push(this.t('group-title') + ' : ' + response.reason.error)
          }
        })

        if (errors.length > 0) {
          errors.unshift(this.t('post-request-error'))
          this.$root.infoNotification.showMessage(errors, true)
        }
        
        this.getSettings()

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    reset() {
      this.dataLoaded = false
      this.getSettings()
    },
    openSettingDialog(settingsType, item, index) {
      if (item) {
        this.editItem = JSON.parse(JSON.stringify(item))
        this.editItemIndex = index
      } else {
        this.editItem = {}
        this.editItem.id = null
        this.$set(this.editItem, 'name', '')
        this.$set(this.editItem, 'is_active', true)
        this.$set(this.editItem, 'enable_customer_selection', true)
        this.$set(this.editItem, 'customer_required', false)
        this.$set(this.editItem, 'enable_additional_field', false)
        this.$set(this.editItem, 'additional_field_name', '')
        this.$set(this.editItem, 'additional_field_required', false)
      }
      this.settingsType = settingsType
      this.settingsDialog = true
    },
    closeSettingsDialog() {
      this.settingsDialog = false
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.settingsType = null
        this.editItem = null
        this.editItemIndex = null
      })
    },
    addSettings() {
      if (this.$refs.form.validate()) {
        this.settings[this.typeMapping[this.settingsType]].push({
          id: -1,
          name: this.editItem.name,
          is_active: this.editItem.is_active,
          enable_customer_selection: this.editItem.enable_customer_selection,
          customer_required: this.editItem.customer_required,
          enable_additional_field: this.editItem.enable_additional_field,
          additional_field_name: this.editItem.additional_field_name,
          additional_field_required: this.editItem.additional_field_required
        })
        this.closeSettingsDialog()
      }
    },
    editSettings() {
      if (this.$refs.form.validate()) {
        Object.assign(this.settings[this.typeMapping[this.settingsType]][this.editItemIndex], this.editItem)
        this.closeSettingsDialog() 
      }
    },
    removeSetting(settingsType, index) {
      this.settings[this.typeMapping[settingsType]].splice(index, 1)
    },
    handleCustomerRequired(event) {
      if (!event) {
        this.editItem.customer_required = event
      }
    },
    handleAdditionalFieldRequired(event) {
      if (!event) {
        this.editItem.additional_field_required = event
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-loader {
  padding: 0 !important;
  height: auto !important;
}

.edit-row-table {
  cursor: pointer;
}

.settings-wrapper {
  justify-content: space-between;

  & > .v-card {
      width: calc(66.66% - 16px);
  }

  .v-data-table {
      margin-bottom: 0;
  }
}

.dr-validation-message {
  float: left;
  padding-top: 8px;
  font-weight: bold;
  color: var(--v-error-base);
}

.button-wrapper {
  text-align: right;
  padding-bottom: 16px;
}
</style>
