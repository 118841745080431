<template>
  <div>
    <BasicCreateEditContainer
      :title="mode == 'create' ? t('create.title') : t('edit.title')"
      ref="editor"
      object-type="Instruction"
      :object-name="data.title"
      :data-loaded="dataLoaded"
      @cancel="close"
      :save-action="saveDocument"
      @saved="onSaved"
      :allow-delete="mode === 'edit'"
      :delete-action="deleteDocument"
      @deleted="onDeleted"
      :has-changes="hasChanges"
      :deletion-hint-text="deletionHintText"
      :deletion-hint-list="deletionHintList"
    >
      <template v-slot:content>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
              :label="t('details.title')"
              counter="120"
              v-model="data.title"
              filled
              :rules="[
                () => data.title.length <= 120 || $t('validation.length.title')
              ]"
            ></v-text-field>

            <v-text-field
              :label="t('details.description')"
              counter="120"
              v-model="data.description"
              filled
              :rules="[
                () => data.description.length <= 120 || $t('validation.length.description')
              ]"
            ></v-text-field>

            <div v-if="data.url" class="existing-file">
              <v-icon>
                mdi-paperclip
              </v-icon>
              <span>{{ data.fileName }} ( {{data.fileSize}}kb )</span>
              <v-icon @click="data.url = ''">
                delete
              </v-icon>
            </div>

            <v-file-input
              v-else
              :label="t('details.choose-file')"
              truncate-length="40"
              show-size
              filled
              v-model="data.document"
              @selected="onDocumentFileChange($event)"
              :rules="[
                () => !!data.document || $t('validation.required.default')
              ]">
            </v-file-input>

            <v-text-field
              :label="t('details.keywords')"
              v-model="data.keywords"
              filled
              hide-details="auto"
            ></v-text-field>

          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="data.assigned_permission_groups"
              :required="true"
              mode="dropdown"/>
          </v-card-text>
        </v-card>

        <v-card v-if="mode === 'edit'" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('move-document.group-title') }}</v-card-title>
          <v-card-text>
            <div>
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                @click="dialogVisible=true"
              >
                <v-icon>mdi-folder-search-outline</v-icon>
                {{ t('move-document.choose-folder') }}
              </v-btn>
              <p v-if="moveToDir" class="sp-mar-top-2">{{ t('move-document.will-be-moved') }}: <b>{{ moveToDir.name }}</b></p>
            </div>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-push-settings.label') }}</v-card-title>
          <v-card-text>
            <news-push
              v-model="data.push"
              :show-last-push="false"
              :can-push-now="false"
              :can-configure-push="true"
              :can-push-on-publish="false"
              :can-auto-push="true"
              :push-not-before="minPushDate"
              @valid="updatePushValid" 
            ></news-push>
          </v-card-text>
        </v-card>

      </template>

    </BasicCreateEditContainer>

    <MoveToFolderDialog
      :dialog="dialogVisible"
      :root-name="$t('instructions.list.title')"
      :parent-folder-id="data.parent_folder_id"
      get-url="instructions_folders"
    ></MoveToFolderDialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import moment from 'moment'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import PermissionGroupSelector from "@/components/PermissionGroupSelector.vue"
import MoveToFolderDialog from '@/components/dialogs/MoveToFolderDialog'
import NewsPush from '@/views/NewsPush.vue'

export default {
  name: 'InstructionsCreateEditDocument',
  components: {
    BasicCreateEditContainer,
    MoveToFolderDialog,
    PermissionGroupSelector,
    NewsPush
  },
  data() {
    return {
      data: {
        id: null,
        title: '',
        description: '',
        assigned_permission_groups: [],
        keywords: null,
        targetDir: 'instructions_documents',
        parent_folder_id: null,
        document: null,
        url: '',
        push: {
          mode: 'auto',
          date: null,
        },
      },
      validation: {
        validPush: true
      },
      dataLoaded: false,
      dataOriginal: null,
      mode: null,
      moveToDir: null,
      deletionHintText: '',
      deletionHintList: [],
      error: null,
      dialogVisible: false
    }
  },
  created() {
    if (this.$route.name === 'InstructionsDocumentCreate') {
      const parentFolderId = this.$route.params.parent_id
      if (!isNaN(parentFolderId)) {
        this.data.parent_folder_id = parentFolderId
      }
      this.mode = 'create'
      this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      this.dataLoaded = true
    }
    if (this.$route.name === 'InstructionsDocumentEdit') {
      const id = this.$route.params.id;
      if (!isNaN(id)) {
        this.mode = 'edit'
        this.getDocument(id)
      }
    }
    EventBus.$on('move-to-dir-select', function (folder) {
      this.moveToDir = folder
      this.dialogVisible = false
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('move-to-dir-select')
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal) || !!this.moveToDir
    },
    minPushDate() {
      return moment()
    },
    dataValid() {
      let isValid = true
      if (!this.validation.validPush) {
        isValid = false
      }
      return isValid
    },
  },
  methods: {
    t: function (key) {
      return this.$t('instructions.document.' + key)
    },
    getDocument(id) {
      HTTP.get('instructions_documents/' + id).then(function (response) {
        this.data = response.data
        // Prepare permission groups for select input
        const tempPermGroupsIds = []
        response.data.assigned_permission_groups.forEach((element) => {
          tempPermGroupsIds.push(element.permission_group_id)
        })
        this.data.assigned_permission_groups = tempPermGroupsIds
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        if (error.response.status && error.response.status === 404) {
            this.$root.infoNotification.showMessage(this.$t('instructions.document.not-found', { documentId: id }))
        } else {
          this.$root.infoNotification.showMessage(this.$t('instructions.document.unknown-error') + error.response.statusText)
        }
        this.close()
      }.bind(this))
    },
    saveDocument() {
      if (!this.dataValid) {
        return
      }

      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }

      let formData = new FormData()
      if (this.data.document) {
        formData.append('instruction_document', this.data.document);
      }

      if (this.data.id && this.moveToDir != null) {
          this.data.parent_folder_id = this.moveToDir.folder_id;
          this.data.change_folder = true;
        }

      formData.append('data', JSON.stringify(this.data))

      if (this.data.id) {
        return HTTP.put('instructions_documents/' + this.data.id, formData, config)
      } else {
        return HTTP.post('instructions_documents', formData, config)
      }
    },
    onSaved() {
      if (this.dataValid) {
        this.dataOriginal = null
        this.close()
      }
    },
    updatePushValid(val) {
      this.validation.validPush = val
    },
    deleteDocument() {
      HTTP.delete('instructions_documents/' + this.data.id)
        .then(function () {
          //
        }.bind(this))
        .catch(function (error) {
          if (error.response.data.errors) {
              this.message = error.response.data.message
            }
            console.log(error.response)
        }.bind(this))
    },
    onDeleted() {
      this.dataOriginal = null
      this.close()
    },
    isEmptyFile(file) {
      return !file || file.size === 0
    },
    close() {
      let path = '/instructions'
      if (this.data.parent_folder_id) {
        path += '/' + this.data.parent_folder_id
      }
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
.existing-file {
  margin-bottom: 30px;

  .mdi-paperclip {
    margin-top: 1px;
  }

  span {
    display: inline-block;
    width: calc(100% - 66px);
    background: #F5F5F5;
    color: #000000;
    padding: 17px 12px;
    margin: 0 9px;
    border-radius: 4px;
  }
}
</style>
