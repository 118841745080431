<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                      v-model="filterStore.filter.searchTerm"
                      :filterLabel="$t('components.app-filter-search-field.label.title')"
                      clearable
                      @click:clear="clearSearchTerm"
                      append-icon="mdi-magnify"
                    ></app-filter-search-field>
                  </div>
                </div>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-status-filter">
                <select-status-filter-widget 
                  v-model="filterStore.filter.status"
                  :multiple="true"
                  :filter-list="['published', 'draft', 'archived', 'planned']"
                ></select-status-filter-widget>
              </div>
            </v-slide-item>
          </v-slide-group>

        </v-container>
      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            data-cy="createButton"
            :to="{ name: 'JobsCreate' }"
          >
            <v-icon>mdi-plus</v-icon>
            {{ t('create') }}
          </v-btn>
        </v-row>

        <v-row v-if="dataLoaded && jobsPosts.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            :headers="headers"
            :items="jobsPosts"
            :options.sync="options"
            :server-items-length="totalJobs"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
                'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.title`]="{ item }">
              <span class="ellipsis" v-if="item.title"><b>{{ item.title }}</b></span>
              <span class="ellipsis preview-text">{{ item.preview_text }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at) }}<br>{{ formatTime(item.created_at) }}
            </template>

            <template v-slot:[`item.published_at`]="{ item }">
              <span v-if="item.status === 'published' || item.status === 'publishat'">
                {{ formatDate(item.published_at) }}<br>{{ formatTime(item.published_at) }}
              </span>
            </template>
            
            <template v-slot:[`item.pushed_at`]="{ item }">
              <span>
                <span v-if="item.pushed_at" title="Push versendet" class="d-block">
                  {{ formatDate(item.pushed_at) }}<br/>{{ formatTime(item.pushed_at) }}
                </span>
                <span v-if="item.push_next_at" title="Push geplant" class="d-block">
                  {{ formatDate(item.push_next_at) }}<br/>{{ formatTime(item.push_next_at) }}
                </span>
              </span>
            </template>

            <template v-slot:[`item.read`]="{ item }">
              <span v-if="item.analytics_calculated">
                {{ item.analytics_count }}
              </span>
              <span v-else class="analytics-loading"></span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <status-icon-widget :status="item.status" :hide-text="false"/>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/jobs/' + item.id }" class="edit-row-table">
                <v-icon
                  small
                  class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import axios from 'axios'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import SelectStatusFilterWidget from '@/components/filter-components/SelectStatusFilterWidget'
import { useJobsFilterStore } from '@/stores/JobsFilterStore'

export default {
  name: 'JobsIndex',
  components: {
    toolbar,
    AppFilterSearchField,
    AppFilterApplyButton,
    StatusIconWidget,
    SelectStatusFilterWidget
  },
  setup() {
    const filterStore = useJobsFilterStore()
    return { filterStore }
  },
  data() {
    return {
      loading: false,
      jobsPosts: [],
      totalJobs: 0,
      options: {},
      dataLoaded: false,
      message: null,
      error: false,
      cancelHTTP: null
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  computed: {
    filterStatus() {
      return [
        {
          name: this.$t('jobs.data.status.published'),
          slug: 'PUBLISHED'
        },
        {
          name: this.$t('jobs.data.status.draft'),
          slug: 'DRAFT'
        },
        {
          name: this.$t('jobs.data.status.archived'),
          slug: 'ARCHIVED'
        }
      ]
    },
    headers() {
      return [
        {
          text: this.t('table.title'),
          value: 'title',
          sortable: true,
          align: 'start',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.created-at'),
          value: 'created_at',
          sortable: true,
          align: 'left',
          width: 155
        },
        {
          text: this.t('table.published-at'),
          value: 'published_at',
          sortable: true,
          align: 'left',
          width: 155
        },
        {
          text: this.t('table.pushed-at'),
          value: 'pushed_at',
          sortable: false,
          align: 'left',
          width: 155
        },
        {
          text: this.t('table.read'),
          value: 'read',
          sortable: false,
          align: 'center',
          width: 100
        },
        {
          text: this.t('table.status'),
          value: 'status',
          width: 100,
          align: 'start',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 75
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('jobs.list.' + key)
    },
    moment: function () {
      return moment()
    },
    reload() {
      this.$nextTick(function () {
        this.jobsPosts = []
        this.getDataFromApi()
      }.bind(this))
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    clearSearchTerm() {
      this.filterStore.filter.searchTerm = ''
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.status.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.status)
      }
      if (this.filterStore.filter.searchTerm ?? '' > 1) {
        queryStringParts.push('term=' + this.filterStore.filter.searchTerm)
      }
      queryStringParts.push('sortBy=' + sortBy[0])
      let sort = sortDesc[0] ? 'desc' : 'asc'
      queryStringParts.push('sortDesc=' + sort)
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    getDataFromApi() {
      if (!this.loading) {
        this.loading = true
        this.dataLoaded = false

        this.apiCall().then(data => {
          this.jobsPosts = data.jobsPosts
          this.totalJobs = data.total
          this.loading = false
          this.dataLoaded = true
        })
      }
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage) 

        HTTP.get('jobs' + url).then(function (response) {
          let jobsPosts = response.data.data

          jobsPosts.forEach((element, index) => {
            element.status = this.getStatusValue(element)
            if (!element.analytics_calculated) {
              this.getAnalyticsData(element.id, index)
            }
            if (!element.push_status_received) {
              this.getPushStatus(element.id, index)
            }
          })

          let total = response.data.total

          resolve({
            jobsPosts,
            total,
          })

        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this)) 
      })
    },
    getAnalyticsData(itemId, index) {
      HTTP.get('analytics/jobs/' + itemId, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (this.jobsPosts[index]) {
          this.$set(this.jobsPosts[index], 'analytics_count', response.data.read_total)
          this.$set(this.jobsPosts[index], 'analytics_total', response.data.total)
          this.$set(this.jobsPosts[index], 'analytics_calculated', true)
        }
      }.bind(this))
      .catch(function (error) {
        console.log(error)
      }.bind(this))
    },
    getStatusValue(element) {
      if (element.deleted_at) {
        return 'archived'
      } else {
        if (!element.published_at) {
          return 'draft'
        } else {
          if (moment().isBefore(element.published_at)) {
            return 'planned'
          } else {
            return 'published'
          }
        }
      }
    },
    getPushStatus(itemId, index) {
      HTTP.get('jobs/' + itemId, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (this.jobsPosts[index]) {
          this.$set(this.jobsPosts[index], 'pending_push', response.data.pending_cronjob)
          this.$set(this.jobsPosts[index], 'push_status_received', true)
        }
      }.bind(this))
      .catch(function (error) {
        console.log(error)
      }.bind(this))
    },
    isPublished(publishedAt) {
      return moment().isAfter(publishedAt)
    }
  }
}
</script>

<style scoped lang="scss">
.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
}

.preview-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.has-column-icon {
  display: inline-block;
  position: relative;
  padding: 0 5px 0 28px;
  font-size: 14px;

  i {
    position: absolute;
    left: 0;
  }
}

.analytics-loading {
  left: 0;
  top: 5px;
}

.text-start .analytics-loading {
  left: 40px;
  top: 15px;
}
</style>
