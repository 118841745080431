const themesCub = {
  themes: {
    light: {
      primary: '#584DFF',
      secondary: '#ECEBFF',
      success: '#39BD6B',
      warning: '#FF9D00',
      error: '#FF5252',
      buttons: '#584DFF',
      accent: '#00A1FF'
    }
  },
}

export default themesCub
