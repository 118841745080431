<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ $t('expenses.list.title') }}</toolbar>
      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            :can-refresh="true"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('from')"
                  v-model="filterStore.filter.dateStart"
                  id="dateStart"
                  clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('to')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-search-list">
                <user-filter-widget
                  v-model="filterStore.filter.selectedUser"
                  :preselected="filterStore.filter.selectedUser"
                  :includeInactive="true"
                ></user-filter-widget>
              </div>
            </v-slide-item>

            <v-slide-item>
                <expense-status-filter v-model="filterStore.filter.status"/>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <expense-type-filter v-model="filterStore.filter.types"/>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar"></v-row>

        <v-row v-if="dataLoaded && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            class="elevation-0"
            :sort-by="initialSortBy"
            :sort-desc="initialSortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.date`]="{ item }">
              <span class="ellipsis">{{ formatDate(item.date) }}</span>
            </template>

            <template v-slot:[`item.firstname`]="{ item }">
              {{ item.user.firstname }}
            </template>

            <template v-slot:[`item.lastname`]="{ item }">
              {{ item.user.lastname }}
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <span v-if="item.name_code">
                {{ $t('expenses.data.type.' + item.name_code) }}
              </span>
              <span v-if="!item.name_code">
                {{ item.name }}
              </span>
            </template>

            <template v-slot:[`item.expense`]="{ item }">
              <span v-if="item.type.calculation_type_code === 'AT_COST'">
                {{ item.total_amount_incl_vat }} {{ item.currency }}</span>
              <span v-if="item.type.calculation_type_code === 'HOURLY_RATE'">
                {{ item.hours }} {{ t('hours') }}</span>
              <span v-if="item.type.calculation_type_code === 'DAILY_RATE'">
                1 {{ t('one-day') }}</span>
              <span v-if="item.type.calculation_type_code === 'FACTOR'">
                {{ item.quantity }} {{ item.unit }}</span>
            </template>

            <template v-slot:[`item.reimbursement`]="{ item }">
              <span v-if="item.is_reimbursed && item.reimbursement_amount">
                {{ item.reimbursement_amount }} {{ item.reimbursement_currency }}
              </span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <StatusIcons :status="item.status"/>
            </template>

            <template v-slot:[`item.details`]="{ item }">
              <router-link :to="{ path: '/expenses/' + item.id }" class="edit-row-table">
                <v-icon small class="edit-row-button">edit</v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </div>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import ExpenseStatusFilter from '@/components/filter-components/ExpenseStatusFilter'
import StatusIcons from '@/components/layouts/StatusIcons'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import ExpenseTypeFilter from '@/components/filter-components/ExpenseTypeFilter'
import { useExpenseFilterStore } from '@/stores/ExpenseFilterStore'

export default {
  name: 'ExpenseIndex',
  components: {
    ExpenseTypeFilter,
    StatusIcons,
    AppFilterApplyButton,
    ExpenseStatusFilter,
    UserFilterWidget,
    toolbar,
    AppFilterDatePicker,
    AppFilterSearchField
  },
  setup() {
    const filterStore = useExpenseFilterStore()
    return { filterStore }
  },
  data() {
    return {
      items: [],
      loading: false,
      options: {},
      initialSortBy: '',
      initialSortDesc: false,
      dataLoaded: false,
    }
  },
  created() {
    this.applyFilter()
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.date'),
          align: 'start',
          sortable: false,
          value: 'date',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.firstname'),
          align: 'start',
          sortable: false,
          value: 'firstname',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.lastname'),
          align: 'start',
          sortable: false,
          value: 'lastname',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.type'),
          align: 'start',
          sortable: false,
          value: 'type',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.expense'),
          align: 'end',
          sortable: false,
          value: 'expense',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.reimbursement'),
          align: 'end',
          sortable: false,
          value: 'reimbursement',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.status'),
          align: 'center',
          sortable: false,
          value: 'status'
        },
        {
          text: this.t('table.details'),
          align: 'center',
          sortable: false,
          value: 'details'
        }
      ]
    }
  },
  methods: {
    t: function (key) {
      return this.$t('expenses.list.' + key)
    },
    buildFilter() {
      let filter = this.filterStore.filter
      return {
        from: filter.dateStart,
        to: filter.dateEnd,
        userid: filter.selectedUser?.id,
        status: filter.status,
        types: filter.types
      }
    },
    async loadData() {
      if (!this.loading) {
        let filter = this.buildFilter()
        this.loading = true
        try {
          let response = await HTTP.get('expenses', { params: filter })
          this.items = response.data.data
        } finally {
          this.loading = false
        }
      }
    },
    async reload() {
      this.$nextTick(() => {
        this.items = []
        this.loadData()
      })
    },
    async applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
  }
}
</script>

<style scoped lang="scss">
.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
