<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t("title") }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter">

          <v-slide-group show-arrows>

            <v-slide-item>

              <div>
                <div class="filter-item temporary">
                  <span class="md-caption">{{ t("filter.month") }}</span>
                  <date-picker
                    v-model="filter.dateMonth"
                    type="month"
                    format="MMMM YYYY"
                    value-type="date"
                  ></date-picker>
                </div>
              </div>

            </v-slide-item>

          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :disabled="!readyToDownload"
            :loading="isDownloading"
            @click="loadExcel()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t("btn-export") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

    </div>

  </div>

</template>

<script>
import { HTTP } from "@/auth"
import moment from "moment"
import { EventBus } from "@/event-bus.js"
import toolbar from "@/components/layouts/Navigation.vue"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import loading from "@/components/layouts/Loading.vue"
import AppFilterApplyButton from "@/components/vuetify/AppFilterApplyButton"

export default {
  name: "TimeSheetAllIndex",
  components: {
    AppFilterApplyButton,
    toolbar,
    DatePicker,
    loading
  },
  data() {
    return {
      filter: {
        dateMonth: moment().subtract(1, "month").startOf("month").toDate(),
      },
      isDownloading: false
    }
  },
  computed: {
    readyToDownload: function() {
      return this.filter.dateMonth && !this.isDownloading
    },
  },
  methods: {
    t: function(key) {
      return this.$t("timelog-reportoverview." + key)
    },
    buildFilter() {
      let filter = {}
      filter.date = moment(this.filter.dateMonth).format("YYYY-MM-DD")
      return filter
    },
    async loadExcel() {
      this.isDownloading = true
      try {
        let filter = this.buildFilter()
        let response = await HTTP.post("timelog/reporting/zuschlag-excel", filter, { responseType: "arraybuffer" })
        let filename = "Jahrerapport-Jahr_" + moment(this.filter.dateMonth).month() + "-%TIMESTAMP%.xlsx"
        this.startDataDownload(response.data, response.headers["content-type"], filename)
      } finally {
        this.isDownloading = false
      }
    }
  }
}
</script>

<style lang="scss">
/* temp */
.filter-item.temporary {

  .md-caption {
    display: block;
    font-weight: 500;
    letter-spacing: 0.01em;
    font-size: 14px;
    line-height: 24px;
  }

  .mx-datepicker {
    margin: 0;
    padding: 0;

    .mx-input-wrapper {
      border-radius: 4px;
    }
  }
}

</style>

<style scoped lang="scss">
/* temp */
.sp-hero .filter-search-list {
  padding-top: 22px;
  // width: calc(66.66% - 134px);
}

/* temp */
.sp-app.v-application .container--fluid.hero-filter {
  padding-bottom: 28px;
}

/* temp */
.data-table-toolbar h2 {
  margin: 0;
}

/* temp */
.md-table-card {
  margin-bottom: 60px;
}

.user-selected .md-icon {
  margin: 4px !important;
  color: #FFF !important;
}

.user-selected .md-icon.delete {
  margin-left: auto !important;
}

.user-selected .md-icon.delete:hover {
  cursor: pointer;
}

</style>

