<template>
    <v-dialog
        v-model="dialog"
        content-class="dialog"
        width="1000"
    >
        <v-card>
            <v-card-title>
                {{ $t('components.menu-create-new-dialog.new-menu-entry') }}
            </v-card-title>

            <v-card-text>
                <span class="menu-create-title">{{ $t('components.menu-create-new-dialog.content-add') }}</span>
                <div class="button-block">
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="icon-left"
                        :to="getDynamicPath('module')"
                    >
                        <v-icon>mdi-checkbox-blank-badge</v-icon>
                        {{ $t('menu.list.module') }}
                    </v-btn>

                    <v-btn
                        elevation="0"
                        color="primary"
                        class="icon-left"
                        :to="getDynamicPath('content_block')"
                    >
                        <v-icon>mdi-view-dashboard</v-icon>
                        {{ $t('menu.list.content_block') }}
                    </v-btn>

                    <v-btn
                        elevation="0"
                        color="primary"
                        class="icon-left"
                        :to="getDynamicPath('webview')"
                    >
                        <v-icon>mdi-share</v-icon>
                        {{ $t('menu.list.webview') }}
                    </v-btn>

                    <v-btn
                        elevation="0"
                        color="primary"
                        class="icon-left"
                        :to="getDynamicPath('external_browser')"
                    >
                        <v-icon>mdi-share</v-icon>
                        {{ $t('menu.list.external_browser') }}
                    </v-btn>
                </div>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                elevation="0"
                @click="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
                {{ $t('components.menu-create-new-dialog.cancel') }}
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    name: "MenuCreateNewDialog",
    model: {
      prop: 'model',
      event: 'input'
    },
    props: {
      model: {
          default: () => [null]
      },
    },
    methods: {
        t: function (key) {
            return this.$t(key);
        },
        getDynamicPath(type) {
            return {
                name: 'MenuCreate',
                params: { type }
            };
        }
    },
    data() {
        return {
            dialog: this.model
        }
    },
    watch: {

        model: function(val) {
            this.dialog = val;
        },
        dialog: function(val) {
            this.$emit('input', val);
        }
    },
  };
</script>

<style scoped>
.menu-create-title {
    font-size: 14px;
    font-weight: 600;
}

.button-block {
    margin-top: 15px;
    margin-right: 20px;
    
}

.icon-left {
    margin-right: 15px;

    i {
        position: relative;
        left: -8px;
    }
}
</style>