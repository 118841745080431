<template>
  <div class="filter-status-filter">
    <div class="filter-item">
      <v-select
        v-model="selectedFilterStatus"
        :label="$t('components.content-filter-timesheet-status.label')"
        :items="filterStatus"
        item-text="name"
        item-value="slug"
        multiple
        chips
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimesheetApprovalStatusFilter",
  props: ['value'],
  data() {
    return {
      selectedFilterStatus: this.value,
      filterStatus: [
        {
          name: '',
          slug: 'submitted'
        },
        {
          name: '',
          slug: 'approved'
        },
        {
          name: '',
          slug: 'rejected'
        },
        {
          name: '',
          slug: 'cleared'
        },
        {
          name: '',
          slug: 'exported'
        }
      ],
    }
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val);
    },
    value: function (val) {
      this.selectedFilterStatus = val
    }
  },
  created() {
    let me = this;
    this.filterStatus.forEach(function (value) {
      value.name = me.$t('timesheet-approval.data.status.' + value.slug)
    })
  }
}
</script>
