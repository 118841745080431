<template>
    <div>
        <v-autocomplete
            ref="autocomplete"
            v-model="select"
            :loading="searching"
            :items="items"
            item-text="name"
            :search-input.sync="search"
            filled
            hide-no-data
            :label="labelData"
            append-icon="mdi-magnify"
            return-object
            clearable
            :spellcheck="false"
            :rules="rules"
        ></v-autocomplete>
    </div>
</template>

<script>
import { CancellingHttp } from '@/infrastructure/communication'

export default {
    name: "ContentFilterChecklist",
    emits: ['item-selected'],
    props: {
        rules: {
            type: Array,
            default: () => []
        },
        readonly: Boolean,
        preselected: null,
        value: {},
        getSearchUrl: null,
        label: {
            type: String
        }
    },
    data() {
        return {
            com: new CancellingHttp(),
            abortController: null,
            loading: false,
            searching: false,
            items: [],
            search: null,
            select: null
        }
    },
    computed: {
        labelData() {
            return (this.label) ? this.label : 'Nach Checkliste suchen'
        }
    },
    watch: {
        select(val) {
            this.$emit('input', val)
            this.$emit('item-selected', val?.id, val)
        },
        search(val) {
            if (!this.select) {
                if (this.select && this.select.hasOwnProperty('name')) {
                    val && val !== this.select.name && this.querySelections(val)
                } else {
                    val && val !== this.select && this.querySelections(val)
                }
            }
        },
        value: function (val) {
            this.select = val
        }
    },
    created() {
        this.com.on('loading', (val) => {
            this.loading = val
        })
        if (this.preselected) {
            this.items.push(this.preselected)
            this.select = this.items[0]
        }
    },
    methods: {
        async querySelections(val) {
            let url = this.getSearchUrl(val)
            this.searching = true
            let response = await this.com.get(url)
            if (response && response.data) {
                this.items = response.data
                this.searching = false
            }
        }
    }
}
</script>

<style scoped>
.v-autocomplete >>> .v-label {
    font-size: 12px;
    left: 0px !important;
}

.v-autocomplete >>> .v-input__icon {
    position: relative;
}
</style>
