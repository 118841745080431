// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import dateTimePlugin from './plugins/DateTime'
import helpersPlugin from './plugins/Helpers'
import downloadPlugin from './plugins/DataDownload'
import vuetify from './plugins/vuetify'
import tokenHelper from './token-helper'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import i18n from './i18n'
import 'vue-material/dist/vue-material.css'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import TreeComponent from '@/components/layouts/FileTreeListItem'

export { default as AppFilterSearchField } from '@/components/vuetify/AppFilterSearchField'
export { default as AppFilterDatePicker } from '@/components/vuetify/AppFilterDatePicker'
export { default as AppDateTimePicker } from '@/components/vuetify/AppDateTimePicker'
export { default as AppSearchField } from '@/components/vuetify/AppSearchField'
export var Event = new Vue({})

let isOidcCallback = window.location.href.includes("/oidc-callback")
let hasValidToken = tokenHelper.hasValidToken()

const currentURL = window.location.href
const url = new URL(currentURL)
const path = url.pathname

let requireAuth = true

router.options.routes.forEach(route => {
    if (path == route.path) {
        if (typeof route.meta !== "undefined" && typeof route.meta.accessConfig !== "undefined" && typeof route.meta.accessConfig.requiresAuth !== "undefined") {
            requireAuth = route.meta.accessConfig.requiresAuth
        }
    }
})


if (!hasValidToken && !isOidcCallback && requireAuth) {
    let API_BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''
    window.location.href = API_BASE_URL + '/api/oidc-login?redirect=' + window.location.pathname
} else {
    Vue.config.productionTip = false
    Vue.use(dateTimePlugin)
    Vue.use(helpersPlugin)
    Vue.use(downloadPlugin)
    Vue.use(PiniaVuePlugin)
    const pinia = createPinia()
    pinia.use(piniaPluginPersistedstate)

    const VueMaterial = require('vue-material')
    Vue.use(VueMaterial)

    Vue.use(TiptapVuetifyPlugin, {
        vuetify,
        iconsGroup: 'mdi'
    })

    Vue.component('file-tree-list-item', TreeComponent)

    Vue.material.registerTheme({
        default: {
            primary: 'blue',
            accent: {
                color: 'light-blue',
                hue: 700
            }
        }
    })

    let API_BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''
    let API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH ?? '/api'
    let API = API_BASE_URL + API_BASE_PATH

    Vue.prototype.$apiurl = API

    new Vue({
        el: '#app',
        router,
        vuetify: vuetify,
        i18n,
        template: '<App/>',
        components: {App},
        pinia,
    })
}