import { render, staticRenderFns } from "./BasicNewContainer.vue?vue&type=template&id=4b007a68"
import script from "./BasicNewContainer.vue?vue&type=script&lang=js"
export * from "./BasicNewContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports