<template>
    <v-dialog
        v-model="dialog"
        width="500"
        persistent
        content-class="documents-chooser custom-class-dialog"
    >
        <v-card>
            <v-card-title>
                {{ t('move-to') }}: <b v-if="moveToDirCandidate">{{ moveToDirCandidate.name }}</b>
            </v-card-title>
            <loading :dataLoaded="dataLoaded" class="mb-4"></loading>
            <v-card-text v-if="dataLoaded">
                <v-treeview
                    v-if="dialog"
                    activatable
                    hoverable
                    return-object
                    item-disabled="locked"
                    :disable-per-node="true"
                    :items="allFolders"
                    @update:active="getActiveFolder"
                >
                <template v-slot:prepend="{ item, open }">
                    <v-icon>
                        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                    </v-icon>
                </template>
                </v-treeview>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    class="icon-left"
                    @click="resetFolder()"
                >
                    <v-icon>mdi-reload</v-icon>
                    {{ t('reset') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    elevation="0"
                    color="primary"
                    class="icon-left"
                    :disabled="!moveToDirCandidate"
                    @click="useFolder()"
                >
                    <v-icon>mdi-check</v-icon>
                    {{ t('select') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from "@/event-bus"
import loading from '@/components/layouts/Loading.vue'

const iterate = (obj, id) => {
    Object.keys(obj).forEach(key => {
        if (key === 'folder_id' && obj[key] === id) {
            obj['locked'] = true
        }
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            iterate(obj[key], id)
        }
    })
}

export default {
    name: "MoveToFolderDialog",
    components: {
        loading
    },
    props: {
        dialog: false,
        rootName: '',
        folderId: 0,
        parentFolderId: 0,
        getUrl: null
    },
    data() {
        return {
            allFolders: [],
            moveToDirCandidate: null,
            dataLoaded: false
        }
    },
    created() {
        this.getFolderTree()
    },
    methods: {
        t: function (key) {
            return this.$t('move-to-folder.' + key)
        },
        getFolderTree() {
            if (this.getUrl) {
                HTTP.get(this.getUrl).then(function (response) {
                    this.allFolders = new Array({ 
                        folder_id: null,
                        parent_folder_id: null,
                        name: this.rootName,
                        children: response.data.children
                    })
                    if (this.folderId > 0) {
                        this.disabledTreeViewItem(this.folderId)
                    }
                    if (this.parentFolderId > 0) {
                        this.disabledTreeViewItem(this.parentFolderId)
                    }
                    this.dataLoaded = true
                }.bind(this)).catch(function (error) {
                    console.log(error)
                }.bind(this))
            }
        },
        useFolder() {
            EventBus.$emit('move-to-dir-select', this.moveToDirCandidate)
            this.moveToDirCandidate = null
        },
        resetFolder() {
            EventBus.$emit('move-to-dir-select', null)
            this.moveToDirCandidate = null
        },
        getActiveFolder(folder) {
            this.moveToDirCandidate = folder[0]
        },
        disabledTreeViewItem(id) {
            
            iterate(this.allFolders[0], id)

        }
    }
}
</script>