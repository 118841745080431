<template>
  <tr :class="getClass">
    <td v-for="(header, index) in headers" :key="index" :style="[{'width': header.width + 'px'}, { 'cursor': header.value == 'edit' ? 'default' : 'grab'}]" :class="[{ 'pr-0': header.value == 'drag' }]">
      <slot :item="item" :name="columnName(header)">

        <div v-if="header.value == 'drag'" :style="getAlignment(header)">
          <v-icon class="drag-handle">mdi-drag</v-icon>
        </div>
     
        <div v-if="header.value == 'name'" :style="getAlignment(header)">
          <span>{{ item.name }}</span>
        </div>


        <div v-if="header.value == 'edit'" :style="getAlignment(header)">
          <v-icon
            small
            class="edit-row-button"
            @click="openSettingsDialog(rowIndex, item)"
          >
            edit
          </v-icon>
        </div>

        <div v-if="header.value == 'remove'" :style="getAlignment(header)">
          <v-icon
            v-if="item.id !== 1"
            color="primary"
            small
            class="edit-row-button"
            @click="removeItem(rowIndex, item)"
          >
            delete
          </v-icon>

          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="disabled"
                v-bind="attrs"
                v-on="on"
              >
                delete
              </v-icon>
            </template>
            <span>{{ $t('news.categories.error-cannot-delete-default-category') }}</span>
          </v-tooltip>
        </div>

      </slot>
    </td>
  </tr>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: "DataTableNewsCategoriesRowHandler",
  props: {
    itemClass: {
      type: String,
      default: "",
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    headers: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  data() {
    return {}
  },
  computed: {
    getClass() {
      return this.itemClass
    }
  },
  methods: {
    columnName(header) {
      return `item.${header.value}`
    },
    getAlignment(header) {
      const align = header.align ? header.align : "center"
      return `text-align: ${align}`
    },
    openSettingsDialog(index, item) {
      EventBus.$emit('nc-open-settings-dialog', index, item)
    },
    removeItem(index) {
      EventBus.$emit('nc-remove-item', index)
    }
  }
}
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.4;
  cursor: default;
}
</style>
