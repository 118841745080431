<template>
  <div>
    <div>
      <v-btn
          color="primary"
          elevation="0"
          outlined
          class="icon-left"
          @click="openDialog('chooseFile-dialog')"
          :data-cy="dataCy"

      >
        <v-icon>mdi-file-plus</v-icon>
        {{ $t('components.link-documents.choose') }}
      </v-btn>
    </div>

    <div v-if="selectedDocuments.length > 0" class="linked-documents-list">
      <div v-for="(item, index) in selectedDocuments" class="list-compact">
        {{ item.title }}
        <v-icon @click="removeLinkedDocument(index)">mdi-delete</v-icon>
      </div>
    </div>

    <v-dialog
        v-model="dialog"
        width="500"
        persistent
        content-class="documents-chooser custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('components.link-documents.choose') }}
        </v-card-title>
        <v-card-text>

          <ul class="tree-list">
            <file-tree-selector
                :tree-data="fileTreeData"
                v-model="selectedDocumentIds"
            />
          </ul>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="resetChosenItems"
              data-cy="closeDocumentButton"
          >
            <v-icon>mdi-reload</v-icon>
            {{ $t('components.link-documents.reset') }}
          </v-btn>
          <v-btn
              color="primary"
              elevation="0"
              class="delete-task"
              @click="closeDialog"
              data-cy="finishDocumentButton"
          >
            <v-icon>mdi-check</v-icon>
            {{ $t('components.link-documents.done') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/auth";
import FileTreeSelector from "@/components/layouts/FileTreeSelector.vue";

export default {
  name: "NewsDocumentSelector",
  components: {FileTreeSelector},
  props: {
    dataCy: {
      default: ''
    },
    value: {},
    initialSelectedDocumentIds: {
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      dialog: false,
      selectedDocumentIds: [...this.initialSelectedDocumentIds],
      dataLoaded: false,
      fileTreeData: null,
    }
  },
  watch: {
    initialSelectedDocumentIds(val) {
      this.selectedDocumentIds = this.initialSelectedDocumentIds

    }
  },
  async created() {
    await this.getFolderTree()
  },
  computed: {
    selectedDocuments() {
      let files = this.allFiles
      return files.filter(z => this.selectedDocumentIds.includes(z.document_id))
    },
    allFiles() {
      if (!this.fileTreeData) {
        return []
      }
      return this.getFiles(this.fileTreeData)
    },
  },
  methods: {
    async getFolderTree() {
      let response = await HTTP.get('documents/folders/all?include=documents')
      this.fileTreeData = response.data
    },
    addLinkedDocument(item) {
      if (!this.selectedDocumentIds.includes(item)) {
        this.selectedDocumentIds.push(item)
      }
    },
    removeLinkedDocument(index) {
      this.selectedDocumentIds.splice(index, 1)
    },
    resetChosenItems() {
      this.selectedDocumentIds = this.initialSelectedDocumentIds
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.$emit('input', this.selectedDocumentIds);
      this.dialog = false
    },
    getFiles(node) {
      let files = []
      if (node.document_id) {
        files.push(node)
      }
      if (node.children) {
        node.children.forEach(z => {
          let childrenFiles = this.getFiles(z)
          childrenFiles.forEach(zz => {
            files.push(zz)
          })
        })
      }
      return files
    }
  }
}
</script>

<style scoped lang="scss">
.linked-documents-list {
  padding-top: 16px;

  > div {
    padding-right: 44px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .v-icon {
      position: absolute;
      right: 10px;
    }
  }
}
</style>
