<template>
  <div class="filter-apply-reset">
    <v-btn
      v-if="!isLoading && !isInitialState && !hasChanges"
      elevation="0"
      color="primary"
      class="icon-left"
      @click="$emit('reset')"
      data-cy="resetButton"
    >
      <v-icon>mdi-filter-remove-outline</v-icon>
      {{ $t('components.filter-apply.reset-label') }}
    </v-btn>

    <v-btn
      v-if="!isLoading && hasChanges"
      elevation="0"
      color="primary"
      class="icon-left"
      @click="$emit('click')"
      data-cy="applyButton"
    >
      <v-icon>mdi-check</v-icon>
      {{ $t('components.filter-apply.label') }}
    </v-btn>

    <v-btn
      v-if="canRefresh && !isLoading && !hasChanges"
      elevation="0"
      color="primary"
      class="icon-left"
      @click="$emit('click')"
      data-cy="refreshButton"
    >
      <v-icon>mdi-refresh</v-icon>
      {{ $t('components.filter-refresh.label') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AppFilterApplyButton",
  props: {
    hasChanges: {
      type: Boolean,
      default: false
    },
    isInitialState: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    canRefresh: {
      type: Boolean,
      default: false
    }
  }
}
</script>
