<template>
    <v-list>
      <v-list-group v-model="isExpanded" :prepend-icon="item.icon">
        <template v-slot:activator>
          <span class="module-title">{{ item.title }}</span>
        </template>

        <v-list-item to="/shop-orders" class="left-inset">
          <v-list-item-title>{{ $t('navigation.shop-orders') }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/shop-inventory" class="left-inset">
          <v-list-item-title>{{ $t('navigation.shop-inventory') }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/shop-products" class="left-inset">
          <v-list-item-title>{{ $t('navigation.shop-products') }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/settings/shop-categories" class="left-inset">
          <v-list-item-title>{{ $t('navigation.shop-categories') }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/settings/shop" class="left-inset">
          <v-list-item-title>{{ $t('navigation.settings-shop') }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </template>
  
  <script>
  export default {
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data() {
      return {
        isExpanded: true
      }
    },
    mounted() {
      this.isExpanded = this.$route.meta.accessConfig.moduleName && this.$route.meta.accessConfig.moduleName[0] === "shop"
    }
  }
  </script>