<template>
  <div class="dashboard-card">

    <v-card v-if="hasPermission" elevation="0">
      <v-card-title>
        <v-icon color="primary">mdi-account</v-icon>
        {{ $t('dashboard.user-statistics.title') }}
      </v-card-title>

      <v-card-text>
        <div v-if="userStatistics && !isLoading" class="sp-flex sp-flex-dir-col analytics-container">
          <div class="text-center">
            <v-progress-circular
              rotate="360"
              size="200"
              width="25"
              :value="Number(registeredPercentage).toFixed(0)"
              color="primary"
              class="mb-8"
            >
              {{ Number(registeredPercentage).toFixed(1) }}%
            </v-progress-circular>
          </div>

          <div>
            <b>{{ $t('dashboard.user-statistics.active-users') }}</b><span> {{
              Number(registeredPercentage).toFixed(1)
            }}% ({{ registeredUsersTotal }} {{ $t('dashboard.user-statistics.from') }} {{ allUsersTotal }})</span>
            <br>
          </div>

          <div v-if="notRegisteredUsers.length > 0">
            <div><b>{{ $t('dashboard.user-statistics.inactive-users') }}</b>
              <v-icon @click="exportInactiveUsers">download</v-icon>
            </div>
            <div class="list-content">
              <v-list-item 
                v-for="entry in notRegisteredUsers" 
                :key="entry.id" 
                class="analytics-item"
              >
                <v-list-item-avatar>
                  <v-lazy v-if="entry.profile_image !== ''" class="user-profile-image">
                    <img
                      v-if="!failedImages.includes(entry.id)"
                      :src="baseUrl + '/api/users/image/' + entry.id + '/' + entry.profile_image"
                      @error="imageLoadError(entry.id)" 
                    />
                    <span v-else class="broken-image"><v-icon>mdi-image-off</v-icon></span>
                  </v-lazy>
                  <span v-else class="user-profile-image user-placeholder"><v-icon>mdi-account-circle</v-icon></span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ entry.name }}, {{ entry.firstname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ entry.updated_at}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </div>

        <div v-else class="analytics-loading-wrapper">
          <span v-if="isLoading" class="analytics-loading"></span>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import store from 'store'
import { HTTP } from '@/auth'
import * as XLSX from 'xlsx'

export default {
  name: 'DashboardUsers',
  data() {
    return {
      user: null,
      isLoading: true,
      userStatistics: null,
      failedImages: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL ?? '',
    }
  },
  async mounted() {
    this.user = store.get('user')
    if (['admin'].includes(this.user.role)) {
      await this.loadData()
    }
  },
  methods: {
    async loadData() {
      let response = await HTTP.get('user_statistics')
      this.userStatistics = response.data.users
      this.isLoading = false
      this.$emit('finished')
    },
    exportInactiveUsers() {
      let data = this.notRegisteredUsers.map(z => {
        return {
          Nachname: z.name,
          Vorname: z.firstname,
          EMail: z.email
        };
      })

      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
      XLSX.writeFile(wb, 'InaktiveBenutzer.xlsx')
    },
    imageLoadError(id) {
      this.failedImages.push(id)
    }
  },
  computed: {
    hasPermission() {
      if (!this.user) {
        return false
      }
      return ['admin'].includes(this.user.role)
    },
    registeredUsersTotal() {
      return this.registeredUsers.length
    },
    registeredUsers() {
      return this.userStatistics.filter(entry => entry.recently_active === 1)
    },
    notRegisteredUsers() {
      return this.userStatistics.filter(entry => entry.recently_active === 0)

    },
    allUsersTotal() {
      return this.userStatistics.length
    },
    registeredPercentage() {
      return this.registeredUsersTotal / this.allUsersTotal * 100
    },
  }
}
</script>

<style scoped lang="scss">
.list-content {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.user-profile-image {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);

  &.user-placeholder {
    border-color: rgba(0, 0, 0, 0);
  }
}

.user-placeholder i {
  position: absolute;
  top: -1px;
  left: -1px;
  font-size: 48px;
  color: rgba(0, 0, 0, 0.3);
}

.broken-image i {
  color: rgba(0, 0, 0, 0.3);
  padding: 7px;
}
</style>
