<template>
  <div>
    <v-radio-group data-cy="status" v-model="publishMode" @change="stateChanged">
      <v-radio
        key="draft"
        :label="$t('components.post-publish-settings.status.draft')"
        value="draft"
        data-cy="draft"
      ></v-radio>

      <v-radio
        v-if="['draft', 'publishat'].includes(initialStatus)"
        key="publishat"
        :label="$t('components.post-publish-settings.status.publish-at')"
        value="publishat"
        data-cy="publish-at-radio"
      ></v-radio>

      <div class="pl-8" :class="[{ 'pb-4': publishMode === 'publishat' }]">
        <app-date-time-picker
          v-if="publishMode === 'publishat'"
          v-model="publishDate"
          date-format="dd.MM.yyyy"
          time-format="HH:mm"
          :id="'publishedDate'"
          :clearable="true"
          :label="$t('components.post-publish-settings.publish-ondate')"
          data-cy="publish-at"
          :rules="[() => (publishMode === 'publishat' && !!publishDate) || $t('validation.required.default')]"
        ></app-date-time-picker>
      </div>  

      <span v-if="publishMode === 'publishat' && !publishAt" class="publish-error mb-2">
        {{ $t('validation.required.publish-date') }}
      </span>

      <span v-if="!validPublishDate" class="publish-error mb-4">
        {{ $t('validation.valid.publish-date') }}
      </span>

      <v-radio
        key="published"
        :label="$t('components.post-publish-settings.status.published')"
        value="published"
        data-cy="published"
      ></v-radio>

      <v-radio
        v-if="showArchiveOption"
        key="archived"
        :label="$t('components.post-publish-settings.status.archived')"
        value="archived"
        data-cy="archived"
      ></v-radio>
    </v-radio-group>

    <div v-if="canAutoArchive">
      <v-checkbox
        :label="$t('news.detail.automatic-archive') "
        data-cy="auto_archive"
        v-model="autoArchiveEnabled"
        hide-details
        :class="[{ 'mb-2': autoArchiveEnabled }]"
      ></v-checkbox>

      <div class="pl-8">
        <app-date-time-picker
          v-if="autoArchiveEnabled"
          v-model="autoArchiveDate"
          date-format="dd.MM.yyyy"
          time-format="HH:mm"
          :id="'autoArchiveDate'"
          :clearable="true"
          :label="$t('news.detail.automatic-archive-at') "
          data-cy="archive_at"
        ></app-date-time-picker>
      </div>

      <span v-if="!validArchiveDate" class="publish-error">
        {{ $t('validation.valid.archive-date') }} {{ formatDateTime(publishDate) }}.
      </span>
    </div>
  </div>
</template>

<script>
import AppDateTimePicker from '@/components/vuetify/AppDateTimePicker'
import moment from 'moment'

export default {
  name: "NewsPublish",
  components: { AppDateTimePicker },
  emits: [
    'status',
    'publish-at',
    'archive-at',
    'valid',
  ],
  props: {
    initialStatus: {},
    publishAt: {},
    autoArchiveAt: null,
    autoArchiveDate: null,
    showArchiveOption: {
      default: true
    }
  },
  data() {
    return {
      publishMode: this.initialStatus,
      publishDate: this.publishAt,
      autoArchiveEnabled: this.autoArchiveDate != null,
      invalidPublishDate: false,
    }
  },
  computed: {
    validPublishDate() {
      return this.publishMode !== 'publishat'
          || moment(this.publishDate) > moment()
    },
    canAutoArchive() {
      return this.publishMode === 'publishat'
          || this.publishMode === 'published'
    },
    validArchiveDate() {
      return !this.autoArchiveEnabled
          || this.publishMode !== 'publishat'
          || moment(this.publishDate) < moment(this.autoArchiveDate)
    }
  },
  watch: {
    publishMode(val) {
      this.validate()
      this.$emit('status', val)
    },
    publishDate(val) {
      this.validate()
      this.$emit('publish-at', val)
    },
    autoArchiveEnabled(val) {
      this.validate()
      if (!val) {
        this.autoArchiveDate = null
      }
    },
    autoArchiveDate(val) {
      this.validate()
      this.$emit('archive-at', val)
    },
    initialStatus(val) {
      this.publishMode = val;
    },
    autoArchiveAt(val) {
      this.autoArchiveDate = val;
    }
  },
  methods: {
    validate() {
      this.$emit('valid',  this.validPublishDate && this.validArchiveDate)
    },
    stateChanged() {
      if (this.publishMode == 'published') {
        this.publishDate = moment()
        this.publishDate()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.publish-error {
  font-size: 14px;
  line-height: 1.2;
  padding-left: 40px;
  margin-top: -8px;
  color: var(--v-error-base);
}
</style>
