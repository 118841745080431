<template>
    <div class="has-hero-container">
        <div>
            <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
    
            <div class="sp-hero sp-bg-light-blue">
        
                <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">
                
                    <app-filter-apply-button
                        :has-changes="filterStore.hasChanges"
                        :is-initial-state="filterStore.isInitialState"
                        :is-loading="loading"
                        @click="applyFilter();"
                        @reset="filterStore.$reset(); applyFilter();"
                    ></app-filter-apply-button>
                
                    <v-slide-group show-arrows>
                        <v-slide-item>          
                            <div class="filter-item">
                                <div class="filter-item-wrapper">
                                    <app-filter-date-picker
                                        :filterLabel="t('from')"
                                        v-model="filterStore.filter.dateStart"
                                        id="dateStart"
                                        clearable
                                    ></app-filter-date-picker>
                                </div>
                            </div>
                        </v-slide-item>

                        <v-slide-item>          
                            <div class="filter-item">
                                <div class="filter-item-wrapper">
                                    <app-filter-date-picker
                                        :filterLabel="t('to')"
                                        v-model="filterStore.filter.dateEnd"
                                        id="dateEnd"
                                        clearable
                                    ></app-filter-date-picker>
                                </div>
                            </div>
                        </v-slide-item>

                        <v-slide-item>          
                            <div class="filter-search-list">
                                <div class="filter-item">
                                    <div class="filter-item-wrapper">
                                    <app-filter-search-field
                                        v-model="filterStore.filter.searchTerm"
                                        :filterLabel="t('general-search')"
                                        clearable
                                        append-icon="mdi-magnify"									
                                    ></app-filter-search-field>
                                    </div>
                                </div>
                            </div>
                        </v-slide-item>	                   
                    </v-slide-group>
    
                </v-container>
                
            </div>
            
            <v-container fluid>
    
                <v-row class="data-table-toolbar"></v-row>
    
                <v-row v-if="dataLoaded && results.data.length === 0">
                    <div class="empty-datatable">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        {{ t('no-data') }}
                        <loading v-if="loading"></loading>
                    </div>
                </v-row>
    
                <v-row v-else>
    
                    <v-data-table
                        :headers="headers"
                        :items="results.data"
                        :options.sync="options"
                        :server-items-length="dataTotal"
                        :loading="loading"
                        class="elevation-0"
                        :page.sync="filterStore.table.currentPage"
                        :items-per-page.sync="filterStore.table.itemsPerPage"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-options': [25, 50, 100, 200],
                            pageText: '{0}-{1} von {2}',
                            'items-per-page-text': t('table.items-per-page')
                        }"
                    >
                        <template v-slot:[`item.customer`]="{ item }">
                            {{ item.object.name }}
                        </template>

                        <template v-slot:[`item.date`]="{ item }">
                            {{ formatDate(item.control_date) }}
                        </template>

                        <template v-slot:[`item.contact`]="{ item }">
                            {{ item.contact }}
                        </template>

                        <template v-slot:[`item.inspector`]="{ item }">
                            {{ item.inspector.display_name }}
                        </template>

                        <template v-slot:[`item.category`]="{ item }">
                            {{ item.category.title }}
                        </template>

                        <template v-slot:[`item.view`]="{ item }">
                            <router-link :to="{ name: 'ObjectControlResultsView', params: { id: item.id } }" class="edit-row-table">
                            <v-icon
                                small
                                class="edit-row-button"
                            >
                                mdi-eye-outline
                            </v-icon>
                            </router-link>
                        </template>
                    </v-data-table>
    
                </v-row>
            </v-container>
    
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import { useObjectControlFilterStore } from '@/stores/ObjectControlFilterStore'

export default {
    name: 'ObjectControlResults',
    components: {
        toolbar,
        AppFilterApplyButton,
        AppFilterDatePicker,
        AppFilterSearchField
    },
    setup() {
        const filterStore = useObjectControlFilterStore()
        return { filterStore }
    },
    data () {
        return {
            loading: false,
            results: {
                page: 1,
                current_page: 1,
                last_page: 1,
                total: null,
                data: []
            },
            dataLoaded: false,
            error: false,
            dataTotal: 0,
            options: {}
        }
    },
    computed: {
        headers() {
            return [
            {
                text: this.t('table.headers.customer'),
                align: 'start',
                sortable: false,
                value: 'customer',
                cellClass: 'text-middle'
            },
            {
                text: this.t('table.headers.date'),
                align: 'start',
                sortable: false,
                value: 'date',
                cellClass: 'text-middle'
            },
            {
                text: this.t('table.headers.contact'),
                align: 'start',
                sortable: false,
                value: 'contact',
                cellClass: 'text-middle'
            },
            {
                text: this.t('table.headers.inspector'),
                align: 'start',
                sortable: false,
                value: 'inspector',
                cellClass: 'text-middle'
            },
            {
                text: this.t('table.headers.category'),
                align: 'start',
                sortable: false,
                value: 'category',
                cellClass: 'text-middle'
            },
            {
                text: this.t('table.headers.view'),
                value: 'view',
                sortable: false,
                align: 'center',
                width: 150,
            }
            ]
        }
    },
    watch: {
        options: {
            handler() {
                this.getResults()
            },
            deep: true,
        }
    },
    methods: {
        t: function (key) {
            return this.$t('object-control.results.list.' + key);
        },
        reload() {
            this.$nextTick(() => {
                this.results.data = []
                this.getResults()
            })
        },
        applyFilter() {
            this.filterStore.filterApplied()
            this.reload()
        },
        getResults() {
            if (!this.loading) {
                this.dataLoaded = false
                this.loading = true

                let url = ''
                let queryStringParts = []
                if (this.filterStore.filter.searchTerm) {
                    queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
                }
                if (this.filterStore.filter.dateStart) {
                    queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
                }
                if (this.filterStore.filter.dateEnd) {
                    queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
                }
                url = '?' + queryStringParts.join('&')

                HTTP.get('object_control' + url, { params: this.options }).then(function (response) {
                    this.results.data = response.data
                    this.results.current_page = response.data.current_page
                    this.results.last_page = response.data.last_page
                    this.results.data = response.data.data
                    this.results.total = response.data.total
                    this.dataTotal = response.data.total
                    this.error = false
                    this.dataLoaded = true
                    this.loading = false
                }.bind(this)).catch(function (error) {
                    if (!error.status) {
                    this.error = true
                    this.loading = false
                    }
                }.bind(this))
            }
        }
    }
}
</script>