<template>
    <v-list>
      <v-list-group v-model="isExpanded" :prepend-icon="item.icon">
        <template v-slot:activator>
          <span class="module-title">{{ item.title }}</span>
        </template>

        <v-list-item to="/surveys" :exact="true" class="left-inset">
          <v-list-item-title>{{ $t('navigation.survey-admin') }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/surveys/results" :exact="true" class="left-inset">
          <v-list-item-title>{{ $t('navigation.survey-analysis') }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </template>
  
  <script>
  export default {
    name: "SurveyListItem",
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data() {
      return {
        isExpanded: true
      }
    },
    mounted() {
      this.isExpanded = this.$route.meta.accessConfig.moduleName && this.$route.meta.accessConfig.moduleName[0] === "surveys"
    }
  }
  </script>
  