import { render, staticRenderFns } from "./LearningPortalTrainingsIndex.vue?vue&type=template&id=4aefaf71&scoped=true"
import script from "./LearningPortalTrainingsIndex.vue?vue&type=script&lang=js"
export * from "./LearningPortalTrainingsIndex.vue?vue&type=script&lang=js"
import style0 from "./LearningPortalTrainingsIndex.vue?vue&type=style&index=0&id=4aefaf71&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aefaf71",
  null
  
)

export default component.exports