import ServapptAppointmentsIndex from '@/views/ServapptAppointmentsIndex'
import ServapptAppointmentsCreate from '@/views/ServapptAppointmentsCreate'
import ServapptAppointmentsEdit from '@/views/ServapptAppointmentsEdit'
import ServapptAreasIndex from '@/views/ServapptAreasIndex'
import ServapptAreasCreate from '@/views/ServapptAreasCreate'
import ServapptAreasEdit from '@/views/ServapptAreasEdit'
import ServapptRequestsIndex from '@/views/ServapptRequestsIndex'
import ServapptRequestsCreate from '@/views/ServapptRequestsCreate'
import ServapptRequestsEdit from '@/views/ServapptRequestsEdit'
import ServapptResponsesIndex from '@/views/ServapptResponsesIndex'
import ServapptAreasEmailSettings from '@/views/ServapptAreasEmailSettings'

const servapptRoutes = [
    {
        path: '/servappt/appointments',
        name: 'ServapptAppointmentsIndex',
        component: ServapptAppointmentsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/appointments/create/:id?',
        name: 'ServapptAppointmentsCreate',
        component: ServapptAppointmentsCreate,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/appointments/:id?',
        name: 'ServapptAppointmentsEdit',
        component: ServapptAppointmentsEdit,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/requests',
        name: 'ServapptRequestsIndex',
        component: ServapptRequestsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/requests/create',
        name: 'ServapptRequestsCreate',
        component: ServapptRequestsCreate,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/requests/:id?',
        name: 'ServapptRequestsEdit',
        component: ServapptRequestsEdit,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/responses',
        name: 'ServapptResponsesIndex',
        component: ServapptResponsesIndex,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/servappt-areas',
        name: 'ServapptAreasIndex',
        component: ServapptAreasIndex,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/email-settings',
        name: 'ServapptAreasEmailSettings',
        component: ServapptAreasEmailSettings,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/servappt-areas/create',
        name: 'ServapptAreasCreate',
        component: ServapptAreasCreate,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/servappt/servappt-areas/:id?',
        name: 'ServapptAreasEdit',
        component: ServapptAreasEdit,
        meta: {
            accessConfig:  {
                moduleName: ['servappt'],
                requiresAuth: true
            }
        }
    }
]

export default servapptRoutes
