<template>
    <div>
        <v-select
            filled
            :title="$t('components.category-select.select') + '*'"
            :label="$t('components.category-select.select') + '*'"
            v-model="selectedCategories"
            :items="categories"
            item-text="name"
            item-value="id"
            :disabled="readOnly"
            :rules="[
                () => !!selectedCategories|| $t('validation.required.category')
            ]"
            :data-cy="dataCy"
        ></v-select>
    </div>
</template>

<script>
import { HTTP } from '@/auth'

export default {
    name: 'NewsCategorySelector',
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        model: {
            type: Number,
            default: () => [null]
        },
        dataCy: {
            default: ''
        },
        value: {},
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            categories: [],
            selectedCategories: this.model
        }
    },
    watch: {
        selectedCategories: function (val) {
            this.$emit('input', val)
        },
        model: function(val) {
            if (val !== null) {
                this.selectedCategories = val
            }
        }
    },
    async created() {
        await this.getCategories()
    },
    methods: {
        async getCategories() {
            let response = await HTTP.get('news/categories')
            this.categories = response.data
        }
    }
}
</script>
