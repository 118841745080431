import ChecklistTasksIndex from '@/views/ChecklistTasksIndex'
import ChecklistSubtasksIndex from '@/views/ChecklistSubtasksIndex'
import ChecklistCreateEditTask from '@/views/ChecklistCreateEditTask'
import ChecklistCreateEditSubtask from '@/views/ChecklistCreateEditSubtask'
import ReportsIndex from '@/views/ReportsIndex'
import ReportsShow from '@/views/ReportsShow'
import ChecklistsIndex from '@/views/ChecklistsIndex'
import ChecklistsCreateEditChecklist from '@/views/ChecklistsCreateEditChecklist'
import StatisticsIndex from '@/views/StatisticsIndex'
import SettingsChecklistTaskTags from '@/views/SettingsChecklistTaskTags'

const qualityReportRoutes = [
    {
        path: '/checklists/create',
        name: 'ChecklistCreateChecklist',
        component: ChecklistsCreateEditChecklist,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklists/:id',
        name: 'ChecklistEditChecklist',
        component: ChecklistsCreateEditChecklist,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklists/',
        name: 'Checklists',
        component: ChecklistsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklist-tasks/create',
        name: 'ChecklistCreateTask',
        component: ChecklistCreateEditTask,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklist-tasks/:id',
        name: 'ChecklistEditTask',
        component: ChecklistCreateEditTask,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklist-tasks',
        name: 'ChecklistTasksIndex',
        component: ChecklistTasksIndex,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklist-subtasks/create',
        name: 'ChecklistCreateSubtask',
        component: ChecklistCreateEditSubtask,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklist-subtasks/:id',
        name: 'ChecklistEditSubtask',
        component: ChecklistCreateEditSubtask,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/checklist-subtasks',
        name: 'ChecklistElements',
        component: ChecklistSubtasksIndex,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/quality-reports/:id',
        name: 'ReportsShow',
        component: ReportsShow,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/quality-reports',
        name: 'Reports',
        component: ReportsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: StatisticsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/checklists/tags',
        name: 'SettingsChecklistTaskTags',
        component: SettingsChecklistTaskTags,
        meta: {
            accessConfig:  {
                moduleName: ['quality_report'],
                requiresAuth: true
            }
        }
    }
]

export default qualityReportRoutes
