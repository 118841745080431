<template>
    <v-list>
      <v-list-group v-model="isExpanded" :prepend-icon="item.icon">
        <template v-slot:activator>
          <span class="module-title">{{ item.title }}</span>
        </template>
  
        <v-list-item to="/time-management/balances" class="left-inset">
          <v-list-item-title>{{ $t('navigation.time-management.balances') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item to="/time-management/sites" class="left-inset">
          <v-list-item-title>{{ $t('navigation.time-management.sites') }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </template>
  
  <script>
  export default {
    name: "TimelogListItem",
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data() {
      return {
        isExpanded: false
      }
    },
    mounted() {
      this.isExpanded = this.$route.meta.accessConfig.moduleName && this.$route.meta.accessConfig.moduleName[0] === "timelog_targethours"
    }
  }
  </script>
  