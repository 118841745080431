import MenuIndex from '@/views/MenuIndex'
import MenuCreateEdit from '@/views/MenuCreateEdit'

const menuRoutes = [
  {
    path: '/settings/menu',
    name: 'MenuIndex',
    component: MenuIndex,
    meta: {
      accessConfig:  {
        moduleName: null,
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/menu/create/:type',
    name: 'MenuCreate',
    component: MenuCreateEdit,
    meta: {
      accessConfig:  {
        moduleName: null,
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/menu/:id?',
    name: 'MenuEdit',
    component: MenuCreateEdit,
    meta: {
      accessConfig:  {
        moduleName: null,
        requiresAuth: true
      }
    } 
  }
]

export default menuRoutes
