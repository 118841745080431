<template>
  <div class="has-hero-container">
    <toolbar :dataLoaded="true">{{ t("title") }}</toolbar>
    <div class="sp-hero sp-bg-light-blue">
      <v-container fluid class="hero-filter">
        <v-slide-group
          show-arrows
        >
          <v-slide-item>
            <div>
              <div class="filter-item">
                <date-picker
                  v-model="filter.dateMonth"
                  type="month"
                  format="MMMM YYYY"
                  value-type="date"
                ></date-picker>
              </div>
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-container>
    </div>

    <v-container fluid>
      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="primary"
          class="icon-right"
          :loading="isProcessing"
          :disabled="isProcessing"
          @click="generate()"
        >
          {{ t("report-export") }}
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import toolbar from "../components/layouts/Navigation.vue"
import AppFilterDatePicker from "../components/vuetify/AppFilterDatePicker"
import show from "../views/ReportsShow.vue"
import loading from "../components/layouts/Loading.vue"
import DatePicker from "vue2-datepicker"
import { HTTP } from "@/auth"
import moment from "moment"

export default {
  name: "TimesheetMonthIndex",
  components: {
    toolbar,
    AppFilterDatePicker,
    DatePicker,
    show,
    loading
  },
  watch: {},
  data() {
    return {
      filter: {
        dateMonth: moment().subtract(1, "month").startOf("month").toDate()
      },
      isProcessing: false
    }
  },
  methods: {
    t: function(key) {
      return this.$t("timelog-salaryreport." + key)
    },
    buildFilter() {
      let filter = {}
      filter.date = moment(this.filter.dateMonth).format("YYYY-MM-DD")
      return filter
    },
    async generate() {
      this.isProcessing = true
      try {
        let filter = this.buildFilter()
        let response = await HTTP.post("timelog/reporting/month-salary-report", filter, { responseType: "arraybuffer" })
        let filename = "Lohnmonatsrapport-Monat_" + moment(this.filter.dateMonth).month() + "-%TIMESTAMP%.xlsx"
        this.startDataDownload(response.data, response.headers["content-type"], filename)
      } finally {
        this.isProcessing = false
      }
    }
  }
}
</script>

<style scoped>

.user-selected .md-icon {
  margin: 4px !important;
  color: #FFF !important;
}

.user-selected .md-icon.delete {
  margin-left: auto !important;
}

.user-selected .md-icon.delete:hover {
  cursor: pointer;
}

</style>

