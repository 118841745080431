<template>
  <div>
    <md-toolbar class="sp-main-toolbar">

      <!-- Hamburger button -->
      <md-button v-if="showMenu" class="md-icon-button" @click.native="menuClicked">
        <md-icon>menu</md-icon>
      </md-button>

      <!-- Back button -->
      <span v-if="back && dataLoaded">
        <span v-if="path">
          <router-link tag="button" class="md-button md-icon-button" :to="{ path: path }">
            <md-icon>arrow_back</md-icon>
          </router-link>
        </span>
        <span v-if="event"> 
          <md-button class="md-icon-button" @click.native="backClicked">
            <md-icon>arrow_back</md-icon>
          </md-button>
        </span>
      </span>

      <span v-if="cancelLeft && dataLoaded">
        <span v-if="path">
         <router-link tag="button" class="md-button md-icon-button" :to="{ path: path }"><md-icon>close</md-icon></router-link>
        </span>
        <span v-if="event">
           <md-button class="md-icon-button" @click.native="cancelLeftClicked()"><md-icon>close</md-icon></md-button>
        </span>
      </span>

      <!-- CENTER -->
      <h2 v-if="dataLoaded" class="md-title" style="flex: 1">
        <slot></slot>
      </h2>

      <md-button v-if="cancel && dataLoaded" class="md-icon-button" @click.native="cancelClicked">
        <md-icon>close</md-icon>
      </md-button>

      <!-- edit button -->
      <md-button v-if="search" class="md-icon-button" @click.native="searchClicked">
        <md-icon>search</md-icon>
      </md-button>

      <!-- ok button -->
      <md-button v-if="ok" class="md-icon-button" @click.native="okClicked">
        <md-icon>check</md-icon>
      </md-button>

      <v-spacer></v-spacer>

      <!--Setting-->
      <span 
        v-if="showSetting"
        @click="goToSetting"
      >
        <v-icon
          class="cog"
          size="30px"
          color="secondary"
          :title="$t('navigation.settings')"
        >
          mdi-cog-outline
        </v-icon>
      </span>

      <v-list-item-content class="user-profile-name">
        <v-list-item-title v-if="user">
          {{ user.name }}, {{ user.firstname }}
        </v-list-item-title>
        
        <v-list-item-subtitle v-if="user">
          {{ $t('users.data.roles.' + user.role) }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <!-- Profile Icon with Image -->
      <v-menu :close-on-content-click="false" offset-y nudge-top="-8">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar v-bind="attrs" v-on="on" class="background-image mr-1">
            <span v-if="user && user.profile_image" class="user-profile-image">
              <img
                v-if="!failedImages.includes(user.id)"
                :src="baseUrl + '/api/users/image/' + user.id + '/' + user.profile_image"
                @error="imageLoadError(user.id)"
              />
              <span v-else class="broken-image">
                <v-icon
                  class="profile-icon"
                  size="30px"
                  color="primary"
                >
                  mdi-account
                </v-icon>
              </span>
            </span>
            <span v-else class="user-profile-image user-placeholder">
              <v-icon
                class="profile-icon"
                size="30px"
                color="primary"
              >
                mdi-account
              </v-icon>
            </span>
          </v-list-item-avatar>
        </template>

        <v-list>
          <v-list-item-group>
            <v-list-item @click.native="profile">
              <v-icon class="pr-4">mdi-account</v-icon>
              <span class="pr-3">{{ $t('navigation.profile') }}</span>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click.native="logout">
              <v-icon class="pr-4">mdi-logout</v-icon>
              <span class="pr-3">{{ $t('navigation.logout') }}</span>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

    </md-toolbar>

    <!-- Sidenavigation -->
    <side-navigation ref="sidenav"></side-navigation>

  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { hasPermission } from '@/services/PermissionService'
import store from 'store'
import router from '@/router'
import auth from '@/auth/'
import SideNavigation from '@/components/menu/SideNavigation'

export default {
  name: 'NavigationToolbar',
  components: {
    SideNavigation
  },
  props: {
    dataLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    menu: {
      type: Boolean,
      required: false,
      default: true
    },
    back: {
      type: Boolean,
      required: false,
      default: false
    },
    path: {
      type: String,
      required: false,
      default: ''
    },
    event: {
      required: false,
      default: false
    },
    cancel: {
      required: false,
      default: false
    },
    cancelLeft: {
      required: false,
      default: false
    },
    ok: {
      type: Boolean,
      required: false,
      default: false
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      user: null,
      failedImages: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL ?? '',
      currentModuleName: null,
      activeModules: null,
      currentModule: null
    }
  },
  mounted() {
    this.user = store.get('user')
    this.currentModuleName = this.$route.meta.accessConfig.moduleName
    if (this.currentModuleName) {
      this.activeModules = store.get('userModules')
      this.currentModule = this.activeModules.filter((module) => module.module_name === this.currentModuleName[0])[0]
    }
  },
  computed: {
    showMenu() {
      return (!this.back && !this.cancel && !this.cancelLeft)
    },
    showSetting() { 
      return !!this.currentModule && !!this.currentModule.settings && this.hasSettingsPermission()
    }
  },
  methods: {
    logout() {
      auth.logout()
    },
    profile() {
      this.$router.push({ name: 'ProfileIndex' })
    },
    goToSetting() {
      if (this.$route.path === this.currentModule.settings) {
        return
      }
      this.$router.push({ path: this.currentModule.settings })
    },
    menuClicked() {
      if (this.$refs.sidenav) {
        this.$refs.sidenav.toggle()
      }
    },
    cancelClicked(event) {
      if (event) {
        EventBus.$emit('cancel')
      } else {
        router.push(this.cancel)
      }
    },
    cancelLeftClicked() {
      EventBus.$emit('cancel')
    },
    okClicked() {
      EventBus.$emit('ok')
    },
    editClicked() {
      EventBus.$emit('edit')
    },
    backClicked() {
      EventBus.$emit('back', this.event)
    },
    searchClicked() {
      EventBus.$emit('search')
    },
    imageLoadError(id) {
      this.failedImages.push(id)
    },
    hasSettingsPermission() {
      let settingsPermission = this.$route.meta.accessConfig.requiredSettingsPermission
      return !settingsPermission || hasPermission(settingsPermission)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-profile-image {
  display: inline-block;
  border-radius: 100%;
}

.profile-icon:hover{
  transform: scale(1.1);
}

.background-image{
  background-color: var(--v-secondary-base);
}

.user-profile-name.v-list-item__content{
  flex: none;
  display: block;

  .v-list-item__title{
    font-weight: bold;
  }
}

.cog {
  margin-right: 50px;
  display: inline-block;
  cursor: pointer;
}

.cog:hover {
  
  &::before {
    transform: rotate(60deg);
    transition: transform 0.3s;
  }
}
</style>
