<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t("title") }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            :can-refresh="true"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('filter.from')"
                  v-model="filterStore.filter.dateStart"
                  id="dateStart"
                  :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('filter.to')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-search-list">
                <user-filter-widget
                  v-model="filterStore.filter.selectedUser"
                  :preselected="filterStore.filter.selectedUser"
                  :include-inactive="true"
                ></user-filter-widget>
              </div>
            </v-slide-item>

            <v-slide-item>
              <timesheet-approval-status-filter
                v-model="filterStore.filter.status"
              ></timesheet-approval-status-filter>
            </v-slide-item>
          </v-slide-group>
          
        </v-container>
      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            outlined
            class="icon-left"
            v-show="theme === 'har'"
            @click="startCsvExport()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t("excel-export") }}
          </v-btn>
        </v-row>

        <v-row v-if="dataLoaded && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t("no-data") }}
          </div>
        </v-row>

        <v-row v-else>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            class="elevation-0"
            :sort-by="initialSortBy"
            :sort-desc="initialSortDesc"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [10, 25, 50],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.week`]="{ item }">
              {{ t("table.week-short") }} {{ formatDateWeek(item.date) }}
            </template>

            <template v-slot:[`item.firstname`]="{ item }">
              {{ item.user.firstname }}
            </template>

            <template v-slot:[`item.lastname`]="{ item }">
              {{ item.user.lastname }}
            </template>

            <template v-slot:[`item.number`]="{ item }">
              {{ item.user.number }}
            </template>

            <template v-slot:[`item.staff_number`]="{ item }">
              {{ item.user.staff_number }}
            </template>

            <template v-slot:[`item.projects`]="{ item }">
              <ul>
                <li v-for="p in item.projects">
                  <span>{{ p.name }}</span>
                </li>
              </ul>
            </template>

            <template v-slot:[`item.externally_logged_seconds`]="{ item }">
              <span v-if="item.externally_logged_seconds">{{
                  formatSecondsAsHours(item.externally_logged_seconds)
                }}</span>
            </template>

            <template v-slot:[`item.absence_hours`]="{ item }">
              {{ item.absence_hours.toFixed(2) }}
            </template>

            <template v-slot:[`item.presence_hours`]="{ item }">
              {{ item.presence_hours.toFixed(2) }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <StatusIcons :status="item.status" />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                elevation="0"
                color="success"
                outlined
                class="icon-left"
                v-if="false"
              >
                {{ t("table.approval") }}
              </v-btn>
              <v-btn
                elevation="0"
                color="success"
                outlined
                class="icon-left"
                v-if="item.can_send_resubmission_request"
                @click="requestResubmission(item)"
              >
                {{ t("table.request-resubmission") }}
              </v-btn>
            </template>

            <template v-slot:[`item.details`]="{ item }">
              <router-link :to="{ path: '/timesheet-approval/'+item.id }" v-if="item.id>0">
                <v-icon small class="edit-row-button">edit</v-icon>
              </router-link>
            </template>

          </v-data-table>
        </v-row>
      </v-container>
    </div>

    <v-dialog
      v-model="dialogs.showResubmission"
      content-class="delete-dialog"
      width="500"
    >
      <timesheet-approval-request-resubmission
        :employee-name="dialogs.requestResubmission.employeeName"
        :submission-date="dialogs.requestResubmission.submissionDate"
        @close="dialogs.showResubmission=false"
        @confirm="doSendRequestResubmission"
      ></timesheet-approval-request-resubmission>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import store from 'store'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import ExpenseStatusFilter from '@/components/filter-components/ExpenseStatusFilter'
import TimesheetApprovalStatusFilter from '@/components/filter-components/TimesheetApprovalStatusFilter'
import StatusIcons from '@/components/layouts/StatusIcons'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import TimesheetApprovalRequestResubmission from '@/views/TimesheetApprovalRequestResubmission'
import { useTimesheetApprovalFilterStore } from '@/stores/TimesheetApprovalFilterStore'
import { mapDataTableConfig } from "@/infrastructure/dataTableHelper"

export default {
  name: "TimesheetApprovalIndex",
  components: {
    TimesheetApprovalRequestResubmission,
    AppFilterApplyButton,
    StatusIcons,
    TimesheetApprovalStatusFilter,
    ExpenseStatusFilter,
    UserFilterWidget,
    toolbar,
    AppFilterDatePicker,
    AppFilterSearchField
  },
  setup() {
    const filterStore = useTimesheetApprovalFilterStore()
    return { filterStore }
  },
  data() {
    return {
      config: {
        externally_logged_hours_visible: false
      },
      items: [],
      loading: false,
      options: {},
      initialSortBy: 'date',
      initialSortDesc: true,
      dataLoaded: false,
      dialogs: {
        showResubmission: false,
        requestResubmission: {
          timesheetId: null,
          employeeName: "",
          submissionDate: ""
        }
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.loadData()
      },
      deep: true
    }
  },
  computed: {
    headers() {
      let columns = []
      columns.push(
        {
          text: this.t("table.week"),
          align: "start",
          sortable: false,
          value: "week",
          cellClass: "text-middle"
        },
        {
          text: this.t("table.firstname"),
          align: "start",
          sortable: false,
          value: "firstname",
          cellClass: "text-middle"
        },
        {
          text: this.t("table.lastname"),
          align: "start",
          sortable: false,
          value: "lastname",
          cellClass: "text-middle"
        },
        {
          text: this.t("table.number"),
          align: "start",
          sortable: false,
          value: "number",
          cellClass: "text-middle"
        },
        {
          text: this.t("table.staff_number"),
          align: "start",
          sortable: false,
          value: "staff_number",
          cellClass: "text-middle"
        },
        {
          text: this.t("table.projects"),
          align: "start",
          sortable: false,
          value: "projects",
          cellClass: "text-middle"
        }
      )

      if (this.config.externally_logged_hours_visible) {
        columns.push(
          {
            text: this.t("table.externally_logged_hours"),
            value: "externally_logged_seconds",
            align: "right",
            width: 150,
            sortable: false
          }
        )
      }

      columns.push(
        {
          text: this.t("table.absence-hours"),
          align: "end",
          sortable: false,
          value: "absence_hours"
        },
        {
          text: this.t("table.presence-hours"),
          align: "end",
          sortable: false,
          value: "presence_hours"
        },
        {
          text: this.t("table.status"),
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: this.t("table.actions"),
          align: "center",
          sortable: false,
          value: "actions"
        },
        {
          text: this.t("table.details"),
          align: "center",
          sortable: false,
          value: "details"
        }
      )

      return columns
    },
    theme() {
      return store.get('theme')
    }
  },
  methods: {
    t: function(key) {
      return this.$t("timesheet-approval.list." + key)
    },
    buildFilter() {
      let filter = {}
      if (this.filterStore.filter.dateStart) {
        filter.from = this.filterStore.filter.dateStart
      }
      if (this.filterStore.filter.dateEnd) {
        filter.to = this.filterStore.filter.dateEnd
      }
      if (this.filterStore.filter.selectedUser) {
        filter.userid = this.filterStore.filter.selectedUser?.id
      }
      if (this.filterStore.filter.status) {
        filter.status = this.filterStore.filter.status
      }
      filter = mapDataTableConfig(filter, this.options)
      return filter
    },
    async loadData() {
      if (!this.loading) {
        try {
          this.loading = true
          this.items = []
          let filter = this.buildFilter()
          let response = await HTTP.get("timelog/timesheets", { params: filter })
          this.config = response.data.config
          this.items = response.data.data
        } finally {
          this.loading = false
        }
      }
    },
    async applyFilter() {
      this.filterStore.filterApplied()
      await this.loadData()
    },
    async startCsvExport() {
      let filter = this.buildFilter()
      await this.downloadCsv('/timelog/export/har', 'export.csv', filter)
    },
    requestResubmission(item) {
      this.dialogs.requestResubmission.timesheetId = item.id
      this.dialogs.requestResubmission.employeeName = item.user.display_name
      this.dialogs.requestResubmission.submissionDate = item.date
      this.dialogs.showResubmission = true
    },
    async doSendRequestResubmission(data) {
      try {
        await HTTP.post("/timelog/timesheets/request-resubmission", {
          id: this.dialogs.requestResubmission.timesheetId,
          message: data.message
        })
        this.dialogs.showResubmission = false
        await this.loadData()
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.status-filter {
  position: relative;
  float: right;
  max-width: calc(50% - 12px);
  min-height: 48px;
  margin: 4px 0 24px;
  padding-top: 6px;
}

.status-filter.extended {
  padding-right: 160px;
}

.status-filter button {
  position: absolute;
  top: 12px;
  right: 0;
}

.comment {
  margin-right: 10px;
}

.status i,
.status-new i,
.status-approved i,
.status-declined i,
.status-deleted i,
.status-approved-exported i {
  font-size: 30px;
  position: relative;
  left: -8px;
  top: -6px;
}

.status-new i {
  color: var(--v-warning-base);
}

.status-approved i {
  color: var(--v-success-base);
  font-size: 28px;
}

.status-declined i {
  color: var(--v-error-base);
}

.status-approved-exported {
  position: relative;
}

.status-approved-exported i {
  position: absolute;
  font-size: 18px;
  left: -8px;
  top: -5px;
  color: var(--v-success-base);
}

ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0 !important;
}
</style>
