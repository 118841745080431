<template>
    <div class="has-hero-container">
        <div>	
            <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

            <div class="sp-hero sp-bg-light-blue">

                <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

                    <app-filter-apply-button
                        :has-changes="filterStore.hasChanges"
                        :is-initial-state="filterStore.isInitialState"
                        :is-loading="loading"
                        @click="applyFilter();"
                        @reset="filterStore.$reset(); applyFilter();"
                    ></app-filter-apply-button>

                    <v-slide-group show-arrows>
                        <v-slide-item>
                            <div class="filter-search-list">
                                <div class="filter-item">
                                    <div class="filter-item-wrapper">
                                        <app-filter-search-field
                                            v-model="filterStore.filter.searchTerm"
                                            :filterLabel="t('filter.name-search')"
                                            :placeholder="t('filter.name-search-placeholder')"
                                            clearable
                                            append-icon="mdi-magnify"									
                                        ></app-filter-search-field>
                                    </div>
                                </div>
                            </div>
                        </v-slide-item>
                    </v-slide-group>

                </v-container>

            </div>

            <v-container fluid>

                <v-row class="data-table-toolbar"></v-row>

                <v-row v-if="dataLoaded && items.length === 0">
                  <div class="empty-datatable">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    {{ t('no-data') }}
                  </div>
                </v-row>

                <v-row v-else>
                    <v-data-table
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :loading="loading"
                    class="elevation-0"
                    :multi-sort="false"
                    :must-sort="true"
                    :sort-by.sync="filterStore.table.sortBy"
                    :sort-desc.sync="filterStore.table.sortDesc"
                    :page.sync="filterStore.table.currentPage"
                    :items-per-page.sync="filterStore.table.itemsPerPage"
                    :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                        'items-per-page-options': [25, 50, 100, 200],
                        pageText: '{0}-{1} von {2}',
                        'items-per-page-text': t('table.items-per-page')
                    }"
                    >
                        <template v-slot:[`item.shop_products.image`]="{ item }">
                            <div>
                                <image-wrapper
                                :link="getPreviewPictureLink(item.product_id)"
                                :can-remove="false"
                                :max-width="60"
                                :max-height="60" />
                            </div>
                        </template>

                        <template v-slot:[`shop_products.title`]="{ item }">
                            <span>{{ item.shop_products.title }}</span>
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                            <router-link :to="{ path: '/shop-inventory/' + item.id }" class="edit-row-table">
                                <v-icon
                                    small
                                    class="edit-row-button"
                                >
                                    edit
                                </v-icon>
                            </router-link>
                        </template>
                    </v-data-table>

                </v-row>
            </v-container>

        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation.vue'
import loading from '@/components/layouts/Loading.vue'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import ImageWrapper from '@/components/ImageWrapper'
import { useShopInventoryFilterStore } from '@/stores/ShopInventoryFilterStore'

export default {
    name: 'ShopInventoryIndex',
    components: {
        toolbar,
        loading,
        AppFilterApplyButton,
        AppFilterSearchField,
        ImageWrapper
    },
    setup() {
        const filterStore = useShopInventoryFilterStore()
        return { filterStore }
    },
    data () {
        return {
            loading: false,
            dataLoaded: false,
            error: false,
            items: [],
            totalItems: 0,
            dataTotal: 0,
            options: {},
            pictureCache: {}
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'shop_products.image',
                    width: 100
                },
                {
                    text: this.t('table.headers.product_number'),
                    align: 'middle',
                    sortable: false,
                    value: 'shop_product_variants.sku',
                    width: 150
                },
                {
                    text: this.t('table.headers.product'),
                    align: 'middle',
                    sortable: false,
                    value: 'shop_products.title',
                },
                {
                    text: this.t('table.headers.variant'),
                    align: 'middle',
                    sortable: false,
                    value: 'combined_name',
                },
                {
                    text: this.t('table.headers.inventory'),
                    align: 'middle',
                    sortable: false,
                    value: 'amount',
                    width: 150
                },
                {
                    text: this.t('table.headers.edit'),
                    value: 'edit',
                    width: 100,
                    align: 'center',
                    sortable: false
                }
            ]
        },
        
    },
    watch: {
      options: {
        handler() {
          this.getInventory()
        },
        deep: true,
      }
    },
    methods: {
        t: function (key) {
            return this.$t('shop.inventory.list.' + key)
        },
        reload() {
            this.$nextTick(() => {
                this.items = []
                this.getInventory()
            })
        },
        applyFilter() {
            this.filterStore.filterApplied()
            this.reload()
        },
        getInventory() {
            if (!this.loading) {
                this.loading = true
                this.dataLoaded = false

                this.apiCall().then(data => {
                    this.items = data.items
                    this.totalItems = data.total
                    this.loading = false
                    this.dataLoaded = true
                })
            }
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

                HTTP.get('shop_inventories' + url).then(function (response) {
                    let items = response.data.data;
                    let total = response.data.total;
               
                    resolve({
                        items,
                        total,
                    })
                }.bind(this))
            })
        },
        getPreviewPictureLink(id) {
            if (this.pictureCache[id]) {
                // If it's in the cache, return the cached value
                return this.pictureCache[id]
            } else {
                // If it's not in the cache, generate the picture link
                let cacheBuster = new Date().getTime()
                let val = '/shop_products/image/' + id
                let selectedPicture = `${val}?${cacheBuster}`

                // Save the picture link in the global array
                this.pictureCache[id] = selectedPicture

                // Return the generated picture link
                return selectedPicture
            }
        },
        prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
            let queryStringParts = []
            if (this.filterStore.filter.searchTerm) {
                queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
            }
            if (sortBy) {
                queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
                let direction = sortDesc[0] ? 'desc' : 'asc'
                queryStringParts.push('direction=' + direction)
            }
            page = page ? page : 1
            queryStringParts.push('page=' + page)
            if (itemsPerPage != null)
                queryStringParts.push('itemsPerPage=' + itemsPerPage)

            return '?' + queryStringParts.join('&')
        }
    }
}
</script>

<style scoped lang="scss">
.v-application .data-table-toolbar > div:last-child {
    margin-left:0px;
}
</style>