<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>
    
      <v-row v-if="dataLoaded" class="settings-wrapper">

        <v-col class="pr-0">
          <v-card v-if="canAccessAbsenceReasonSettings()" elevation="0">
            <v-card-title>{{ t('group-title') }}</v-card-title>
            <v-card-text>
              <div class="button-wrapper">
                <v-btn 
                  elevation="0"
                  color="primary"
                  class="icon-left" 
                  data-cy="createButton"
                  @click="openSettingDialog(null)"
                  :disabled="saving || loading"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ t('create') }}
                </v-btn>
              </div>

              <v-data-table
                v-if="dataLoaded"
                :headers="headers"
                :items="settings.reasons"
                :items-per-page="99"
                class="elevation-0"
                hide-default-footer
              >
                <template #body="props">
                  <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                  <draggable
                    v-else
                    v-model="settings.reasons"
                    tag="tbody"
                  >
                    <data-table-absences-reasons-row-handler
                      v-for="(item, index) in props.items"
                      :row-index="index"
                      :item="item"
                      :headers="headers"
                    ></data-table-absences-reasons-row-handler> 
                  </draggable>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="pl-0">
          <v-form ref="form2">
            <v-card v-if="canAccessAbsenceEmailSettings()" elevation="0">
              <v-card-title>{{ t('dialog.email-recipient-new-absence') }}</v-card-title>
              <v-card-text>
                <v-switch
                  v-model="settings.settings.absence_email_enabled.value"
                  inset
                  :label="settings.settings.absence_email_enabled.value ? t('yes') : t('no')"
                  @change="handleEmailEnableChange($event, 'absence_email')"
                ></v-switch>
                <br>
                <v-text-field
                  :label="t('dialog.email-recipient')"
                  v-model="settings.settings.absence_email.value"
                  filled
                  :rules="[
                    v => !settings.settings.absence_email_enabled.value || (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v)) || t('dialog.email-recipient-notvalid')
                  ]"
                  :disabled="!settings.settings.absence_email_enabled.value"
                  hide-details="auto"
                ></v-text-field>
              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ t('dialog.email-recipient-confirmed-absence') }}</v-card-title>
              <v-card-text>
                <v-switch
                  v-model="settings.settings.absence_email_approved_enabled.value"
                  inset
                  :label="settings.settings.absence_email_approved_enabled.value ? t('yes') : t('no')"
                  @change="handleEmailEnableChange($event, 'absence_email_approved')"
                ></v-switch>
                <br>
                <v-text-field
                  :label="t('dialog.email-recipient')"
                  v-model="settings.settings.absence_email_approved.value"
                  filled
                  :rules="[
                    v => !settings.settings.absence_email_approved_enabled.value || (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v)) || t('dialog.email-recipient-notvalid')
                  ]"
                  :disabled="!settings.settings.absence_email_approved_enabled.value"
                  hide-details="auto"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
      </v-row>

    </v-container>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
              color="primary"
              text
              class="icon-left"
              @click="reset()"
              :disabled="saving"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="icon-left"
              @click="saveSettings()"
              :disabled="!hasChanges || loading || !dataLoaded"
              :loading="saving"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

    <v-dialog
      v-model="settingsDialog"
      content-class="settings-dialog"
      width="600" 
      persistent
    >
      <v-card v-if="settingsDialog">
        <v-card-title>
          {{ editItem.id ? t('dialog.dialog-title-edit') : t('dialog.dialog-title-add') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent="editItem.id ? editSettings() : addSettings()">
              <v-text-field
                :label="t('table.name')"
                v-model="editItem.name"
                data-cy="name"
                counter="40"
                filled
                :rules="[
                  () => !!editItem.name || t('dialog.error-name-required'),
                  () => (editItem.name && editItem.name.length <= 40) || t('dialog.error-max-name')
                ]"
              ></v-text-field>

              <v-checkbox 
                class="mt-0"
                v-model="editItem.timelog_active"
                :label="t('dialog.timelog_active')"
                @change="handleTimelogActiveChange($event)"
                hide-details="auto"
              ></v-checkbox>

              <div v-if="editItem.timelog_active" class="mt-4">
                <v-autocomplete
                  v-model="customers.customer_id"
                  :loading="customers.loading"
                  :items="customers.data"
                  item-text="display_name"
                  item-value="id"
                  :search-input.sync="customers.search"
                  filled
                  hide-no-data
                  :label="t('dialog.customer')"
                  append-icon="mdi-magnify"
                  clearable
                  :spellcheck="false"
                  :rules="[
                    () => !!customers.customer_id || $t('validation.required.default'),
                  ]"
                ></v-autocomplete>
              </div>

              <div v-if="editItem.timelog_active && customers.customer_id" class="mt-4">
                <v-autocomplete
                  v-model="projects.project_id"
                  :loading="projects.loading"
                  :items="projects.data"
                  item-text="name"
                  item-value="id"
                  :search-input.sync="projects.search"
                  filled
                  hide-no-data
                  :label="t('dialog.project')"
                  append-icon="mdi-magnify"
                  clearable
                  :spellcheck="false"
                  :rules="[
                    () => !!projects.project_id || $t('validation.required.default'),
                  ]"
                ></v-autocomplete>
              </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="closeSettingsDialog()"
          >
            <v-icon>mdi-close</v-icon>
            {{ t( 'dialog.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            @click="editItem.id ? editSettings() : addSettings()"
          >
            <v-icon>mdi-check</v-icon>
            {{ editItem.id ? t('dialog.update') : t('dialog.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import { isAdmin } from '@/services/UserService'
import { hasPermission } from '@/services/PermissionService'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import draggable from 'vuedraggable'
import DataTableAbsencesReasonsRowHandler from '@/components/DataTableAbsencesReasonsRowHandler'

export default {
  name: 'SettingsAbsences',
  components: {
    toolbar,
    loading,
    draggable,
    DataTableAbsencesReasonsRowHandler
  },
  data() {
    return {
      settings: {
        reasons: [],
        settings: []
      },
      dataOriginal: {},
      dataLoaded: false,
      loading: false,
      saving: false,
      error: false,
      settingsDialog: false,
      editItem: null,
      editItemIndex: null,
      customers: {
        loading: false,
        data: [],
        search: "",
        customer_id: null, 
        customer_name: null
      },
      projects: {
        loading: false,
        data: [],
        search: "",
        project_id: null,
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.t('table.timelog'),
          value: 'timelog_project',
          sortable: false,
          align: 'center',
          width: 80
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 80
        },
        {
          text: '',
          value: 'remove',
          sortable: false,
          align: 'center',
          width: 100,
        }
      ]
    },
    hasChanges() {
      return JSON.stringify(this.settings) !== JSON.stringify(this.dataOriginal)
    },
  },
  watch: {
    "customers.search": {
      handler() {
        if (this.settingsDialog) {
          this.searchCustomers()
        }
      }
    },
    "projects.search": {
      handler() {
        if (this.settingsDialog) {
          this.searchProjects()
        }
      }
    }
  },
  created() {
    this.getSettings()
    EventBus.$on('ar-open-settings-dialog', (index, item) => {
      this.openSettingDialog(item, index)
      this.settingsDialog = true
    })
    EventBus.$on('ar-remove-item', (index) => {
      this.removeSetting(index)
    })
  },
  methods: {
    t: function (key) {
      return this.$t('absences.absences-settings.' + key)
    },
    canAccessAbsenceReasonSettings() {
      return hasPermission(['absence_reason_manage']) || isAdmin()
    },
    canAccessAbsenceEmailSettings() {
      return hasPermission(['absence_settings_email']) || isAdmin()
    },
    async searchCustomers() {
      this.customers.loading = true
      try {
        if (this.customers.search && this.customers.search != '') {
          let response = await HTTP.get("customers/search?q=" + this.customers.search)
          this.customers.data = response.data
        }
      } finally {
        this.customers.loading = false
      }
    },
    async searchProjects() {
      this.projects.loading = true;
      try {
        if (this.projects.search && this.projects.search != '') {
          let response = await HTTP.get("customer-projects/search_by_customer?customer_id=" + this.customers.customer_id + "&q=" + this.projects.search)
          this.projects.data = response.data
        }
      } finally {
        this.projects.loading = false
      }
    },
    getAbsenceReasons() {
      return new Promise(function(resolve, reject) {
        HTTP.get('absences/reasons').then((response) => {
          resolve({
            'type': 'reasons',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getAbsenceEmailSettings() {
      return new Promise(function(resolve, reject) {
        HTTP.get('settings?key=absence').then((response) => {
          resolve({
            'type': 'settings',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getSettings() {
      this.loading = true

      Promise.allSettled([
        this.getAbsenceReasons(),
        this.getAbsenceEmailSettings()
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'fulfilled') {
            if (response.value.type === 'reasons') {
              this.settings[response.value.type] = response.value.data
              this.settings[response.value.type].forEach((reason) => {
                reason.timelog_active = reason.timelog_project !== null
              })
            } else {
              let temp = {}
              temp.absence_email = response.value.data.filter(item => item.key === 'absence_email').shift()
              temp.absence_email.value = temp.absence_email.value ?? ''
              temp.absence_email_enabled = response.value.data.filter(item => item.key === 'absence_email_enabled').shift()
              temp.absence_email_enabled.value = temp.absence_email_enabled.value === 'true'
              temp.absence_email_approved = response.value.data.filter(item => item.key === 'absence_email_approved').shift()
              temp.absence_email_approved.value = temp.absence_email_approved.value ?? ''
              temp.absence_email_approved_enabled = response.value.data.filter(item => item.key === 'absence_email_approved_enabled').shift()
              temp.absence_email_approved_enabled.value = temp.absence_email_approved_enabled.value === 'true'
              this.settings[response.value.type] = temp
            }
          }
          if (response.status == 'rejected') {
            errors.push(this.t('http.request-get-error'))
          }
        })

        if (errors.length > 0) {
          this.$root.infoNotification.showMessage(errors)
        }

        this.dataOriginal = JSON.parse(JSON.stringify(this.settings))

        this.dataLoaded = true
        this.loading = false
        this.error = false

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    saveAbsenceReasons() {
      return new Promise(function(resolve, reject) {
        HTTP.post('absences/reasons', this.settings.reasons).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        }) 
      }.bind(this))
    },
    saveAbsenceEmailSettings() {
      return new Promise(function(resolve, reject) {
        try {
          for (var key in this.settings.settings) {
            HTTP.patch('settings/' + this.settings.settings[key].id, this.settings.settings[key])
          }
          resolve()
        } catch {
          reject()
        }
      }.bind(this))
    },
    saveSettings() {
      if (this.$refs.form2.validate()) {

        this.saving = true

        Promise.allSettled([
          this.saveAbsenceReasons(),
          this.saveAbsenceEmailSettings()
        ]).then(function(responses) {

          let errors = []
          responses.forEach((response) => {
            if (response.status == 'rejected') {
              errors.push(this.t('http.request-post-error'))
            }
          })

          if (errors.length > 0) {
            this.$root.infoNotification.showMessage(errors, true)
          } else {
            this.$root.infoNotification.showMessage(this.t('http.success-msg-saved'), false)
          }
          
          this.getSettings()

        }.bind(this)).finally(function() {
          this.saving = false
        }.bind(this))

      }
    },
    reset() {
      this.dataLoaded = false
      this.settings.reasons = []
      this.getSettings()
    },
    openSettingDialog(item, index) {
      if (item) {
        this.editItem = JSON.parse(JSON.stringify(item))
        this.editItemIndex = index
        if (item.timelog_customer) {
          this.customers.data.push(item.timelog_customer)
          this.customers.customer_id = item.timelog_customer.id
        }
        if (item.timelog_project) {
          this.projects.data.push(item.timelog_project)
          this.projects.project_id = item.timelog_project.id
        } 
      } else {
        this.editItem = {
          id: null,
          name: '',
          timelog_active: null,
          timelog_customer: null,
          timelog_project: null
        }
      }
      this.settingsDialog = true
    },
    closeSettingsDialog() {
      this.settingsDialog = false
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.editItem = null
        this.editItemIndex = null
        this.customers.data = []
        this.customers.search = ''
        this.customers.customer_id = null
        this.projects.data = []
        this.projects.search = ''
        this.projects.project_id = null
      })
    },
    addSettings() {
      if (this.$refs.form.validate()) {
        this.settings.reasons.push({
          id: -1,
          name: this.editItem.name,
          timelog_active: this.editItem.timelog_active,
          timelog_customer: this.customers.data.length > 0 ? this.customers.data[0] : null,
          timelog_project: this.projects.data.length > 0 ? this.projects.data[0] : null
        })
        this.closeSettingsDialog()
      }
    },
    editSettings() {
      if (this.$refs.form.validate()) {
        Object.assign(this.settings.reasons[this.editItemIndex], this.editItem)
        this.closeSettingsDialog()
      }
    },
    removeSetting(index) {
      this.settings.reasons.splice(index, 1)
    },
    handleEmailEnableChange(enabled, setting) {
      if (!enabled) {
        this.settings.settings[setting].value = ''
      }
    },
    handleTimelogActiveChange(enabled) {
      if (!enabled) {
        this.editItem.timelog_customer = null
        this.editItem.timelog_project = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-wrapper {
  text-align: right;
  padding-bottom: 16px;
}

.settings-wrapper {
  justify-content: space-between;

  .col {
    flex-basis: unset;
    flex-grow: unset;
  }

  .col:first-child {
      width: calc(66.66% - 16px);
  }

  .col:last-child {
      width: calc(33.33% - 16px);
      align-self: flex-start;
  }

  .v-data-table {
      margin-bottom: 0;
  }
}

.table-loader {
  padding: 0 !important;
  height: auto !important;
}
</style>
