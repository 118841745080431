import { render, staticRenderFns } from "./InstructionsPublish.vue?vue&type=template&id=683de786&scoped=true"
import script from "./InstructionsPublish.vue?vue&type=script&lang=js"
export * from "./InstructionsPublish.vue?vue&type=script&lang=js"
import style0 from "./InstructionsPublish.vue?vue&type=style&index=0&id=683de786&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683de786",
  null
  
)

export default component.exports