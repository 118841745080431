<template>
  <div>
    <basic-edit-container
        :title="t('title')"
        ref="editor"
        :object-type="t('title-single')"
        :object-name="ideaData.subject"
        :data-loaded="dataLoaded"
        @cancel="cancel"
        :save-action="saveItem"
        :allow-delete="true" 
        :delete-action="deleteItem"
        @deleted="onDeleted"
        :has-changes="false"
      >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="ideaData.subject"
              :label="t('details.subject')"            
              filled
              :disabled="true"
              hide-details
            ></v-text-field>
            
            <v-textarea
              class="mt-4"
              v-model="ideaData.content"
              :label="t('details.content')"
              filled
              :disabled="true"
              hide-details
            ></v-textarea>

            <v-text-field
              class="mt-4"
              v-model="ideaData.author_name"
              :label="t('details.author')"            
              filled
              :disabled="true"
              hide-details
            ></v-text-field>

            <v-text-field
              class="mt-4"
              v-model="ideaData.created_at"
              :label="t('details.created')"            
              filled
              :disabled="true"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('comment.group-title') + '*' }}</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="ideaData.comment"
              filled
              hide-details="auto"
              :rules="[
								() => !!ideaData.comment || $t('validation.required.default'),
                () => (ideaData.comment && ideaData.comment.length >= 1) || $t('validation.required.default')
							]"
              :disabled="!canApproveOrDecline"
            ></v-textarea>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0" class="sp-mar-bottom-3">
          <v-card-title>{{ t('images.group-title') }}</v-card-title>
          <v-card-text v-if="ideaData.documents" class="expense-image-wrapper">
            <div v-for="(image, index) in imageData" :key="index" class="expense-image">
              <v-img :src="image"></v-img>
              <v-btn
                color="primary"
                elevation="0"
                outlined
                class="icon-left"
                @click="downloadImage(index)"
              >
                <v-icon>mdi-download</v-icon>
                {{ t('images.download') }}
              </v-btn>
            </div>
          </v-card-text>
          <v-card-text v-else>
            {{ t('images.empty') }}
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:action-buttons>
        <v-btn
          color="primary"
          elevation="0"
          class="icon-left"
          @click="decline()"
          outlined
          :disabled="!canApproveOrDecline || !validComment"
          :loading="isSaving"
        >
          <v-icon>mdi-reply</v-icon>
          {{ t('decline') }}
        </v-btn>

        <v-btn
          color="primary"
          elevation="0"
          class="icon-left"
          @click="approve()"
          :disabled="!canApproveOrDecline || !validComment"
          :loading="isSaving"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('approve') }}
        </v-btn>
      </template>
    </basic-edit-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { Buffer } from 'buffer'
import moment from 'moment'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'

export default {
  name: 'IdeaBoxEdit',
  components: {
    BasicEditContainer,
  },
  data() {
    return {
      dataLoaded: false,
      imageData: null,
      ideaData: {
        id: null,
        anonymized: null,
        author_name: null,
        content: null,
        documents: null,
        status: null,
        subject: null,
        comment: null,
        created_at: null
      },
      isSaving: false
    }
  },
  created() {
    this.ideaData.id = this.$route.params.id
    this.getIdea()
  },
  computed: {
    validComment() {
      return this.ideaData.comment && this.ideaData.comment.length > 0
    },
    canApproveOrDecline() {
      return this.ideaData.status === 'open'
    }
  },
  methods: {
    t: function (key) {
      return this.$t('ideabox.edit.' + key)
    },
    async getIdea() {
      HTTP.get('ideabox/' + this.ideaData.id).then(function (response) {
        this.ideaData = response.data
        this.ideaData.author_name = this.ideaData.anonymized ? this.$t('ideabox.list.anonymous') : this.ideaData.author_name
        this.ideaData.created_at = moment(this.ideaData.created_at).format('DD.MM.YYYY')
        this.dataLoaded = true
        this.loadImages()
      }.bind(this)).catch(function (error) {
        console.log(error)
      })
    },
    loadImages() {
      if (this.ideaData.documents) {
        this.imageData = []
        let documents = this.ideaData.documents.split(';')
        documents.forEach(doc => {
          HTTP.get('/ideabox-documents/' + doc, { responseType: 'arraybuffer' }).then(function (response) {
            this.imageData.push('data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64'))
          }.bind(this))
        })
      }
    },
    decline() {
      this.ideaData.status = 'declined'
      this.submit()
    },
    approve() {
      this.ideaData.status = 'approved'
      this.submit()
    },
    submit() {
      this.isSaving = true
      HTTP.patch('ideabox/' + this.ideaData.id, this.ideaData).then(function () {
        this.close()
      }.bind(this)).catch(function (error) {
        console.log(error)
        this.isSaving = false
      }.bind(this))
    },
    downloadImage(index) {
      if (typeof this.imageData[index] === 'undefined') {
        return
      }
      let fileName = "IdeaDocument_" + moment(this.ideaData.created_at, 'DD.MM.YYYY').format('YYYY_MM_DD') + "_" + index
      const downloadLink = document.createElement("a")
      downloadLink.href = this.imageData[index]
      downloadLink.download = fileName
      downloadLink.click()
    },
    cancel() {
      this.close()
    },
    saveItem() {
      return this.submit()
    },
    close() {
       this.$router.push({ name: 'IdeaBoxIndex' })
    },
    deleteItem() {
      return HTTP.delete('ideabox/' + this.ideaData.id)
    },
    onDeleted() {
      this.close()
    }
  }
}
</script>

<style scope lang="scss">
.expense-image-wrapper {
  display: flex;
  flex-direction: column;

  & > .layout {
    margin-top: 0;
  }

  .expense-image {
    position: relative;
    max-width: 100%;

    & + .expense-image {
      margin-top: 16px;
    }

    .v-btn {
      position: absolute;
      bottom: 16px;
      left: 16px;
      background: #FFF;
    }
  }
}
</style>
