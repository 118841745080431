<template>
    <div>
        <BasicEditContainer
            ref="editor"
            :data-loaded="dataLoaded"
            v-if="dataLoaded"
            :title="$t('chat-groups.details.edit.title')"
            @cancel="cancel"
            :save-action="saveItem"
            @saved="onSaved"
            :allow-delete="true"
            :delete-action="deleteGroup"
            @deleted="onDeleted"
            :object-type="$t('chat-groups.data.object-type')"
            :object-name="item.name"
            :has-changes="hasChanges"
        >
            <template v-slot:default>

                <v-card elevation="0">
                    <v-card-title>{{ $t('chat-groups.details.details.group-title') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <v-text-field
                                :label="$t('chat-groups.details.details.name') + '*'"
                                counter="120"
                                v-model="item.name"
                                filled
                                :rules="[
                                    () => !!item.name || $t('chat-groups.details.details.error'),
                                    () => (item.name && item.name.length <= 120) || $t('chat-groups.details.details.error-max-length')
                                ]"
                            ></v-text-field>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ $t('chat-groups.details.members.group-title') }}</v-card-title>
                    <v-card-text>
                        <div class="user-filter-wrapper">
                            <permission-group-selector
                                v-model="permission_groups"
                                :required="false"
                                mode="dropdown"
                            ></permission-group-selector>
                            
                            <UserMultiSelector
                                ref="userMultiSelector"
                                v-model="item.members"
                                :get-user-url="getUserUrl"
                            ></UserMultiSelector>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ $t('chat-groups.details.cover-picture.group-title') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <picture-upload
                                :show-preview="true"
                                :initial-picture="item.picture_url"
                                v-model="picture"
                                aspectMode="quadratic"
                            ></picture-upload>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </BasicEditContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'
import loading from '@/components/layouts/Loading'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import UserMultiSelector from '@/components/UserMultiSelector'
import PictureUpload from '@/components/inputs/PictureUpload'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'

export default {
    name: 'ChatGroupEdit',
    components: { 
        loading,
        BasicEditContainer,
        UserMultiSelector,
        PictureUpload,
        PermissionGroupSelector
    },
    data() {
        return {
            itemId: null,
            item: {
                name: '',
                members: [],
                picture: '',
                picture_url: '',
            },
            picture: null,
            itemOriginal: null,
            dataLoaded: false,
            imageData: null,
            pictureChanged: 0,
            permission_groups: []
        }
    },
    mounted () {
      EventBus.$on('pgs-selector.blur', function () {
        this.$refs.userMultiSelector.reload()
      }.bind(this))
    },
    beforeDestroy() {
        EventBus.$off('pgs-selector.blur')
    },
    async created() {
        this.itemId = this.$route.params.id
        await this.loadData()
    },
    computed: {
        hasChanges() {
            if (!this.itemOriginal) {
                return false;
            }

            return this.contentChanged || this.picture != null
        },
        contentChanged() {
            return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
        },
    },
    methods: {
        getUserConfirmResponse(response) {
            this.cancelConfirmed = response
        },
        async loadData() {
            this.dataLoaded = false
            try {
                let response = await HTTP.get('/chat/groups/' + this.itemId)
                this.item = response.data
                this.itemOriginal = JSON.parse(JSON.stringify(this.item))
            } finally {
                this.dataLoaded = true
            }
        },
        async saveItem() {
            this.item.picture = this.picture
            return HTTP.patch('chat/groups/' + this.itemId, this.item)
        },
        deleteGroup() {
            return HTTP.delete('chat/groups/' + this.itemId)
        },
        onDeleted() {
            this.cancel()
        },
        async onSaved() {
            this.cancel()
        },
        cancel() {
            this.$router.go(-1)
        },
        getUserUrl() {
            if (this.permission_groups.length) {
                return '/chat/groups/users' + '?pgs=' + this.permission_groups.join(',')
            } else {
                return '/chat/groups/users'
            }
        }
    }
}
</script>

<style scoped lang="scss">
.user-filter-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > div {
        width: 100%;
    }
}
</style>