import { render, staticRenderFns } from "./SurveysCreate.vue?vue&type=template&id=b2b7b27e&scoped=true"
import script from "./SurveysCreate.vue?vue&type=script&lang=js"
export * from "./SurveysCreate.vue?vue&type=script&lang=js"
import style0 from "./SurveysCreate.vue?vue&type=style&index=0&id=b2b7b27e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2b7b27e",
  null
  
)

export default component.exports