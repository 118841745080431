<template>
  <div>
    <toolbar :data-loaded="dataLoaded">{{ title }}</toolbar>

    <v-container fluid class="edit-screen">
      <loading :error="error" :data-loaded="dataLoaded"></loading>
      <v-form ref="form" v-on:submit.prevent>
        <v-row v-if="dataLoaded && allowDelete">
          <v-col class="content-area" cols="12" sm="12" md="8"></v-col>
          <v-col class="meta-area" cols="12" sm="12" md="4">
            <DeleteButton :object-type="objectType" :object-name="objectName" :buttonText="buttonText" @click="doDelete"></DeleteButton>
          </v-col>
        </v-row>

        <v-row v-show="dataLoaded">
          <v-col class="content-area" cols="12" sm="12" md="8">
            <slot/>
          </v-col>
          <v-col class="meta-area" cols="12" sm="12" md="4">
            <slot name="meta"/>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <transition name="fade">
      <div v-if="dataLoaded" class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <slot name="cancel-button">
              <cancel-button-dialog
                ref="cancelButtonDialog"
                :data-edited="hasChanges"
                @cancel="doCancel"
              ></cancel-button-dialog>
            </slot>
            <v-spacer></v-spacer>
            <slot name="action-buttons">
              <v-btn
                  color="primary"
                  elevation="0"
                  class="icon-left"
                  @click="doSave"

                  data-cy="submit"
                  :disabled="!hasChanges"
                  :loading="processing"
              >
                <v-icon>mdi-check</v-icon>
                {{ $t('components.basic-edit-container.save') }}
              </v-btn>
            </slot>
          </v-row>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import toolbar from '@/components/layouts/Navigation'
import DeleteButton from '@/components/inputs/DeleteButton'
import loading from '@/components/layouts/Loading'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'

export default {
  name: "BasicEditContainer",
  components: {
    CancelButtonDialog,
    DeleteButton,
    toolbar,
    loading,
  },
  emits: ['saved', 'deleted', 'cancel'],
  props: {
    title: String,
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    objectType: String,
    objectName: String,
    saveAction: null,
    deleteAction: Function,
    hasChanges: {
      type: Boolean,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      error: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.cancelButtonDialog.beforeRouteLeave(to, from, next)
  },
  methods: {
    doCancel() {
      this.$emit('cancel')
    },
    async doSave() {
      if (this.validate()) {
        if (this.processing === true) {
          return
        }
        if (typeof this.saveAction === 'function') {
          this.processing = true
          let root = this.$root
          this.saveAction()
            .then(function (response) {
              this.$emit('saved', response.data.id)
            }.bind(this))
            .catch(function (error) {
              let messageArray = [];
              if (error.response && error.response.data && error.response.data.errors) {
                let obj = error.response.data.errors
                for (const key in error.response.data.errors) {
                  if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const value = obj[key]
                    messageArray.push(value)
                  }
                }
                if (messageArray.length > 0) {
                  root.infoNotification.showMessage(messageArray.join(", "))
                }
              } else {
                let errorData = error.response.data
                if (errorData && errorData.rule) {
                  let errorMessage = this.$t('errors.rules.' + errorData.rule).replace('{0}', errorData.field)
                  root.infoNotification.showMessage(errorMessage)
                } else {
                  root.infoNotification.showMessage('An unknown error occurred. ' + error.response.data)
                }
              }
            })
            .finally(function () {
              this.processing = false
            }.bind(this))
        } else {
          this.$emit('not-executed')
          return
        }
      }
    },
    doDelete() {
      this.deleteAction()
        .then(function () {
          this.$emit('deleted')
        }.bind(this))
    },
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>
