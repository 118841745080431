<template>
  <div class="filter-item">
    <div class="filter-requests-state-filter">
      <v-select
        v-model="selectedResponseState"
        :label="$t('components.content-filter-servappt-responses-states.label')"
        :items="filterArea"
        item-text="name"
        item-value="slug"
        multiple
        chips
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServapptResponseStateFilter",
  props: ['value'],
  data() {
    return {
      selectedResponseState: this.value,
      filterArea: [
        {
          name: this.t("unconfirmed"),
          slug: 'unconfirmed'
        },
        {
          name: this.t("confirmed"),
          slug: 'confirmed'
        },
        {
          name: this.t("nonpossible"),
          slug: 'nonpossible'
        },
        {
          name: this.t("deleted"),
          slug: 'deleted'
        },
        {
          name: this.t("nonanswer"),
          slug: 'nonanswer'
        }
      ]
    }
  },
  created() {
    let me = this;
    me.filterArea.forEach(function (value) {
      value.name = me.t(value.slug)
    });
  },
  watch: {
    selectedResponseState: function (val) {
      this.$emit('input', val)
    },
    value: function (val) {
      this.selectedResponseState = val
    }
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.data.responses.states.' + key)
    }
  }
}
</script>
