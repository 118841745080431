<template>
    <v-list>
      <v-list-group v-model="isExpanded" :prepend-icon="item.icon">
        <template v-slot:activator>
          <span class="module-title">{{ item.title }}</span>
        </template>
  
        <v-list-item to="/servappt/requests" class="left-inset">
          <v-list-item-title>{{ $t('navigation.servappt-requests') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item to="/servappt/responses" class="left-inset">
          <v-list-item-title>{{ $t('navigation.servappt-responses') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item to="/servappt/appointments" class="left-inset">
          <v-list-item-title>{{ $t('navigation.servappt-appointments') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item v-if="canAppointmentsSee()" to="/servappt/servappt-areas" class="left-inset">
          <v-list-item-title>{{ $t('navigation.servappt-servapptareas') }}</v-list-item-title>
        </v-list-item>
  
        <v-list-item to="/servappt/email-settings" class="left-inset">
          <v-list-item-title>{{ $t('navigation.servappt-emailsettings') }}</v-list-item-title>
        </v-list-item>
  
      </v-list-group>
    </v-list>
  </template>
  
  <script>
  import { isAdmin } from '@/services/UserService'
  import { hasPermission } from '@/services/PermissionService'
  
  export default {
    name: "ServapptListItem",
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    data() {
      return {
        isExpanded: false
      }
    },
    mounted() {
      this.isExpanded = this.$route.meta.accessConfig.moduleName && this.$route.meta.accessConfig.moduleName[0] === "servappt"
    },
    methods: {
      canAppointmentsSee() {
        return hasPermission("servappt_appointment_manage") || isAdmin()
      }
    }
  }
  </script>
  