import TimesheetApprovalIndex from '@/views/TimesheetApprovalIndex'
import TimesheetApprovalEdit from '@/views/TimesheetApprovalEdit'

const timesheetApprovalRoutes = [
  {
    path: '/timesheet-approval',
    name: 'TimesheetApprovalIndex',
    component: TimesheetApprovalIndex,
    meta: {
      accessConfig:  {
        moduleName: ['timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/timesheet-approval/:id?',
    name: 'TimesheetApprovalEdit',
    component: TimesheetApprovalEdit,
    meta: {
      accessConfig:  {
        moduleName: ['timesheet'],
        requiresAuth: true
      }
    }
  }
]

export default timesheetApprovalRoutes
