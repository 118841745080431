import de from 'vuetify/lib/locale/de';

const themesHar = {
    themes: {
        light: {
            primary: '#292147',
            secondary: '#FCE3D9',
            success: '#71AE5C',
            warning: '#FB8C00',
            error: '#E30613',
            buttons: '#292147',
            accent: '#2196F3'
        }
    },
}

export default themesHar
