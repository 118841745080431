<template>
  <v-list>
    <v-list-group v-model="isExpanded" :prepend-icon="item.icon">
      <template v-slot:activator>
        <span class="module-title">{{ item.title }}</span>
      </template>

      <v-list-item to="/checklists" class="left-inset">
        <v-list-item-title>{{ $t('navigation.checklists-create') }}</v-list-item-title>
      </v-list-item>

      <v-list-item to="/checklist-tasks" class="left-inset">
        <v-list-item-title>{{ $t('navigation.checklists-taskcreate') }}</v-list-item-title>
      </v-list-item>

      <v-list-item to="/checklist-subtasks" class="left-inset">
        <v-list-item-title>{{ $t('navigation.checklists-subtaskcreate') }}</v-list-item-title>
      </v-list-item>

      <v-list-item to="/quality-reports" class="left-inset">
        <v-list-item-title>{{ $t('navigation.qualityreports') }}</v-list-item-title>
      </v-list-item>

      <v-list-item to="/statistics" class="left-inset">
        <v-list-item-title>{{ $t('navigation.reports') }}</v-list-item-title>
      </v-list-item>

      <v-list-item to="/settings/checklists/tags" class="left-inset">
        <v-list-item-title>{{ $t('navigation.settings-checklisttags') }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "ChecklistListItem",
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  mounted() {
    this.isExpanded = this.$route.meta.accessConfig.moduleName && this.$route.meta.accessConfig.moduleName[0] === "quality_report"
  }
}
</script>
