import DamageReportIndex from '@/views/DamageReportIndex'
import DamageReportEdit from '@/views/DamageReportEdit'
import SettingsDamageReport from '@/views/SettingsDamageReport'

const damageReportRoutes = [
  {
    path: '/damage-report',
    name: 'DamageReportIndex',
    component: DamageReportIndex,
    meta: {
      accessConfig:  {
        moduleName: ['damage_report'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/damage-report/:id',
    name: 'DamageReportEdit',
    component: DamageReportEdit,
    meta: {
      accessConfig:  {
        moduleName: ['damage_report'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/damage-report',
    name: 'SettingsDamageReport',
    component: SettingsDamageReport,
    meta: {
      accessConfig:  {
        moduleName: ['damage_report'],
        requiresAuth: true
      }
    }
  }
]

export default damageReportRoutes
