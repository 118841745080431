<template>
  <tr :class="getClass">
    <td v-for="(header, index) in headers" :key="index" :style="[{'width': header.width + 'px'}, { 'cursor': header.value == 'edit' ? 'default' : 'grab'}]" :class="[{ 'pr-0': header.value == 'drag' }]">
      <slot :item="item" :name="columnName(header)">

        <div v-if="header.value == 'drag'" :style="getAlignment(header)">
          <v-icon class="drag-handle">mdi-drag</v-icon>
        </div>
     
        <div v-if="header.value == 'name'" :style="getAlignment(header)">
          <span><b>{{ item.name }}</b></span>
        </div>

        <div v-if="header.value == 'timelog_project'" :style="getAlignment(header)">
          <v-icon
          v-if="item.timelog_project != null"
          >
          mdi-check
          </v-icon>
        </div>

        <div v-if="header.value == 'edit'" :style="getAlignment(header)">
          <v-icon
            small
            class="edit-row-button"
            @click="openSettingsDialog(rowIndex, item)"
          >
            edit
          </v-icon>
        </div>

        <div v-if="header.value == 'remove'" :style="getAlignment(header)">
          <v-icon
            small
            class="edit-row-button"
            @click="removeItem(rowIndex, item)"
          >
            delete
          </v-icon>
        </div>

      </slot>
    </td>
  </tr>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: "DataTableAbsencesReasonsRowHandler",
  props: {
    itemClass: {
      type: String,
      default: "",
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    headers: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  data() {
    return {}
  },
  computed: {
    getClass() {
      return this.itemClass
    }
  },
  methods: {
    columnName(header) {
      return `item.${header.value}`
    },
    getAlignment(header) {
      const align = header.align ? header.align : "center"
      return `text-align: ${align}`
    },
    openSettingsDialog(index, item) {
      EventBus.$emit('ar-open-settings-dialog', index, item)
    },
    removeItem(index) {
      EventBus.$emit('ar-remove-item', index)
    }
  }
}
</script>