import { defineStore, acceptHMRUpdate } from 'pinia'

const initialState = { 
  dateStart: null,
  dateEnd: null,
  selectedUser: null,
  searchTerm: '',
  status: []
}

const store = defineStore('IdeaBoxFilterStore', {
  state: () => {
    return {
      filter: {
        ...initialState
      },
      appliedFilter: {
          ...initialState
      },
      table: {
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: null,
        sortDesc: false
      }
    }
  },
  getters: {
    hasChanges: (state) => {
      return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
    },
    isInitialState: (state) => {
      return JSON.stringify(state.filter) === JSON.stringify(initialState)
    }
  },
  actions: {
    filterApplied() {
      this.table.currentPage = 1
      this.appliedFilter = { ...this.filter }
    }
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useIdeaBoxFilterStore = store

