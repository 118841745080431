import { defineStore, acceptHMRUpdate } from 'pinia'

const initialState = { 
  searchTerm: '',
  customer: null,
  showInactive: false
}

const store = defineStore('ChecklistFilterStore', {
  state: () => {
    return {
      filter: {
        ...initialState
      },
      appliedFilter: {
        ...initialState
      },
      table: {
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: []
      }
    }
  },
  getters: {
    hasChanges: (state) => {
      return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
    },
    isInitialState: (state) => {
      return JSON.stringify(state.filter) === JSON.stringify(initialState)
    }
  },
  actions: {
    filterApplied() {
      this.table.currentPage = 1
      this.appliedFilter = { ...this.filter }
    }
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useChecklistFilterStore = store

