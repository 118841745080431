import WorkReportsIndex from '@/views/WorkReportsIndex'
import WorkReportsCreateEdit from '@/views/WorkReportsCreateEdit'
import WorkReportsSelectionListsIndex from '@/views/WorkReportsSelectionListsIndex'
import WorkReportsSelectionListsEdit from '@/views/WorkReportsSelectionListsEdit'
import WorkReportsSelectionListsCreate from '@/views/WorkReportsSelectionListsCreate'
import WorkReportsSettings from '@/views/WorkReportsSettings'

const workReportRoutes = [
    {
        path: '/settings/work-reports/settings',
        name: 'WorkReportsSettings',
        component: WorkReportsSettings,
        meta: {
            accessConfig: {
                moduleName: ['work_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/work-reports/selection-lists',
        name: 'WorkReportsSelectionListsIndex',
        component: WorkReportsSelectionListsIndex,
        meta: {
            accessConfig: {
                moduleName: ['work_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/work-reports/selection-lists/create',
        name: 'WorkReportsSelectionListsCreate',
        component: WorkReportsSelectionListsCreate,
        meta: {
            accessConfig:  {
                moduleName: ['work_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/work-reports/selection-lists/:id?',
        name: 'WorkReportsSelectionListsEdit',
        component: WorkReportsSelectionListsEdit,
        meta: {
            accessConfig: {
                moduleName: ['work_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/work-reports',
        name: 'WorkReports',
        component: WorkReportsIndex,
        meta: {
            accessConfig: {
                moduleName: ['work_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/work-reports/create',
        name: 'WorkReportsCreate',
        component: WorkReportsCreateEdit,
        meta: {
            accessConfig: {
                moduleName: ['work_report'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/work-reports/:id',
        name: 'WorkReportsEdit',
        component: WorkReportsCreateEdit,
        meta: {
            accessConfig: {
                moduleName: ['work_report'],
                requiresAuth: true
            }
        }
    }
]

export default workReportRoutes
