<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>
    
      <v-row v-if="dataLoaded" class="settings-wrapper">

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('group-title') }}</v-card-title>
          <v-card-text>
            <div class="button-wrapper">
              <v-btn 
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog(null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('create') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="categories"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="categories"
                  tag="tbody"
                >
                  <data-table-news-categories-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                  ></data-table-news-categories-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-row>

    </v-container>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
              color="primary"
              text
              class="icon-left"
              @click="reset()"
              :disabled="saving"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                class="icon-left"
                @click="saveSettings()"
                :disabled="!hasChanges || loading || !dataLoaded"
                :loading="saving"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

    <v-dialog
      v-model="settingsDialog"
      content-class="settings-dialog"
      width="600" 
      persistent
    >
      <v-card v-if="settingsDialog">
        <v-card-title>
          {{ editItem.id ? t('dialog.dialog-title-edit') : t('dialog.dialog-title-add') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent="editItem.id ? editSettings() : addSettings()">
              <v-text-field
                :label="t('table.name')"
                v-model="editItem.name"
                data-cy="name"
                counter="40"
                filled
                :rules="[
                  () => !!editItem.name || t('dialog.error-name-required'),
                  () => (editItem.name && editItem.name.length <= 40) || t('dialog.error-max-name')
                ]"
              ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="closeSettingsDialog()"
          >
            <v-icon>mdi-close</v-icon>
            {{ t( 'dialog.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            @click="editItem.id ? editSettings() : addSettings()"
          >
            <v-icon>mdi-check</v-icon>
            {{ editItem.id ? t('dialog.update') : t('dialog.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import draggable from 'vuedraggable'
import DataTableNewsCategoriesRowHandler from '@/components/DataTableNewsCategoriesRowHandler'

export default {
  name: 'SettingsNews',
  components: {
    toolbar,
    loading,
    draggable,
    DataTableNewsCategoriesRowHandler
  },
  data() {
    return {
      categories: [],
      dataOriginal: {},
      dataLoaded: false,
      loading: false,
      saving: false,
      error: false,
      settingsDialog: false,
      editItem: null,
      editItemIndex: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 80
        },
        {
          text: '',
          value: 'remove',
          sortable: false,
          align: 'center',
          width: 100,
        }
      ]
    },
    hasChanges() {
      return JSON.stringify(this.categories) !== JSON.stringify(this.dataOriginal)
    },
  },
  created() {
    this.getSettings()
    EventBus.$on('nc-open-settings-dialog', (index, item) => {
      this.openSettingDialog(item, index)
      this.settingsDialog = true
    })
    EventBus.$on('nc-remove-item', (index) => {
      this.removeSetting(index)
    })
  },
  methods: {
    t: function (key) {
      return this.$t('news.categories.' + key);
    },
    getNewsCategories() {
      return new Promise(function(resolve, reject) {
        HTTP.get('news/categories').then((response) => {
          resolve({
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getSettings() {
      this.loading = true

      Promise.allSettled([
        this.getNewsCategories()      
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'fulfilled') {
            this.categories = response.value.data
          }
          if (response.status == 'rejected') {
            errors.push(this.t('http.request-get-error'))
          }
        })

        if (errors.length > 0) {
          this.$root.infoNotification.showMessage(errors)
        }

        this.dataOriginal = JSON.parse(JSON.stringify(this.categories))

        this.dataLoaded = true
        this.loading = false
        this.error = false

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    saveNewsCategories() {
      return new Promise(function(resolve, reject) {
        HTTP.post('news/categories', this.categories).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        }) 
      }.bind(this))
    },
    saveSettings() {
      this.saving = true

      Promise.allSettled([
        this.saveNewsCategories()
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'rejected') {
            errors.push(this.t('http.request-post-error'))
          }
        })

        if (errors.length > 0) {
          this.$root.infoNotification.showMessage(errors, true)
        } else {
          this.$root.infoNotification.showMessage(this.t('http.success-msg-saved'), false)
        }
        
        this.getSettings()

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    reset() {
      this.dataLoaded = false
      this.categories = []
      this.getSettings()
    },
    openSettingDialog(item, index) {
      if (item) {
        this.editItem = JSON.parse(JSON.stringify(item))
        this.editItemIndex = index
      } else {
        this.editItem = {
          id: null,
          name: '',
        }
      }
      this.settingsDialog = true
    },
    closeSettingsDialog() {
      this.settingsDialog = false
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.editItem = null
        this.editItemIndex = null
      })
    },
    addSettings() {
      if (this.$refs.form.validate()) {
        this.categories.push({
          id: -1,
          name: this.editItem.name,
        })
        this.closeSettingsDialog()
      }
    },
    editSettings() {
      if (this.$refs.form.validate()) {
        Object.assign(this.categories[this.editItemIndex], this.editItem)
        this.closeSettingsDialog()
      }
    },
    removeSetting(index) {
      if (this.categories[index].id === 1) {
        this.$root.infoNotification.showMessage(this.t('http.error-cannot-delete-first-item'), true)
        return
      }
      this.categories.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.settings-wrapper {
  justify-content: space-between;

  & > .v-card {
      width: calc(66.66% - 16px);
  }

  .v-data-table {
      margin-bottom: 0;
  }
}

.button-wrapper {
  text-align: right;
  padding-bottom: 16px;
}
</style>
