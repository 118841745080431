<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
    
			  <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">
			
				  <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>
          
          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                      v-model="filterStore.filter.searchTerm"
                      :filterLabel="t('filter.name-search')"
                      clearable
                      append-icon="mdi-magnify"									
                    ></app-filter-search-field>
                  </div>
                </div>
              </div>
            </v-slide-item>	
				  </v-slide-group>

			  </v-container>
			
		  </div>
		
		  <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                :to="{ name: 'ChecklistCreateTask' }"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('create') }}
            </v-btn>

          </div>
        </v-row>

        <v-row v-if="dataLoaded && elements.data.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>
          <v-data-table
              :headers="headers"
              :items="elements.data"
              :options.sync="options"
              :server-items-length="dataTotal"
              :loading="loading"
              class="elevation-0"
              :sort-by.sync="filterStore.table.sortBy"
              :sort-desc.sync="filterStore.table.sortDesc"
              :page.sync="filterStore.table.currentPage"
              :items-per-page.sync="filterStore.table.itemsPerPage"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-options': [25, 50, 100, 200],
                pageText: '{0}-{1} von {2}',
                'items-per-page-text': t('table.items-per-page')
              }"
          >
            <template v-slot:[`item.name`]="{ item }">
              <span class="ellipsis"><b>{{ item.name }}</b></span>
              <br v-if="item.description">
              <span v-if="item.description" class="ellipsis preview-text">{{ item.description }}</span>
            </template>
            <template v-slot:[`item.tags`]="{ item }">
              <div class="chips-wrapper">
                <v-chip
                  v-for="(tag, index) in item.tags" :key="index"
                  :ripple="false"        
                >
                  {{ tag.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ name: 'ChecklistEditTask', params: { id: item.id } }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
			  </v-row>

		  </v-container>

    </div>
  </div>
</template>

<script>
  import { HTTP } from '@/auth'
  import toolbar from '@/components/layouts/Navigation'
  import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
  import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
  import { useChecklistTasksFilterStore } from '@/stores/ChecklistTasksFilterStore'

  export default {
	name: 'ChecklistTasksIndex',
    components: {
      toolbar,
      AppFilterApplyButton,
	    AppFilterSearchField
    },
    setup() {
      const filterStore = useChecklistTasksFilterStore()
      return { filterStore }
    },
    data () {
      return {
        loading: false,
        elements: {
          page: 1,
          current_page: 1,
          last_page: 1,
          total: null,
          data: []
        },
        dataLoaded: false,
        error: false,
        dataTotal: 0,
        options: {}
      }
    },
    computed: {
      headers() {
        return [
          {
            text: this.t('table.headers.name'),
            align: 'start',
            sortable: true,
            value: 'name'
          },
          {
            text: this.t('table.headers.tags'),
            align: 'start',
            sortable: false,
            value: 'tags'
          },
          {
            text: this.t('table.headers.edit'),
            value: 'edit',
            sortable: false,
            align: 'center',
            width: 150,
          }
        ]
      }
    },
    watch: {
      options: {
        handler() {
          this.getElements()
        },
        deep: true,
      }
    },
    methods: {
      t: function (key) {
        return this.$t('checklists.tasks.list.' + key);
      },
      reload () {
        this.$nextTick(() => {
          this.elements.data = []
          this.getElements()
        })
      },
      applyFilter() {
        this.filterStore.filterApplied()
        this.reload()
      },
      getElements () {
        if (!this.loading) {
          this.loading = true
          this.dataLoaded = false

          let q = ''
          if (this.filterStore.filter.searchTerm) {
            q = '?q=' + this.filterStore.filter.searchTerm
          }

          HTTP.get('mainelements' + q, {params: this.options})
            .then(function (response) {
              this.elements.data = response.data
              this.elements.current_page = response.data.current_page
              this.elements.last_page = response.data.last_page
              this.elements.data = response.data.data
              this.elements.total = response.data.total
              this.dataTotal = response.data.total
              this.error = false
              this.dataLoaded = true
              this.loading = false
            }.bind(this)).catch(function (error) {
              if (!error.status) {
                this.error = true
              }
              this.loading = false
          }.bind(this))
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.chips-wrapper {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
</style>
