<template>
    <v-list>
      <v-list-group v-model="isExpanded" :prepend-icon="item.icon">
        <template v-slot:activator>
          <span class="module-title">{{ item.title }}</span>
        </template>
  
        <v-list-item @click="goToChat" class="custom-padding left-inset">
          <v-list-item-title>{{ $t('navigation.chat') }}</v-list-item-title>
        </v-list-item>

        <v-list-item to="/chat-groups" class="left-inset">
          <v-list-item-title>{{ $t('navigation.chat-groups') }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </template>
  
  <script>
  export default {
    name: "ChatListItem",
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({})
      },
      chatAppUrl: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        isExpanded: true
      };
    },
    mounted() {
        this.isExpanded = this.$route.meta.accessConfig.moduleName && this.$route.meta.accessConfig.moduleName[0] === "chat"
    },
    methods: {
      goToChat() {
        window.open(this.chatAppUrl, "_blank");
      }
    }
  };
  </script>
  