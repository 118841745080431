<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                    v-model="filterStore.filter.dateStart"
                    id="dateStart"
                    :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                    v-model="filterStore.filter.dateEnd"
                    id="dateEnd"
                    :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item filter-status-filter">
                <select-status-filter-widget 
                  v-model="filterStore.filter.status"
                  :multiple="true"
                  :filter-list="['draft', 'published', 'running', 'finished']"
                ></select-status-filter-widget>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              :to="{ path: '/surveys/create'}"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('create') }}
            </v-btn>

          </div>
        </v-row>

        <v-row v-if="dataLoaded && surveys.data.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            :headers="headers"
            :items="surveys.data"
            :options.sync="options"
            :server-items-length="dataTotal"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.title`]="{ item }">
              <span class="ellipsis" v-if="item.title"><b>{{ item.title }}</b></span>
            </template>

            <template v-slot:[`item.start_date`]="{ item }">
              <span class="ellipsis" v-if="item.start_date">
                {{ formatDate(item.start_date) }}<br>{{ formatTime(item.start_date) }}
              </span>
            </template>

            <template v-slot:[`item.end_date`]="{ item }">
              <span class="ellipsis" v-if="item.end_date">
                {{ formatDate(item.end_date) }}<br>{{ formatTime(item.end_date) }}
              </span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <status-icon-widget :status="item.status" :hide-text="false"/>
            </template>

            <template v-slot:[`item.participants`]="{ item }">
              <span v-if="item.analytics_calculated"
                    class="analytics no-icon">{{ item.analytics_count}}</span>
              <span v-else class="analytics-loading"></span>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/surveys/'+item.id }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import moment from 'moment'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import SelectStatusFilterWidget from '@/components/filter-components/SelectStatusFilterWidget'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import { useSurveyFilterStore } from '@/stores/SurveyFilterStore'

export default {
  name: 'SurveysIndex',
  components: {
    toolbar,
    AppFilterDatePicker,
    AppFilterApplyButton,
    SelectStatusFilterWidget,
    StatusIconWidget
  },
  setup() {
    const filterStore = useSurveyFilterStore()
    return { filterStore }
  },
  data() {
    return {
      loading: false,
      surveys: {
        page: 1,
        current_page: 1,
        last_page: 1,
        total: null,
        data: []
      },
      dataLoaded: false,
      error: false,
      dataTotal: 0,
      options: {},
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.title'),
          align: 'start',
          sortable: true,
          value: 'title',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.start'),
          value: 'start_date',
          sortable: true,
          width: 150,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.end'),
          value: 'end_date',
          sortable: true,
          width: 150,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.status'),
          value: 'status',
          align: 'start',
          sortable: false,
          width: 120,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.participants'),
          value: 'participants',
          sortable: false,
          align: 'center',
          width: 150,
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 150,
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getSurveys()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('surveys.list.' + key)
    },
    moment: function () {
      return moment()
    },
    reload() {
      this.$nextTick(() => {
        this.surveys.data = []
        this.getSurveys()
      })
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    buildFilterParameters() {
      let filter = {...this.filterStore.filter}
      if (this.filterStore.filter.dateStart) {
        filter.dateStart = moment(this.filterStore.filter.dateStart).utc().format()
      }
      if (this.filterStore.filter.dateEnd) {
        filter.dateEnd = moment(this.filterStore.filter.dateEnd).endOf('day').utc().format()
      }
      return filter
    },
    getSurveys() {
      if (!this.loading) {
        this.loading = true
        this.dataLoaded = false

        let filter = this.buildFilterParameters()
        let parameters = { ...this.options, ...filter }

        HTTP.get('surveys', { params: parameters }).then(function (response) {
          this.surveys.data = response.data.data
          this.surveys.data.forEach((element, index) => {
            element.status = element.status.toLowerCase()
            element.status = this.getStatusValue(element)
            if (!element.analytics_calculated) {
              this.getAnalyticsData(element.id, index)
            }
          })
          this.surveys.last_page = response.data.last_page
          this.surveys.current_page = response.data.current_page
          this.surveys.total = response.data.total
          this.dataTotal = response.data.total
          this.error = false
          this.dataLoaded = true
          this.loading = false
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
          this.loading = false
        }.bind(this))
      }
    },
    getStatusValue(element) {
      if (element.status == 'draft') {
         return 'draft'
      } else {
        if (moment().isBefore(element.start_date)) {
          return 'published'
        } else if (moment().isAfter(element.start_date) && moment().isBefore(element.end_date)) {
          return 'running'
        } else {
          return 'finished'
        }
      }
    },
    getAnalyticsData(itemId, index) {
      HTTP.get('analytics/surveys/' + itemId)
        .then(function (response) {
          if (this.surveys.data[index]) {
            this.$set(this.surveys.data[index], 'analytics_count', response.data.read_total)
            this.$set(this.surveys.data[index], 'analytics_total', response.data.total)
            this.$set(this.surveys.data[index], 'analytics_calculated', true)
          }
        }.bind(this))
        .catch(function (error) {
          console.log(error)
        }.bind(this))
    }
  }
}
</script>

<style scoped lang="scss">
.analytics {
  display: inline-block;
  position: relative;
  padding: 0 5px 0 28px;
  font-size: 14px;

  &.no-icon {
    padding-left: 5px;
  }

  .material-icons {
    position: absolute;
    left: 0;
  }
}

.analytics-loading {
  left: 0;
  top: 4px;
}
</style>

