import { defineStore, acceptHMRUpdate } from 'pinia'
import moment from 'moment'

const initialState = { 
    dateStart: moment().subtract(28, 'days').format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    selectedUser: null,
    selectedCustomer: null
}

const store = defineStore('TimestampFilterStore', {
    state: () => {
        return {
            filter: {
                ...initialState
            },
            appliedFilter: {
                ...initialState
            },
            table: {
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: ['start_date'],
                sortDesc: [true]
            },
        }
    },
    getters: {
        hasChanges: (state) => {
            return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
        },
        isInitialState: (state) => {
            return JSON.stringify(state.filter) === JSON.stringify(initialState)
        }
    },
    actions: {
        filterApplied() {
            this.table.currentPage = 1
            this.appliedFilter = {...this.filter}
        }
    },
    persist: true,
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useTimestampFilterStore = store

