<template>
  <div>
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      :item-text="getItemText"
      :search-input.sync="search"
      cache-items
      filled
      hide-no-data
      :label="$t('components.content-filter-object.label')"
      append-icon="mdi-magnify"
      return-object
      :disabled="readonly"
      clearable
      :rules="rules"
    ></v-autocomplete>
  </div>
</template>

<script>
import { CancellingHttp } from '@/infrastructure/communication'

export default {
  name: "ContentFilterObject",
  components: {},
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    readonly: Boolean,
    value: {}
  },
  data() {
    return {
      com: new CancellingHttp(),
      abortController: null,
      loading: false,
      items: [],
      search: null,
      select: this.value,
    }
  },
  watch: {
    select(val) {
      this.$emit('input', val);
      this.$emit('item-selected', val?.id, val);
    },
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    value: function (val) {
      this.select = val
    }
  },
  created() {
    this.com.on('loading', (val) => {
      this.loading = val
    })
  },
  methods: {
    getItemText(item) {
      if (item.objects) {
        return item.objects
      }
      return item.objects
    },
    async querySelections(val) {
      let url = 'work-reports/objects?o=' + encodeURIComponent(val)
      let response = await this.com.get(url)

      if (response && response.object) {
        response.object.forEach(item => {
          this.items.push(item.object)
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>
