<template>
  <div class="filter-item expense-status-filter">
      <v-select
          v-model="selectedFilterStatus"
          :label="$t('components.content-filter-expense-status.label')"
          :items="filterStatus"
          item-text="name"
          item-value="slug"
          multiple
          chips
      ></v-select>
  </div>
</template>

<script>
export default {
  name: "ExpenseStatusFilter",
  props: ['value'],
  data() {
    return {
      selectedFilterStatus: this.value,
      filterStatus: [
        {
          name: '',
          slug: 'filed'
        },
        {
          name: '',
          slug: 'approved'
        },
        {
          name: '',
          slug: 'rejected'
        },
        {
          name: '',
          slug: 'reimbursed'
        }
      ],
    }
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val);
    },
    value: function (val) {
      this.selectedFilterStatus = val
    }
  },
  created() {
    let me = this;
    this.filterStatus.forEach(function (value) {
      value.name = me.$t('expenses.data.status.' + value.slug);
    });
  }
}
</script>

<style scoped>
.expense-status-filter {
  min-width: 320px;
}
</style>
