<template>
    <div>
        <BasicNewContainer
            :title="$t('chat-groups.details.create.title')"
            ref="editor"
            @cancel="cancel"
            :saveAction="saveItem"
            @saved="close"
            :has-changes="hasChanges"
        >
            <template v-slot:default>
                <v-card elevation="0">
                    <v-card-title>{{ $t('chat-groups.details.details.group-title') }}</v-card-title>
                    <v-card-text>
                        <v-text-field
                            :label="$t('chat-groups.details.details.name')"
                            v-model="data.name"
                            counter="120"
                            filled
                            :rules="[
                                () => !!data.name || $t('chat-groups.details.details.error'),
                                () => (data.name && data.name.length <= 120) || $t('chat-groups.details.details.error-max-length')
                            ]"
                        ></v-text-field>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ $t('chat-groups.details.members.group-title') }}</v-card-title>
                    <v-card-text>
                        <div class="user-filter-wrapper">
                            <permission-group-selector
                                v-model="permission_groups"
                                :required="false"
                                mode="dropdown"
                            ></permission-group-selector>

                            <UserMultiSelector
                                ref="userMultiSelector"
                                v-model="data.members"
                                :get-user-url="getUserUrl"
                            ></UserMultiSelector>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ $t('chat-groups.details.cover-picture.group-title') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <picture-upload
                                :show-preview="true"
                                v-model="data.picture"
                                aspectMode="quadratic"
                            ></picture-upload>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </BasicNewContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'
import loading from '@/components/layouts/Loading'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import UserMultiSelector from '@/components/UserMultiSelector'
import PictureUpload from '@/components/inputs/PictureUpload'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'

export default {
    name: 'ChatGroupCreate',
    components: {
        loading,
        BasicNewContainer,
        UserMultiSelector,
        PictureUpload,
        PermissionGroupSelector  
    },
    data() {
        return {
            data: {
                name: '',
                members: [],
                picture: '',
            },
            dataOriginal: null,
            permission_groups: []
        }
    },
    mounted() {
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
        EventBus.$on('pgs-selector.blur', function () {
            this.$refs.userMultiSelector.reload()
        }.bind(this))
    },
    beforeDestroy() {
        EventBus.$off('pgs-selector.blur')
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        }
    },
    methods: {
        async saveItem() {
            let data = {
                name: this.data.name,
                members: this.data.members,
                picture: this.data.picture,
            }
            await HTTP.post('/chat/groups', data)
        },
        close() {
            this.cancel()
        },
        cancel() {
            if (window.history.length > 1) {
                this.$router.go(-1)
            } else {
                window.close()
            }
        },
        getUserUrl() {
            if (this.permission_groups.length) {
                return '/chat/groups/users' + '?pgs=' + this.permission_groups.join(',')
            } else {
                return '/chat/groups/users'
            }
        }
    }
}
</script>
