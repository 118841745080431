import { defineStore, acceptHMRUpdate } from 'pinia'

const initialState = { 
    searchTerm: '',
    fulfilledStates: ['fulfilled', 'unfulfilled']
}

const store = defineStore('LearningPortalEvaluationFilterStore', {
    state: () => {
        return {
            filter: {
                ...initialState
            },
            appliedFilter: {
                ...initialState
            },
            table: {
                currentPage: 1,
                itemsPerPage: 25
            },
        }
    },
    getters: {
        hasChanges: (state) => {
            return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
        },
        isInitialState: (state) => {
            return JSON.stringify(state.filter) === JSON.stringify(initialState)
        }
    },
    actions: {
        filterApplied() {
            this.table.currentPage = 1
            this.appliedFilter = {...this.filter}
        }
    },
    persist: true,
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useLearningPortalEvaluationFilterStore = store

