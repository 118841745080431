<template>
  <div>

    <BasicEditContainer
      :title="t('edit.title')"
      ref="editor"
      object-type="Time"
      object-name="Zeiteintrag"
      :data-loaded="dataLoaded"
      @cancel="cancel"
      :save-action="save"
      @saved="onSaved"
      :allowDelete="false"
      :has-changes="hasChanges"
      >
        <template v-slot:default>
          <v-card elevation="0">
            <v-card-title>{{ t('times.group-title') }}</v-card-title>
            <v-card-text>
              <div v-for="record in data">
                <v-row dense>
                  <v-col :class="getColClass(record)">
                    <v-select
                      :label="t('edit.client')"
                      :title="t('edit.client')"
                      item-text="name"
                      item-value="id"
                      v-model="record.customer_id"
                      :items="config.customers"
                      filled
                      :readonly="!config.can_edit_times"
                      :rules="[
                        () => !!record.customer_id || $t('validation.required.client')
                      ]"
                    ></v-select>
                  </v-col>

                  <v-col :class="getColClass(record)">
                    <v-select
                      :label="t('edit.projects')"
                      :title="t('edit.projects')"
                      item-text="name"
                      item-value="id"
                      v-model="record.project_id"
                      :items="projectsOfCustomer(record.customer_id)"
                      :readonly="!config.can_edit_times"
                      filled
                    ></v-select>
                  </v-col>

                  <v-col v-if="!!record.time_from" class="col-1">
                    <div>
                      <v-menu
                        v-model="record.time_from_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        @input="recordTimeChanged(record, 'time_from_changed')"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            filled
                            class="pt-0 mt-0"
                            :label="t('from')"
                            v-model="record.time_from"
                            :readonly="true"
                            v-bind="attrs"
                            v-on="on"
                            @click="record.time_from_menu = true"
                            @change="recordTimeChanged(record, 'time_from_changed')"
                            hide-details
                          ></v-text-field>
                        </template>

                        <v-time-picker
                          ref="timer"
                          format="24hr"
                          class="v-time-picker-custom"
                          v-model="record.time_from"
                          @change="recordTimeChanged(record, 'time_from_changed')"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col v-if="!!record.time_to" class="col-1">
                    <div>
                      <v-menu
                        v-model="record.time_to_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        @input="recordTimeChanged(record, 'time_to_changed')"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            filled
                            class="pt-0 mt-0"
                            :label="t('to')"
                            v-model="record.time_to"
                            :readonly="true"
                            v-bind="attrs"
                            v-on="on"
                            @click="record.time_to_menu = true"
                            @change="recordTimeChanged(record, 'time_to_changed')"
                            hide-details
                          ></v-text-field>
                        </template>

                        <v-time-picker
                          ref="timer"
                          format="24hr"
                          class="v-time-picker-custom"
                          v-model="record.time_to"
                          @change="recordTimeChanged(record, 'time_to_changed')"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col class="col-1">
                    <div>
                      <v-text-field
                        filled
                        :label="t('hours')"
                        v-model="record.duration_hours"
                        hide-details="auto"
                        type="number"
                        :disabled="!config.can_edit_times || !!record.time_from"
                        @change="updateHoursChanged(record)"
                      ></v-text-field>
                    </div>
                  </v-col>

                  <v-col class="col-3">
                    <v-textarea
                      filled
                      :label="record.hours_changed ? t('edit.comment') + '*' : t('edit.comment')"
                      v-model="record.new_comment"
                      counter="120"
                      auto-grow
                      rows="1"
                      class="change-comment"
                      :append-icon="record.comments.length > 0 ? 'mdi-comment-alert-outline' : ''"
                      :readonly="!config.can_edit_times"
                      @click:append="openComments(record)"
                      :rules="[
                        () => (!record.hours_changed || (record.hours_changed && record.new_comment !== '')) || $t('validation.required.comment')
                      ]"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>

              <v-row>
                <v-col cols="12" class="text-right">
                  <span :title="!config.can_edit_times ? t('timeperiod-locked') : ''">
                    <v-btn
                      :disabled="!config.can_edit_times"
                      color="primary"
                      elevation="0"
                      class="icon-left"
                      @click="addTimeRow"
                    >
                      <v-icon>mdi-check</v-icon>
                      {{ t('edit.add') }}
                    </v-btn>
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('times.title') }}</v-card-title>
          <v-card-text>
            <div class="mb-3">
              {{ t('times.employee') }} {{ user.display_name }}<br/>
              {{ t('times.date') }} {{ formatDate(date) }}
            </div>

            <strong>
              {{ t('times.create_date') }}
            </strong><br>
            <div v-for="record in data" v-if="record.id !== undefined">
              {{ getCustomerName(record.customer_id) }} {{ formatDate(record.created_at) }} :
              {{ formatTime(record.created_at) }}
            </div>
          </v-card-text>
        </v-card>

        <v-card
          class="mt-4"
          elevation="0"
          v-if="showExternalHours"
        >
          <v-card-title>{{ t('external-logged-hours.group-title') }}</v-card-title>
          <v-card-text>
            <div class="disabled-input">
              <span>{{ formatSecondsAsHours(additional.external_seconds) }}</span>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </BasicEditContainer>

    <v-dialog
      v-model="showComments"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('comments.title') }}
        </v-card-title>
        <v-card-text class="d-flex comments-list">
          
          <div v-for="(comment, index) in this.commentsList" :key="index">
            <span><i>{{ formatDateTime(comment.date) }} : {{ comment.user.display_name }}</i><br><b>{{ comment.comment }}</b></span>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeComments()"
            text
          >
            <v-icon>mdi-close</v-icon>
            {{ t('comments.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'

export default {
  name: 'TimeEdit',
  components: {
    BasicEditContainer,
  },
  data() {
    return {
      userId: null,
      date: null,
      user: {},
      config: {
        can_edit_times: false,
        customers: [],
        projects: [],
        user: {},
      },
      additional: {
        external_seconds: null,
      },
      data: [],
      newEntry: {
        customer_id: null,
        project_id: null,
        time_from: '00:00',
        time_from_original: '00:00',
        time_from_menu: false,
        time_to: '00:00',
        time_to_original: '00:00',
        time_to_menu: false,
        duration_hours: 0,
        duration_hours_original: 0,
        hours_changed: true,
        comments: [],
        new_comment: ''
      },
      dataOriginal: {},
      dataLoaded: false,
      showNewEntryRow: false,
      rules: {
        length: len => v => (v || '').length >= len || this.t('errors.length'),
        required: v => !!v || this.t('errors.required'),
      },
      showComments: false,
      commentsList: []
    }
  },
  async created() {
    this.userId = this.$route.query.userId
    this.date = this.$route.query.date
    await this.loadData()
  },
  computed: {
    hasChanges() {
      if (!this.dataOriginal) {
        return false;
      }
      return this.contentChanged
    },
    contentChanged() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    },
    showExternalHours() {
      return this.additional.external_seconds !== null
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-timelogs.detail.' + key);
    },
    moment: function () {
      return moment();
    },
    projectsOfCustomer: function (customerId) {
      return this.config.customers.find(z => z.id === customerId)?.projects || []
    },
    updateHoursChanged(e) {
      e.hours_changed = (e.duration_hours_original).toFixed(2) !== parseInt(e.duration_hours)
      e.duration = parseFloat(e.duration_hours) * 3600
    },
    addTimeRow() {
      this.showNewEntryRow = true
      this.newEntry.duration = this.newEntry.duration_hours * 60 * 60
      this.data.push({...this.newEntry})
      this.newEntry = {
        customer_id: null,
        project_id: null,
        time_from: '00:00',
        time_from_original: '00:00',
        time_from_menu: false,
        time_from_changed: false,
        time_to: '00:00',
        time_to_original: '00:00',
        time_to_menu: false,
        time_to_changed: false,
        duration_hours: 0,
        duration_hours_original: 0,
        hours_changed: true,
        comments: [],
        new_comment: ''
      }
    },
    async loadData() {
      this.dataLoaded = false
      try {
        let response = await HTTP.get('timelog/day?userId=' + this.userId + '&date=' + this.date)
        this.date = response.data.date
        this.user = response.data.user
        this.config = response.data.config
        this.additional = response.data.additional
        response.data.records.forEach((element) => {
          element.time_from_original = element.time_from
          element.time_from = element.time_from ? moment(element.time_from, 'HH:mm:ss').format('HH:mm') : null
          element.time_from_menu = false
          element.time_from_changed = false
          element.time_to_original = element.time_to
          element.time_to = element.time_to ? moment(element.time_to, 'HH:mm:ss').format('HH:mm') : null
          element.time_to_menu = false
          element.time_to_changed = false
          element.duration_hours_original = element.duration / 60 / 60
          element.duration_hours = (element.duration / 60 / 60).toFixed(2)
          element.hours_changed = false
          element.new_comment = ''
        })
        this.data = response.data.records
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
        if (!this.config.can_edit_times) {
          this.$root.infoNotification.showInfo(this.t('timeperiod-locked'))
        }
      } catch (e) {
        this.$root.infoNotification.showError(e.response.data.message)
      } finally {
        this.dataLoaded = true
      }
    },
    async save() {
      let modifiedData = []
      
      this.data.forEach((z) => {
        modifiedData.push({
          id: z.id,
          customer_id: z.customer_id,
          project_id: z.project_id,
          time_from: z.time_from_changed ? moment(z.time_from, 'HH:mm').format('HH:mm:ss') : z.time_from_original,
          time_to: z.time_to_changed ? moment(z.time_to, 'HH:mm').format('HH:mm:ss') : z.time_to_original,
          duration: z.duration,
          comment: z.hours_changed && z.new_comment !== '' ? z.new_comment : ''
        })
      })

      const json = JSON.stringify({
        user_id: this.userId,
        date: this.date,
        entries: modifiedData
      })
      await HTTP.post('timelog/day', { 'data': json })
      this.close()
    },
    async onSaved() {
      this.close()
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({ name: 'Time' })
    },
    getCustomerName(id) {
      return this.config.customers.find(z => z.id === id).display_name
    },
    openComments(record) {
      this.commentsList = record.comments
      this.showComments = true
    },
    closeComments() {
      this.showComments = false
      this.commentsList = []
    },
    recordTimeChanged(record, param) {
      record.hours_changed = true
      let timeStart = moment(record.time_from, 'HH:mm')
      let timeEnd = moment(record.time_to, 'HH:mm')
      let duration = timeStart <= timeEnd ? moment.duration(timeEnd.diff(timeStart)) : moment.duration(timeEnd.add(1, 'days').diff(timeStart))
      record[param] = true
      record.duration = duration.asSeconds()
      record.duration_hours = duration.asHours().toFixed(2)
    },
    getColClass(record) {
      return record.time_from ? 'col-3' : 'col-4'
    }
  }
}
</script>

<style lang="scss" scoped>
.change-comment {

  ::v-deep .v-input__append-inner {
    cursor: pointer;
  }
}

.comments-list {
  flex-direction: column;
  gap: 16px;

  i {
    color: inherit;
  }
}
</style>
