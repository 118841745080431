<template>
    <div class="has-hero-container">
  
        <toolbar :dataLoaded="true">{{ t('title') + training.title }}</toolbar>

        <div class="sp-hero sp-bg-light-blue">
            <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">
  
                <app-filter-apply-button
                    :has-changes="filterStore.hasChanges"
                    :is-initial-state="filterStore.isInitialState"
                    :is-loading="loading"
                    @click="applyFilter();"
                    @reset="filterStore.$reset(); applyFilter();"
                ></app-filter-apply-button>
  
                <v-slide-group show-arrows>
                    <v-slide-item>
                        <div class="filter-search-list">
                            <div class="filter-item">
                                <div class="filter-item-wrapper">
                                    <app-filter-search-field
                                        v-model="filterStore.filter.searchTerm"
                                        :filterLabel="t('user-search')"
                                        clearable
                                        @click:clear="clearSearchTerm"
                                        append-icon="mdi-magnify"
                                    ></app-filter-search-field>
                                </div>
                            </div>
                        </div>
                    </v-slide-item>

                    <v-slide-item>
                        <div class="pl-2">
                            <div class="filter-item complete-filter">
                                <v-checkbox
                                    class="mt-0"
                                    v-model="filterStore.filter.fulfilledStates"
                                    :label="t('filter.show-fulfilled')"
                                    value="fulfilled"
                                    hide-details="auto"
                                    @change="validateFulfilledStates($event)"
                                ></v-checkbox>

                                <v-checkbox
                                    class="mt-0"
                                    v-model="filterStore.filter.fulfilledStates"
                                    :label="t('filter.show-unfulfilled')"
                                    value="unfulfilled"
                                    hide-details="auto"
                                    @change="validateFulfilledStates($event)"
                                ></v-checkbox>
                              </div>
                        </div>
                    </v-slide-item>
                </v-slide-group>

            </v-container>
        </div>

        <v-container fluid class="edit-screen pt-8">

            <v-row class="py-0 pb-2 data-table-toolbar">
                <v-spacer></v-spacer>
                <div>
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="icon-left"
                        :loading="excelExporting"
                        :disabled="totalItems == 0"
                        @click="exportExcel()"
                    >
                        <v-icon>mdi-download</v-icon>
                        {{ t('export') }}
                    </v-btn>
                </div>
            </v-row>

            <v-row>

                <v-col cols="8" class="pl-0">

                    <v-data-table
                        :headers="headers"
                        :items="evaluations"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :loading="loading"
                        :no-data-text="t('no-data')"
                        class="elevation-0"
                        :page.sync="filterStore.table.currentPage"
                        :items-per-page.sync="filterStore.table.itemsPerPage"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-options': [25, 50, 100, 200],
                            pageText: '{0}-{1} von {2}',
                            'items-per-page-text': t('table.items-per-page')
                        }" 
                    >
                        <template v-slot:[`item.updated_at`]="{ item }">
                            {{ formatDateTime(item.updated_at) }}
                        </template>    
                        
                        <template v-slot:[`item.correct_answers`]="{ item }">
                            <v-chip
                                class="fulfilled-chip"
                                :class="[{ 'fulfilled': item.correct_answers >= training.required_correct_answers }]"
                            >
                                {{ item.correct_answers + '/' + training.numberOfQuizes }}
                            </v-chip>     
                        </template>
                    </v-data-table>

                </v-col>

                <v-col cols="4" class="pr-0">
                    <v-card elevation="0">
                        <v-card-title>{{ t('card-title') }}</v-card-title>
                        <v-card-text>
                            <loading v-if="!dataLoaded" class="mt-0"></loading>
                            <div v-else>
                                <v-text-field
                                    :label="t('title-placeholder')"
                                    v-model="training.title"
                                    filled
                                    :readonly="true"
                                    hide-details="auto"
                                ></v-text-field>

                                <v-text-field
                                     class="mt-3"
                                    :label="t('lessons-placeholder')"
                                    v-model="training.lessons.length"
                                    filled
                                    :readonly="true"
                                    hide-details="auto"
                                ></v-text-field>

                                <v-text-field
                                     class="mt-3"
                                    :label="t('quizes-placeholder')"
                                    v-model="training.numberOfQuizes"
                                    filled
                                    :readonly="true"
                                    hide-details="auto"
                                ></v-text-field>

                                <v-text-field
                                     class="mt-3"
                                    :label="t('correct-placeholder')"
                                    v-model="training.required_correct_answers"
                                    filled
                                    :readonly="true"
                                    hide-details="auto"
                                ></v-text-field>

                                <v-text-field
                                     class="mt-3"
                                    :label="t('participants-placeholder')"
                                    v-model="totalItems"
                                    filled
                                    :readonly="true"
                                    hide-details="auto"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>

        </v-container>

        <transition name="fade">
            <div class="bottom-navigation modal-nav">
                <v-container fluid>
                    <v-row>
                        <v-btn
                          color="primary"
                          text
                          class="icon-left"
                          @click="close()"
                        >
                          <v-icon>mdi-close</v-icon>
                          {{ t('close') }}
                        </v-btn>
                    </v-row>
                </v-container>
            </div>
        </transition>

    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import moment from 'moment'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import { useLearningPortalEvaluationFilterStore } from '@/stores/LearningPortalEvaluationFilterStore'

export default {
    name: 'LearningPortalEvaluationsShow',
    components: {
        toolbar,
        loading,
        AppFilterApplyButton,
        AppFilterSearchField
    },
    setup() {
        const filterStore = useLearningPortalEvaluationFilterStore()
        return { filterStore }
    },
    data() {
        return {
            training: {
                id: null,
                title: '',
                is_active: true,
                folders: [],
                lessons: [],
                required_correct_answers: 0,
                allowed_tries: 1,
                allowed_tries_enabled: 0
            },
            evaluations: [],
            totalItems: 0,
            options: {},
            dataLoaded: false,
            loading: false,
            error: false,
            excelExporting: false
        }
    },
    created() {
        this.training.id = this.$route.params.id
    },
    watch: {
        options: {
            handler() {
                this.getEvaluations()
            },
            deep: true,
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.t('table.user'),
                    align: 'start',
                    sortable: false,
                    value: 'user.display_name',
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.submitted'),
                    align: 'start',
                    sortable: false,
                    value: 'updated_at',
                    width: 220,
                    cellClass: 'text-middle'
                },
                {
                    text: this.t('table.result'),
                    align: 'center',
                    sortable: false,
                    value: 'correct_answers',
                    width: 120,
                    cellClass: 'text-middle'
                }
            ]
        }
    },
    methods: {
        t: function (key) {
            return this.$t('learning-portal.evaluations.' + key)
        },
        reload () {
            this.$nextTick(() => {
                this.evaluations = []
                this.getEvaluations()
            })
        },
        applyFilter() {
            this.filterStore.filterApplied()
            this.reload()
        },
        clearSearchTerm() {
            this.filterStore.filter.searchTerm = ''
        },
        getEvaluations() {
            if (!this.loading) {
                this.loading = true
                this.dataLoaded = false

                this.apiCall().then(data => {
                    this.evaluations = data.items
                    this.training = data.training
                    this.totalItems = data.total
                    this.loading = false
                    this.dataLoaded = true
                })
            }
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                const { page, itemsPerPage } = this.options
                let url = this.prepareUrl(page, itemsPerPage)

                HTTP.get('learning_portal_training/' + this.training.id + '/evaluations' + url).then(function (response) {
                let items = response.data.evaluations.data
                let total = response.data.evaluations.total
                let training = response.data.training
            
                resolve({
                    items,
                    total,
                    training
                })
                }.bind(this))
            })
        },
        prepareUrl(page, itemsPerPage) {
            let queryStringParts = []
            if (this.filterStore.filter.searchTerm) {
                queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
            }
            let fulfilledFilter = this.filterStore.filter.fulfilledStates.join(",")
            queryStringParts.push('fulfilledFilter=' + fulfilledFilter)
            page = page ? page : 1
            queryStringParts.push('page=' + page)
            if (itemsPerPage != null) {
                queryStringParts.push('itemsPerPage=' + itemsPerPage)
            }
            return '?' + queryStringParts.join('&')
        },
        exportExcel() {
            this.excelExporting = true

            const { page, itemsPerPage } = this.options
            let url = this.prepareUrl(page, itemsPerPage)

            HTTP.post('dataexport/excel/learning-portal/' + this.training.id + '/evaluations' + url, {}, { responseType: 'arraybuffer' }).then(function (response) {
                let headers = response.headers
                let blob = new Blob([response.data], {type: headers['content-type']})
                let link = document.createElement('a')
                link.style = 'display: none'
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Evaluationen_' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
                document.body.appendChild(link)
                link.click()
                setTimeout(function () {
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(blob)
                }, 100)
                this.excelExporting = false
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.excelExporting = false
            }.bind(this))
        },
        close () {
            this.$router.push({ name: 'LearningPortalEvaluationsIndex' })
        },
        validateFulfilledStates(event) {
            if (event.length == 0) {
                this.filterStore.filter.fulfilledStates = ['fulfilled', 'unfulfilled']
            }
        }
    }
}
</script>

<style scoped lang="scss">
.complete-filter {
    padding-top: 14px !important;
}

.fulfilled-chip {
    display: inline-block;
    font-weight: bold;
    line-height: 33px;
    color: #FFFFFF !important;
    background: var(--v-warning-base) !important;

    &.fulfilled {
        background: var(--v-success-base) !important;
    }
}
</style>