import CustomerNotesIndex from '@/views/CustomerNotesIndex'
import CustomerNotesEdit from '@/views/CustomerNotesEdit'
import CustomerNotesCreate from '@/views/CustomerNotesCreate'

const customersRoutes = [
  {
    path: '/customer_notes',
    name: 'CustomerNotesIndex',
    component: CustomerNotesIndex,
    meta: {
      accessConfig:  {
          moduleName: ['customer_notes'],
          requiresAuth: true
      }
    }
  },
  {
    path: '/customer_notes/create',
    name: 'CustomerNotesCreate',
    component: CustomerNotesCreate,
    meta: {
      accessConfig:  {
          moduleName: ['customer_notes'],
          requiresAuth: true
      }
    }
  },
  {
    path: '/customer_notes/:id?',
    name: 'CustomerNotesEdit',
    component: CustomerNotesEdit,
    meta: {
      accessConfig:  {
          moduleName: ['customer_notes'],
          requiresAuth: true
      }
    }
  }
]

export default customersRoutes
