// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import de from 'vuetify/lib/locale/de';
import 'vuetify/dist/vuetify.min.css'
import store from 'store'
import themesCub from './themes/cub' // Cubera
import themesRep from './themes/rep' // Repoxit
import themesBua from './themes/bua' // Burri Achermann
import themesSup from './themes/sup' // Elektro Supersaxo
import themesHar from './themes/har' // Hardworker
import themesHgi from './themes/hgi' // Hägni AG
import themesZwe from './themes/zwe' // Zweifel
import themesJax from './themes/jax' // Jaisli Xamax
import themesSmp from './themes/smp' // Stampfli
import themesCar from './themes/car' // car
import themesFsa from './themes/fsa' // fsa
import themesGak from './themes/gak' // gak
import themesGia from './themes/gia' // gia
import themesPon from './themes/pon' // pon
import themesHis from './themes/his' // pon
import themesCda from './themes/cda' // claudia daetwyler
import themesKee from './themes/kee' // keey

//Get url
var urlString = window.location.href;

// Create a URL object
var url = new URL(urlString);

// Extract the desired text from the hostname
var link = url.hostname.split('.')[0];

let currentTheme = store.get('theme')
let themes = {}

if (typeof currentTheme == "undefined") {
	switch (link) {
		case 'burriachermann':
			themes = themesBua
			break;
		case 'supersaxo':
			themes = themesSup
			break
		case 'har':
			themes = themesHar
			break
		case 'haegni':
			themes = themesHgi
			break
		case 'zweifel':
			themes = themesZwe
			break
		case 'jaislixamax':
			themes = themesJax
			break
		case 'schlagenhauf':
			themes = themesSmp
			break
		case 'repoxit':
			themes = themesRep
			break
		case 'cubera':
			themes = themesCub
			break
		case 'carpenter':
			themes = themesCar
			break
		case 'freestyleacademy':
			themes = themesFsa
			break
		case 'gartenkultur':
			themes = themesGak
			break	
		case 'gemeinsamimalter':
			themes = themesGia
			break
		case 'ponato':
			themes = themesPon
			break
		case 'his':
			themes = themesHis
			break
		case 'cdaetwyler':
			themes = themesCda
			break
		case 'keey':
			themes = themesHis
			break	
		default:
			themes = themesKee
			break
	}
} else {
	switch (currentTheme) {
		case 'bua':
			themes = themesBua
			break
		case 'sup':
			themes = themesSup
			break
		case 'har':
			themes = themesHar
			break
		case 'hgi':
			themes = themesHgi
			break
		case 'zwe':
			themes = themesZwe
			break
		case 'jax':
			themes = themesJax
			break
		case 'sag':
			themes = themesSmp
			break
		case 'rep':
			themes = themesRep
			break
		case 'cub':
			themes = themesCub
			break
		case 'car':
			themes = themesCar
			break;
		case 'fsa':
			themes = themesFsa
			break;
		case 'gak':
			themes = themesGak
			break;	
		case 'gia':
			themes = themesGia
			break;
		case 'pon':
			themes = themesPon
			break;
		case 'his':
			themes = themesHis
			break;
		case 'cda':
			themes = themesCda
			break;
		case 'kee':
			themes = themesKee
			break;	
		default:
			themes = themesCub
			break
	}
}


Vue.use(Vuetify)

let opts = {
	icons: {
		iconfont: 'mdi'
	},
	lang: {
		locales: { de },
		current: 'de'
	},
	theme: {
		...themes,
		options: {
			customProperties: true,
			variations: false
		}
	}
}

export default new Vuetify(opts)
