import { render, staticRenderFns } from "./ExpenseIndex.vue?vue&type=template&id=c2b9f2c2&scoped=true"
import script from "./ExpenseIndex.vue?vue&type=script&lang=js"
export * from "./ExpenseIndex.vue?vue&type=script&lang=js"
import style0 from "./ExpenseIndex.vue?vue&type=style&index=0&id=c2b9f2c2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b9f2c2",
  null
  
)

export default component.exports