<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('general', 'title') }}</toolbar>

    <v-container fluid class="edit-screen"> 

      <loading :error="error" :dataLoaded="dataLoaded"></loading>
    
      <v-row v-if="dataLoaded" class="settings-wrapper">

        <v-card elevation="0">
          <v-card-title>{{ t('ratings', 'group-title') }}</v-card-title>
          <v-card-text>
            <p v-if="!validations['ratings']" class="qc-validation-message">
              {{ t('ratings', 'validation') }}
            </p>
            <v-spacer></v-spacer>
            <div class="button-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog('ratings', null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('ratings', 'create') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="settings.ratings"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="settings.ratings"
                  tag="tbody"
                >
                  <data-table-notifications-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                    setting="ratings"
                  ></data-table-notifications-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('rooms', 'group-title') }}</v-card-title>
          <v-card-text>
            <p v-if="!validations['rooms']" class="qc-validation-message">
              {{ t('rooms', 'validation') }}
            </p>
            <v-spacer></v-spacer>
            <div class="button-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog('rooms', null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('rooms', 'create') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="settings.rooms"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="settings.rooms"
                  tag="tbody"
                >
                  <data-table-notifications-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                    setting="rooms"
                  ></data-table-notifications-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('notification-types', 'group-title') }}</v-card-title>
          <v-card-text>
            <p v-if="!validations['notification-types']" class="qc-validation-message">
              {{ t('notification-types', 'validation') }}
            </p>
            <v-spacer></v-spacer>
            <div class="button-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog('notification-types', null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('notification-types', 'create') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="settings.notificationTypes"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="settings.notificationTypes"
                  tag="tbody"
                >
                  <data-table-notifications-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                    setting="notification-types"
                  ></data-table-notifications-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('subject-areas', 'group-title') }}</v-card-title>
          <v-card-text>
            <p v-if="!validations['subject-areas']" class="qc-validation-message">
              {{ t('subject-areas', 'validation') }}
            </p>
            <v-spacer></v-spacer>
            <div class="button-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog('subject-areas', null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('subject-areas', 'create') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="settings.subjectAreas"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="settings.subjectAreas"
                  tag="tbody"
                >
                  <data-table-notifications-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                    setting="subject-areas"
                  ></data-table-notifications-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        
        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('receipt-types', 'group-title') }}</v-card-title>
          <v-card-text>
            <p v-if="!validations['receipt-types']" class="qc-validation-message">
              {{ t('receipt-types', 'validation') }}
            </p>
            <v-spacer></v-spacer>
            <div class="button-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="openSettingDialog('receipt-types', null)"
                :disabled="saving || loading"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('receipt-types', 'create') }}
              </v-btn>
            </div>

            <v-data-table
              v-if="dataLoaded"
              :headers="headers"
              :items="settings.receiptTypes"
              :items-per-page="99"
              class="elevation-0"
              hide-default-footer
            >
              <template #body="props">
                <tbody v-if="loading"><tr><td colspan="6" class="table-loader"><v-progress-linear indeterminate color="primary"></v-progress-linear></td></tr></tbody>
                <draggable
                  v-else
                  v-model="settings.receiptTypes"
                  tag="tbody"
                >
                  <data-table-notifications-row-handler
                    v-for="(item, index) in props.items"
                    :row-index="index"
                    :item="item"
                    :headers="headers"
                    setting="receipt-types"
                  ></data-table-notifications-row-handler> 
                </draggable>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-row>

    </v-container>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
              color="primary"
              text
              class="icon-left"
              @click="reset()"
              :disabled="saving"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('general', 'reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                class="icon-left"
                @click="saveSettings()"
                :disabled="!hasChanges || loading || !dataLoaded"
                :loading="saving"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('general', 'save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

    <v-dialog
      v-model="settingsDialog"
      content-class="settings-dialog"
      width="600"
      persistent
    >
      <v-card v-if="settingsDialog">
        <v-card-title>
          {{ editItem ? t(this.settingsType, 'dialog.edit-title') : t(this.settingsType, 'dialog.add-title') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent="editItem ? editSettings() : addSettings()">
            <v-text-field
              :label="t('general', 'dialog.placeholder')"
              v-model="settingsTitle"
              counter="120"
              filled
              :rules="[
                () => !!settingsTitle || t('general', 'validation.error-title-required'),
                () => (settingsTitle && settingsTitle.length <= 120) || t('general', 'validation.error-max-title-120')
              ]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="closeSettingsDialog()"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('general', 'dialog.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            @click="editItem ? editSettings() : addSettings()"
          >
            <v-icon>mdi-check</v-icon>
            {{ editItem ? t('general', 'dialog.apply') : t('general', 'dialog.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import DataTableNotificationsRowHandler from '@/components/DataTableNotificationsRowHandler'
import draggable from 'vuedraggable'

export default {
  name: 'SettingsQcNotifications',
  components: {
    toolbar,
    loading,
    DataTableNotificationsRowHandler,
    draggable
  },
  data() {
    return {
      settings: {
        ratings: [],
        rooms: [],
        notificationTypes: [],
        subjectAreas: [],
        receiptTypes: []
      },
      dataOriginal: {},
      dataLoaded: false,
      loading: false,
      saving: false,
      error: false,
      settingsDialog: false,
      settingsType: null,
      settingsTitle: '',
      editItem: null,
      editItemIndex: null,
      typeMapping: {
        'ratings': 'ratings',
        'rooms': 'rooms',
        'notification-types': 'notificationTypes',
        'subject-areas': 'subjectAreas',
        'receipt-types': 'receiptTypes'
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'drag',
          width: 30
        },
        {
          text: this.t('general', 'table.title'),
          align: 'start',
          sortable: false,
          value: 'title'
        },
        {
          text: this.t('general', 'table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 120
        },
        {
          text: '',
          value: 'remove',
          sortable: false,
          align: 'center',
          width: 100,
        }
      ]
    },
    hasChanges() {
      return JSON.stringify(this.settings) !== JSON.stringify(this.dataOriginal)
    },
    validations() {
      return {
        'ratings': this.settings.ratings.length > 0,
        'rooms': this.settings.rooms.length > 0,
        'notification-types': this.settings.notificationTypes.length > 0,
        'subject-areas': this.settings.subjectAreas.length > 0,
        'receipt-types': this.settings.receiptTypes.length > 0
      }
    }
  },
  created() {
    this.getSettings()
    EventBus.$on('qc-open-settings-dialog', (settingsType, index, item) => {
      this.openSettingDialog(settingsType, item, index)
      this.settingsDialog = true
    })
    EventBus.$on('qc-remove-item', (settingsType, index) => {
      this.removeSetting(settingsType, index)
    })
  },
  methods: {
    t: function (setting, key) {
      return this.$t('qc-notifications.settings.' + setting + '.' + key)
    },
    getSettings() {
      this.loading = true

      Promise.allSettled([
        this.getRatings(),
        this.getRooms(),
        this.getNotificationTypes(),
        this.getSubjectAreas(),
        this.getReceiptTypes()       
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'fulfilled') {
            this.settings[response.value.type] = response.value.data
          }
          if (response.status == 'rejected') {
            errors.push(this.t(response.reason.type, 'group-title') + ' : ' + response.reason.error)
          }
        })

        if (errors.length > 0) {
          errors.unshift(this.t('general', 'get-request-error'))
          this.$root.infoNotification.showMessage(errors)
        }

        this.dataOriginal = JSON.parse(JSON.stringify(this.settings))

        this.dataLoaded = true
        this.loading = false
        this.error = false

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    getRatings() {
      return new Promise(function(resolve, reject) {
        HTTP.get('notification_ratings').then((response) => {
          resolve({
            'type': 'ratings',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getRooms() {
      return new Promise(function(resolve, reject) {
        HTTP.get('notification_rooms').then((response) => {
          resolve({
            'type': 'rooms',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getNotificationTypes() {
      return new Promise(function(resolve, reject) {
        HTTP.get('notification_types').then((response) => {
          resolve({
            'type': 'notificationTypes',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getSubjectAreas() {
      return new Promise(function(resolve, reject) {
        HTTP.get('subject_areas').then((response) => {
          resolve({
            'type': 'subjectAreas',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    getReceiptTypes() {
      return new Promise(function(resolve, reject) {
        HTTP.get('notification_receipt_types').then((response) => {
          resolve({
            'type': 'receiptTypes',
            'data': response.data
          })
        }).catch((error) => {
          reject(error)
        })
      }.bind(this))  
    },
    saveRatings() {
      if (this.settings.ratings.length == 0) {
        return Promise.reject({
          'type': 'ratings',
          'error': 'Empty Array'
        })
      } else {
        return new Promise(function(resolve, reject) {
          HTTP.post('notification_ratings', this.settings.ratings).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      }
    },
    saveRooms() {
      if (this.settings.rooms.length == 0) {
        return Promise.reject({
          'type': 'rooms',
          'error': 'Empty Array'
        })
      } else {
        return new Promise(function(resolve, reject) {
          HTTP.post('notification_rooms', this.settings.rooms).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      }
    },
    saveNotificationTypes() {
      if (this.settings.notificationTypes.length == 0) {
        return Promise.reject({
          'type': 'notification-types',
          'error': 'Empty Array'
        })
      } else {
        return new Promise(function(resolve, reject) {
          HTTP.post('notification_types', this.settings.notificationTypes).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      }
    },
    saveSubjectAreas() {
      if (this.settings.subjectAreas.length == 0) {
        return Promise.reject({
          'type': 'subject-areas',
          'error': 'Empty Array'
        })
      } else {
        return new Promise(function(resolve, reject) {
          HTTP.post('subject_areas', this.settings.subjectAreas).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      }
    },
    saveReceiptTypes() {
      if (this.settings.receiptTypes.length == 0) {
        return Promise.reject({
          'type': 'receipt-types',
          'error': 'Empty Array'
        })
      } else {
        return new Promise(function(resolve, reject) {
          HTTP.post('notification_receipt_types', this.settings.receiptTypes).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          }) 
        }.bind(this))
      }
    },
    saveSettings() {
      this.saving = true

      Object.keys(this.validations).forEach((type) => {
        if (!this.validations[type]) {
          this.saving = false
          return
        }
      })

      Promise.allSettled([
        this.saveRatings(),
        this.saveRooms(),
        this.saveNotificationTypes(),
        this.saveSubjectAreas(),
        this.saveReceiptTypes()
      ]).then(function(responses) {

        let errors = []
        responses.forEach((response) => {
          if (response.status == 'rejected') {
            errors.push(this.t(response.reason.type, 'group-title') + ' : ' + response.reason.error)
          }
        })

        if (errors.length > 0) {
          errors.unshift(this.t('general', 'post-request-error'))
          this.$root.infoNotification.showMessage(errors, true)
        }
        
        this.getSettings()

      }.bind(this)).finally(function() {
        this.saving = false
      }.bind(this))
    },
    reset() {
      this.dataLoaded = false
      this.settings.receiptTypes = []
      this.getSettings()
    },
    openSettingDialog(settingsType, item, index) {
      if (item) {
        this.settingsTitle = item.title
        this.editItem = item
        this.editItemIndex = index
      }
      this.settingsType = settingsType
      this.settingsDialog = true
    },
    closeSettingsDialog() {
      this.settingsDialog = false
      this.settingsType = null
      this.settingsTitle = ''
      this.editItem = null
      this.editItemIndex = null
      this.$refs.form.reset()
    },
    addSettings() {
      if (this.$refs.form.validate()) {
        this.settings[this.typeMapping[this.settingsType]].push({
          id: null,
          title: this.settingsTitle
        })
        this.closeSettingsDialog()
      }
    },
    editSettings() {
      if (this.$refs.form.validate()) {
        this.settings[this.typeMapping[this.settingsType]][this.editItemIndex].title = this.settingsTitle
        this.closeSettingsDialog()
      }
    },
    removeSetting(settingsType, index) {
      this.settings[this.typeMapping[settingsType]].splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.table-loader {
  padding: 0 !important;
  height: auto !important;
}

.edit-row-table {
  cursor: pointer;
}

.apply-setting {
  color: #FFF !important;
  background: #FF5252 !important;
}

.settings-wrapper {
  justify-content: space-between;

  & > .v-card {
      width: calc(66.66% - 16px);
  }

  .v-data-table {
      margin-bottom: 0;
  }
}

.qc-validation-message {
  float: left;
  padding-top: 8px;
  font-weight: bold;
  color: var(--v-error-base);
}

.button-wrapper {
  text-align: right;
  padding-bottom: 16px;
}
</style>
