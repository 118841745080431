<template>
    <div>
        <v-autocomplete
            v-model="selectedCustomer"
            :loading="loading"
            :items="items"
            item-text="name"
            :search-input.sync="search"
            cache-items
            filled
            hide-no-data
            :label="labelData"
            append-icon="mdi-magnify"
            return-object
            :disabled="readonly"
            clearable
            :rules="rules"
            :data-cy="dataCy"
        ></v-autocomplete>
    </div>
</template>

<script>
import { CancellingHttp } from '@/infrastructure/communication'

export default {
    name: "ContentCustomer",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        dataCy: {
            default: ''
        },
        model: {
            type: Object,
            default: () => [null]
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: Boolean,
        value: {},
        getSearchUrl: null,
        label: {
            type: String,
        }
    },
    data() {
        return {
            selectedCustomer: this.model,
            com: new CancellingHttp(),
            abortController: null,
            loading: false,
            items: [],
            search: null
        }
    },
    computed: {
        labelData() {
            return (this.label) ? this.label : "Nach Kunde / Objekt suchen";
        }
    },
    watch: {
        search(val) {
            val && val !== this.selectedCustomer && this.querySelections(val)
        },
        selectedCustomer: function(val) {
            this.$emit('input', val);
        },
        model: function(val) {
            if (val !== null) {
                this.items.push(val)
                this.selectedCustomer = val
            }
        }
    },
    created() {
        this.com.on('loading', (val) => {
            this.loading = val
        })
        
    },
    methods: {
        async querySelections(val) {
            let url = this.getSearchUrl(val)
            this.items = await this.com.get(url)
        },
    }
}
</script>

<style scoped lang="scss">
.v-autocomplete >>> .v-label {
    font-size: 12px;
    left: 0px !important;
}

.v-autocomplete >>> .v-input__icon {
    position: relative;
}
</style>
