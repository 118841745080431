import JobsIndex from '@/views/JobsIndex'
import JobsCreateEdit from '@/views/JobsCreateEdit'
import SettingsJobs from '@/views/SettingsJobs'

const jobsRoutes = [
    {
        path: '/jobs',
        name: 'JobsIndex',
        component: JobsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/jobs/create',
        name: 'JobsCreate',
        component: JobsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/jobs/:id?',
        name: 'JobsEdit',
        component: JobsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/jobs',
        name: 'SettingsJobs',
        component: SettingsJobs,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    }
]

export default jobsRoutes
