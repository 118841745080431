import NewsIndex from '@/views/NewsIndex'
import SettingsNews from '@/views/SettingsNews'
import NewsCreate from '@/views/NewsCreate'
import NewsEdit from '@/views/NewsEdit'

const newsRoutes = [
    {
        path: '/news',
        name: 'News',
        component: NewsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true,
                requiredSettingsPermission: ['news_category_manage']
            }
        }
    },
    {
        path: '/news/create',
        name: 'NewsCreate',
        component: NewsCreate,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true,
                requiredSettingsPermission: ['news_category_manage']
            }
        }
    },
    {
        path: '/news/:id?',
        name: 'NewsEdit',
        component: NewsEdit,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true,
                requiredSettingsPermission: ['news_category_manage']
            }
        }
    },
    {
        path: '/settings/news',
        name: 'SettingsNews',
        component: SettingsNews,
        meta: {
            moduleName: 'news',
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true,
                requiredSettingsPermission: ['news_category_manage']
            }
        }
    }
]

export default newsRoutes
