<template>
  <div>
    <BasicEditContainer
        :title="t('edit.title')"
        ref="editor"
        object-type="Absence"
        :object-name="absenceData.title"
        :data-loaded="dataLoaded"
        @cancel="cancel"
        :save-action="saveItem"
        @saved="onSaved"
        :allowDelete="false"
        :has-changes="hasChanges">
      <template v-slot:default>

        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <div v-if="dataLoaded">

              <div class="disabled-input">
                <label>{{ t('details.title') }}</label>
                <span>{{ absenceData.title }}</span>
              </div>

              <md-input-container>
                <label>{{ t('details.reason') }}</label>
                <md-select v-if="absenceData.reason" name="reason" id="reason" v-model="absenceData.reason.id">
                  <md-option v-for="reason in reasons" :key="reason.id" :value="reason.id ">{{
                      reason.name
                    }}
                  </md-option>
                </md-select>
              </md-input-container>

              <div class="disabled-input">
                <label>{{ t('details.employee') }}</label>
                <span>
                  {{ absenceData.author.firstname + ' ' + absenceData.author.name }}
                </span>
                <span v-if="absenceData.author.email && absenceData.author.email !== ''" class="small">
                  {{ absenceData.author.email }}</span>
                <span v-if="absenceData.author.department &&  absenceData.author.department !== ''" class="small">
                  {{ absenceData.author.department }}
                </span>
                <span v-if="absenceData.author.workplace &&  absenceData.author.workplace !== ''" class="small">
                  {{ absenceData.author.workplace }}
                </span>
              </div>

              <div class="disabled-input">
                <label>{{ t('details.comment') }}</label>
                <span>{{ absenceData.comment }}</span>
              </div>

            </div>

          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('time-span.group-title') }}</v-card-title>
          <v-card-text>

            <div>
              <md-layout md-flex="100">
                <md-layout md-flex="50" class="sp-flex-align-self-start" md-flex-offset="0">
                  <span class="time-label">{{ t('time-span.start') }}:</span>
                  <span class="time-string">
                    <span v-if="absenceData.allday">{{ formatDate(absenceData.start_date) }}</span>
                    <span v-if="!absenceData.allday">{{ formatDateTime(absenceData.start_date) }}</span>
                  </span>
                </md-layout>
                <md-layout md-flex="50" class="sp-flex-align-self-start" md-flex-offset="0">
                  <span class="time-label">{{ t('time-span.end') }}:</span>
                  <span class="time-string">
                    <span v-if="absenceData.allday">{{ formatDate(absenceData.end_date) }}</span>
                    <span v-if="!absenceData.allday">{{ formatDateTime(absenceData.end_date) }}</span>
                  </span>
                </md-layout>
              </md-layout>
              <hr>
              <full-calendar ref="calendar"
                             :events="events"
                             :config="calendarConfig"
                             :defaultView="calendarConfig.initialView"
                             @view-render="setAbsenzDay"
              ></full-calendar>
            </div>

          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>

        <!-- Documents -->
        <v-card v-if="absenceData.documents" elevation="0" class="sp-mar-bottom-3 absence-document">
          <v-card-title>{{ t('photo.group-title') }}</v-card-title>
          <v-card-text>
            <div>
              <v-img :src="imageData"></v-img>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="primary"
                elevation="0"
                outlined
                class="icon-left"
                @click="downloadImage()"
            >
              <v-icon>mdi-download</v-icon>
              {{ t('photo.download') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- Status -->
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('status.group-title') }}</v-card-title>
          <v-card-text>
            <div>

              <div class="disabled-input">
                <label>{{ t('status.filed-on') }}:</label>
                <span>{{ formatDateTime(absenceData.created_at) }}</span>
              </div>

              <md-input-container>
                <label>{{ t('status.status') }}</label>
                <md-select name="status" id="status" v-model="absenceData.status">
                  <md-option v-for="status in stati" :key="status.key" :value="status.key">
                    {{ status.value }}
                  </md-option>
                </md-select>
              </md-input-container>

            </div>
          </v-card-text>
        </v-card>

        <!-- Comment -->
        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('comment.group-title') }}</v-card-title>
          <v-card-text>
            <md-input-container>
              <md-textarea name="adminComment" id="adminComment" v-model="absenceData.admin_comment"></md-textarea>
            </md-input-container>
          </v-card-text>
        </v-card>

         <!-- Comment -->
         <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('checkbox.group-title') }}</v-card-title>
          <v-card-text>
              <div>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="absenceData.archived"/>
                  </v-list-item-action>
                  <v-list-item-content @click="toggleArchived">
                    <v-list-item-title>{{ t('checkbox.archived') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="absenceData.exported"/>
                  </v-list-item-action>
                  <v-list-item-content @click="toggleExported">
                    <v-list-item-title>{{ t('checkbox.exported') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

          </v-card-text>
        </v-card>

      </template>
    </BasicEditContainer>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import 'fullcalendar/dist/fullcalendar.css'
import { FullCalendar } from 'vue-full-calendar'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import { Buffer } from 'buffer'

export default {
  components: {
    BasicEditContainer,
    loading,
    toolbar,
    DatePicker,
    FullCalendar
  },
  data() {
    return {
      itemId: null,
      data: {},
      dataLoaded: false,
      error: null,
      errorCancel: null,
      absenceData: {},
      dataOriginal: {},
      imageData: null,
      reasons: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      },
      initialStatus: null,
      events: [],
      calendarConfig: {
        locale: 'de',
        initialView: 'month',
        selectable: false,
        height: 'auto',
        contentHeight: 'auto',
        dayNamesShort: [
          'SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'
        ],
        validRange: {
          start: moment().startOf('month').format('YYYY-MM-DD')
        },
        eventRender: function (ev, element) {
          element.attr('title', ev.description)
          element.addClass(ev.classNames)
          if (ev.current) {
            element.addClass('current-user')
          }
        }
      },
      absenzDaySet: false
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY');
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm');
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.getReasons()
  },
  computed: {
    hasChanges() {
      if (!this.dataOriginal) {
        return false;
      }
      return this.contentChanged
    },
    contentChanged() {
      return JSON.stringify(this.absenceData) !== JSON.stringify(this.dataOriginal)
    },
    stati() {
      return [
        { key: 'open', value: this.$t('absences.data.status.open') },
        { key: 'approved', value: this.$t('absences.data.status.approved') },
        { key: 'declined', value: this.$t('absences.data.status.declined') },
        { key: 'deleted', value: this.$t('absences.data.status.deleted') }
      ]
    }
  },
  methods: {
    t: function (key) {
      return this.$t('absences.detail.' + key)
    },
    moment: function () {
      return moment()
    },
    getReasons() {
      HTTP.get('absences/reasons').then(function (response) {
        response.data.forEach((element) => {
          this.reasons.push({ id: element.id, name: element.name })
        })
        this.getAbsence()
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    getAbsence() {
      HTTP.get('absences/' + this.itemId).then(function (response) {
        this.absenceData = response.data
        this.initialStatus = response.data.status
        this.dataLoaded = true
        this.loadDocumentImage()

        this.events.push({
          current: true,
          title: this.absenceData.title,
          description: this.absenceData.author.firstname + ' ' + this.absenceData.author.name,
          start: moment(this.absenceData.start_date).local(),
          end: this.absenceData.allday ? moment(this.absenceData.end_date).add(1, 'days').local().format('YYYY-MM-DD HH:mm') : moment(this.absenceData.end_date).local(),
          color: this.getStatusColor(this.absenceData.status),
          textColor: '#FFFFFF',
          editable: false,
          allDay: !!this.absenceData.allday,
          classNames: 'event-' + this.absenceData.status
        })

        HTTP.get('absences/eventslist/' + this.itemId).then(function (response) {
          response.data.forEach((element) => {
            this.events.push({
              current: false,
              title: element.title,
              description: element.author.firstname + ' ' + element.author.name,
              start: moment(element.start_date).local(),
              end: element.allday ? moment(element.end_date).add(1, 'days').local().format('YYYY-MM-DD HH:mm') : moment(element.end_date).local(),
              color: this.getStatusColor(element.status),
              textColor: '#FFFFFF',
              editable: false,
              allDay: !!element.allday,
              classNames: 'event-' + element.status
            })
          })
          this.$refs.calendar.fireMethod('render')
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
            this.dataLoaded = false
          }
        }.bind(this))

        this.dataOriginal = JSON.parse(JSON.stringify(this.absenceData))

      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    loadDocumentImage() {
      if (this.absenceData.documents) {
        this.imageData = null;
        HTTP.get('/absence-documents/' + this.absenceData.documents, { responseType: 'arraybuffer' })
            .then(response => this.imageData = 'data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64'))
      }
    },
    downloadImage() {
      let fileName = "Absence_"
          + moment(this.absenceData.created_at).format('DD_MM_YYYY') + "_"
          + this.absenceData.author.firstname + "_"
          + this.absenceData.author.name;

      const downloadLink = document.createElement("a")
      downloadLink.href = this.imageData;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    getStatusColor(status) {
      switch (status) {
        case 'open':
          return '#d2c41e'
        case 'approved':
          return '#27ad27'
        case 'declined':
          return '#ec4949'
      }
    },
    setAbsenzDay() {
      if (!this.absenzDaySet) {
        this.$refs.calendar.fireMethod('gotoDate', this.absenceData.start_date)
        this.absenzDaySet = true
      }
    },
    saveItem() {

      const json = JSON.stringify({
        absenceData: this.absenceData,
        status: this.absenceData.status
      })

      return HTTP.post('absences/' + this.absenceData.id, { 'data': json })
          .then(function (response) {
            this.data = response.data
            this.close()
          }.bind(this))
          .catch(function (error) {
            if (error.response.data.errors) {
              this.message = error.response.data.message
            }
            this.$root.infoNotification.showMessage('Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.');
          }.bind(this))

    },
    async onSaved() {
      this.taskDataOriginal = null
      this.close()
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({ name: 'AbsencesIndex' })
    },
    toggleArchived() {
      console.log(this.absenceData.archived);
      this.absenceData.archived = !this.absenceData.archived;
    },
    toggleExported() {
      console.log(456);
      this.absenceData.exported = !this.absenceData.exported;
    }
  }
}
</script>

<style>
.fc-header-toolbar .fc-right {
  display: none;
}

.fc-header-toolbar .fc-center {
  float: right;
  line-height: 30px;
}

.fc-scroller {
  overflow: hidden !important;
}

.fc-day-grid-event .fc-content {
  padding: 4px;
}

.fc-day-grid-event.current-user {
  padding: 6px 4px;
  font-weight: bold;
  text-shadow: 1px 0px 0px rgb(0 0 0);
  box-shadow: inset 0px 0px 0px 4px rgb(0 0 0 / 15%);
}

.fc-day-grid-event:hover {
  text-decoration: none !important;
}

.fc-day-grid-event.current-user.event-open {
  background-image: linear-gradient(45deg, #b5aa16 25%, #d2c41e 25%, #d2c41e 50%, #b5aa16 50%, #b5aa16 75%, #d2c41e 75%, #d2c41e 100%);
  background-size: 42.43px 42.43px;
  background-repeat: repeat;
}

.fc-day-grid-event.current-user.event-approved {
  background-image: linear-gradient(45deg, #169216 25%, #27ad27 25%, #27ad27 50%, #169216 50%, #169216 75%, #27ad27 75%, #27ad27 100%);
  background-size: 42.43px 42.43px;
  background-repeat: repeat;
}

.fc-day-grid-event.current-user.event-declined {
  background-image: linear-gradient(45deg, #c43939 25%, #ec4949 25%, #ec4949 50%, #c43939 50%, #c43939 75%, #ec4949 75%, #ec4949 100%);
  background-size: 42.43px 42.43px;
  background-repeat: repeat;
}
</style>

<style lang="scss" scoped>
.absence-document {
  max-width: 376px;
}

.full-width {
  width: 100%;
}

.md-avatar.rectangle {
  margin: 0;
  border-radius: 0;
}

.md-theme-default.md-input-disabled {
  color: rgba(0, 0, 0, .38);
}

.mx-datepicker {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;
}

.disabled-input {
  margin-bottom: 24px;
  font-size: 16px;

  label {
    line-height: 24px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
  }

  span {
    display: block;

    &.small {
      font-size: 14px;
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid rgba(227, 227, 227, 0.7);
  margin: 16px 0;
}

.time-label {
  line-height: 24px;
  align-self: center;
}

.time-string {
  margin-left: 16px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  background: #f7f7f7;
  padding: 16px;
  border: 1px solid rgba(227, 227, 227, 0.7);
  border-radius: 4px;
}

.sp-flex-1 {
  max-width: 100%;
}

</style>

